import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { Modifier, Modifiers } from 'react-day-picker/types/common';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { postNotification } from '../../../../models/LocalNotifications';
import {
	calcScheduleCampaignCalendarDateRange,
	convertDurationFromString,
	getScheduleTimeOptions,
	getSendOnBehalfDisabledDays,
	getSendOnBehalfWaitTime,
} from '../../../../models/UiUtils';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	useBlogUpdateMutation,
	useBlogUpdateScheduleMutation,
	useCreateBlogPostMutation,
	useInfiniteReportsBlogsQuery,
} from '../../../../queries';
import { Button } from '../../../components/Button';
import { CampaignDayPicker } from '../../../components/CampaignDayPicker';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { DefaultSelectBox, ISelectBoxOption } from '../../../components/SelectBox';
import { DefaultCampaignCalendarBlogStatuses } from '../../../components/campaigns/CampaignCalendar/models';
import { BlogsCircleGraphic } from '../../../components/svgs/graphics/BlogsCircleGraphic';
import { bs } from '../../../styles/styles';
import { IBlogPostSchedulerProps } from '../Post/models';
import { BlogPostContext } from '../blogPostContext';
import { BlogCampaignCompletedListItem } from './BlogCampaignCompletedListItem';
import { BlogCampaignPendingListItem } from './BlogCampaignPendingListItem';
import { BlogCampaignScheduledListItem } from './BlogCampaignScheduledListItem';
import { styleSheet } from './styles';

interface IProps extends IBlogPostSchedulerProps, IImpersonationContextComponentProps {
	className?: string;
	sendFrom?: Api.ISendEmailFrom;
	initialSelectedDate?: Date;
	isSubmitting?: boolean;
}

const ScheduleSelectBoxOptions: ISelectBoxOption<'now' | 'schedule'>[] = [
	{
		title: 'Publish Now',
		value: 'now',
	},
	{
		title: 'Schedule for later',
		value: 'schedule',
	},
];

type DateRange = {
	startDate: Date;
	endDate: Date;
};

type State = {
	dateRange: DateRange;
	selectedDate: Date;
	sendNow: boolean;
};

type Actions =
	| {
			type: 'setDateRange';
			payload: DateRange;
	  }
	| {
			type: 'calendarDaySelected';
			payload: Date;
	  }
	| {
			type: 'scheduleHourChange';
			payload: number;
	  }
	| {
			type: 'setSendNow';
			payload: boolean;
	  };

const reducer = (state: State, action: Actions): State => {
	switch (action.type) {
		case 'setDateRange': {
			return {
				...state,
				dateRange: action.payload,
			};
		}
		case 'calendarDaySelected': {
			const newDate = new Date(action.payload);
			newDate.setHours(state.selectedDate.getHours(), 0, 0);
			return {
				...state,
				selectedDate: newDate,
				sendNow: moment(newDate).isSame(moment(), 'day'), // If the new selectedDate is today, set sendNow to true
			};
		}
		case 'scheduleHourChange': {
			const newDate = new Date(state.selectedDate);
			newDate.setHours(action.payload, 0, 0);
			return {
				...state,
				selectedDate: newDate,
			};
		}
		case 'setSendNow': {
			return {
				...state,
				sendNow: action.payload,
			};
		}
		default: {
			throw new Error('Invalid action type');
		}
	}
};

const NUM_WEEKS_SHOWN_IN_CAL: 4 | 6 = 6;

export const ScheduleBlogPost = inject(ImpersonationContextKey)(function ScheduleBlogPost({
	className,
	sendFrom,
	isSubmitting,
	onScheduled,
	onCreated,
	onUpdated,
	impersonationContext,
}: IProps) {
	const fullscreenModal = useFullscreenModal();
	const userSession = useUserSession();
	const { blogPost, setBlogPost } = React.useContext(BlogPostContext);
	const initialSelectedDate = blogPost?.scheduledSendDate ? new Date(blogPost.scheduledSendDate) : new Date();

	const updateBlogPostScheduleMutation = useBlogUpdateScheduleMutation({
		impersonationContext: impersonationContext?.toJs(),
		onSuccess: blog => {
			setBlogPost(blog);
			postNotification({
				info: blog,
				topic: Topics.EDIT_BLOG_POST,
			});
			if (!onScheduled?.(blog)) {
				fullscreenModal.history.push(`/blog/post/success`);
			}
		},
	});
	const updateBlogPostMutation = useBlogUpdateMutation({
		impersonationContext: impersonationContext?.toJs(),
		onSuccess: blog => {
			setBlogPost(blog);
			postNotification({
				info: blog,
				topic: Topics.EDIT_BLOG_POST,
			});
			if (!onUpdated?.(blog)) {
				fullscreenModal.history.push(`/blog/post/edit/${blogPost.id}`);
			}
		},
	});

	const createBlogPostMutation = useCreateBlogPostMutation({
		impersonationContext: impersonationContext?.toJs(),
		onSuccess: blog => {
			setBlogPost(blog);
			postNotification({
				info: blog,
				topic: Topics.EDIT_BLOG_POST,
			});
			if (!onCreated?.(blog)) {
				fullscreenModal.history.push(`/blog/post/success`);
			}
		},
	});

	const [state, dispatch] = React.useReducer(reducer, { initialSelectedDate }, init => {
		const selectedDate = init.initialSelectedDate ?? new Date();
		return {
			selectedDate,
			dateRange: calcScheduleCampaignCalendarDateRange({
				selectedDate,
				numWeeks: NUM_WEEKS_SHOWN_IN_CAL,
			}),
			sendNow: moment(selectedDate).isSame(moment(), 'day'),
		};
	});

	const reportsBlogsQuery = useInfiniteReportsBlogsQuery({
		impersonationContext: impersonationContext?.toJs(),
		endDate: state.dateRange.endDate.toISOString(),
		startDate: state.dateRange.startDate.toISOString(),
		sendFromUserId: blogPost.sendFromUserId || userSession.user.id,
		status: DefaultCampaignCalendarBlogStatuses,
		pageSize: 25,
	});

	const completedAndStartedCampaigns = (reportsBlogsQuery.data?.pages?.[0].values ?? []).filter(
		campaign => campaign.status === Api.BlogStatus.Completed
	);
	const completedCampaignDates = completedAndStartedCampaigns.map(campaign => new Date(campaign.scheduledSendDate));
	const queuedCampaigns = (reportsBlogsQuery.data?.pages?.[0].values ?? []).filter(
		campaign => campaign.status === Api.BlogStatus.Queued || campaign.status === Api.BlogStatus.Pending
	);
	const queuedCampaignDates = queuedCampaigns.map(campaign => new Date(campaign.scheduledSendDate!));

	const modifiers = {
		hasCampaign: [...completedCampaignDates, ...queuedCampaignDates],
		hasCompletedCampaign: completedCampaignDates,
		hasQueuedCampaign: queuedCampaignDates,
	} as Omit<Modifiers, 'today' | 'outside'>;
	const minStartDate =
		sendFrom !== 'CurrentUser' ? getSendOnBehalfDisabledDays(userSession, impersonationContext) : null;
	const disabledDays: Modifier[] = [{ before: minStartDate ? minStartDate : new Date() }];
	const businessHours = userSession?.account?.features?.sendEmail;
	if (businessHours?.observeSendIntervals) {
		if (!businessHours?.saturdayInterval?.startMinutes && !businessHours?.saturdayInterval?.endMinutes) {
			disabledDays.push({ daysOfWeek: [6] });
		}
		if (!businessHours?.sundayInterval?.startMinutes && !businessHours?.sundayInterval?.endMinutes) {
			disabledDays.push({ daysOfWeek: [0] });
		}
		if (!businessHours?.fridayInterval?.startMinutes && !businessHours?.fridayInterval?.endMinutes) {
			disabledDays.push({ daysOfWeek: [5] });
		}
	}
	// Compute timeOptions
	// Don't know how this works. Taken from EditSendOptions/timeOptions
	/* ---------- */
	const allOptions = getScheduleTimeOptions(0, userSession, undefined, moment(state.selectedDate));
	let timeOptions = allOptions;
	const nowMoment = moment();
	const days = convertDurationFromString(userSession?.account?.preferences?.sendOnBehalfWaitTime)?.days ?? 2;
	if (
		(sendFrom === 'ContactOwner' || sendFrom === 'SelectedUser') &&
		moment(state.selectedDate).isSame(getSendOnBehalfWaitTime(userSession), 'day') &&
		days !== 2
	) {
		timeOptions = allOptions.filter(({ value }) => {
			const thenMoment = getSendOnBehalfWaitTime(userSession).set(
				'hours',
				// @ts-ignore
				value
			);
			// @ts-ignore
			return thenMoment.isAfter(getSendOnBehalfWaitTime(userSession));
		});
	} else if (moment(state.selectedDate).isSame(nowMoment, 'day')) {
		timeOptions = allOptions.filter(({ value }) => {
			// @ts-ignore
			const thenMoment = moment(nowMoment).set('hours', value).set('minutes', 0);
			return thenMoment.isAfter(nowMoment);
		});
	}
	const validSelectedHour = timeOptions.find(x => x.value === moment(state.selectedDate).get('hours')) ?? {
		isDefault: true,
		title: 'Select a time',
		value: -1,
	};
	/* ---------- */

	const shouldShowScheduleTypeSelector = moment(state.selectedDate).isSame(moment(), 'day') && timeOptions.length > 0;
	const showIsLoading =
		isSubmitting ||
		updateBlogPostMutation.isLoading ||
		createBlogPostMutation.isLoading ||
		updateBlogPostScheduleMutation.isLoading;
	const disableSendButton = (!state.sendNow && validSelectedHour.isDefault) || showIsLoading;

	const handleSendNowClick = () => {
		const nextPost: Api.IBlogPost = {
			...blogPost,
			scheduledSendDate: new Date().toISOString(),
		};
		if (blogPost?.id) {
			updateBlogPostScheduleMutation.mutate({ post: nextPost });
			return;
		}
		createBlogPostMutation.mutate({ post: nextPost });
	};

	const handleScheduleClick = () => {
		const nextPost: Api.IBlogPost = {
			...blogPost,
			scheduledSendDate: state.selectedDate.toISOString(),
		};
		if (blogPost?.id) {
			updateBlogPostMutation.mutate({ post: nextPost });
		} else {
			createBlogPostMutation.mutate({ post: nextPost });
		}
	};

	const hanldleChangeDateClick = () => {
		const nextPost: Api.IBlogPost = {
			...blogPost,
			scheduledSendDate: state.selectedDate.toISOString(),
		};

		updateBlogPostMutation.mutate({ post: nextPost });
	};

	return (
		<div className={`${className ?? ''} ${css(styleSheet.mainContainer)}`}>
			<FabContext appearance={{ hidden: true }} />
			<MultiContainerHeader
				fullscreenHeader='Schedule Blog Post'
				onFullscreenRequestClose={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
			/>
			<header className={css(bs.flex, bs.itemsCenter, styleSheet.header)}>
				<figure className={css(styleSheet.figure)}>
					<BlogsCircleGraphic />
				</figure>
				<section className={css(styleSheet.section)}>
					<h2>When do you want to publish this blog?</h2>
				</section>
			</header>
			<div
				className={css(
					bs.flex,
					bs.itemsCenter,
					bs.gap8,
					bs.borderGray300,
					bs.border0,
					bs.borderB,
					bs.borderSolid,
					bs.h80,
					bs.pt5,
					bs.pb2
				)}
			>
				<CampaignDayPicker
					disabledDays={disabledDays}
					modifiers={modifiers}
					onDayClick={day => {
						dispatch({ type: 'calendarDaySelected', payload: day });
					}}
					onDateRangeChange={dateRange => {
						dispatch({
							type: 'setDateRange',
							payload: dateRange,
						});
					}}
					numWeeksShown={NUM_WEEKS_SHOWN_IN_CAL}
					selectedDay={state.selectedDate}
				/>
				<div className={css(bs.flexGrow, bs.hFull, bs.relative)}>
					<div className={css(bs.hFull, bs.overflowAuto, bs.flex, bs.flexCol, bs.gap2)}>
						{queuedCampaigns.length > 0 ? (
							<div>
								<div className={css(bs.textHeader, bs.textXs, bs.uppercase, bs.mb2)}>Planned Blogs</div>
								<div className={css(bs.flex, bs.flexCol, bs.gap5)}>
									{queuedCampaigns.map((campaign, i) =>
										campaign.status === Api.BlogStatus.Queued ? (
											<BlogCampaignScheduledListItem key={i} report={campaign} index={i} />
										) : (
											<BlogCampaignPendingListItem key={i} report={campaign} index={i} />
										)
									)}
								</div>
							</div>
						) : null}
						{completedAndStartedCampaigns.length > 0 ? (
							<div>
								<div className={css(bs.textHeader, bs.textXs, bs.uppercase, bs.mb2)}>Completed Blogs</div>
								<div className={css(bs.flex, bs.flexCol, bs.gap5)}>
									{completedAndStartedCampaigns.map((campaign, i) => (
										<BlogCampaignCompletedListItem key={i} report={campaign} index={i} />
									))}
								</div>
							</div>
						) : null}
						<div
							className={css(
								bs['top-1-2'],
								bs.absolute,
								bs.transform,
								bs['left-1-2'],
								bs['-translate-x-1-2'],
								bs['-translate-y-1-2']
							)}
						>
							{!reportsBlogsQuery.isSuccess ? <LoadingSpinner type='large' /> : null}
							{reportsBlogsQuery.isSuccess && queuedCampaigns.length === 0 && completedAndStartedCampaigns.length === 0
								? 'No scheduled Blogs'
								: null}
						</div>
					</div>
				</div>
			</div>
			<footer className={css(bs.flex, bs.pt5, bs.px4, bs.pb9)}>
				<time dateTime={state.selectedDate.toLocaleString()} className={css(bs.textLabel, bs.mt2, bs.w40)}>
					{moment(state.selectedDate).format(`ddd[,] MMMM Do `)}
				</time>
				<div className={css(bs.flex, bs.flex1)}>
					<div className={css(bs.flex, bs.itemsCenter, bs.gap2)}>
						{shouldShowScheduleTypeSelector ? (
							<DefaultSelectBox
								menuPopupPosition='top'
								onSelectionChanged={option => {
									dispatch({
										type: 'setSendNow',
										payload: option.value === 'now',
									});
								}}
								options={ScheduleSelectBoxOptions}
								optionStyles={[bs.textSm, bs.textLeft]}
								selectedOption={ScheduleSelectBoxOptions.find(x => x.value === (state.sendNow ? 'now' : 'schedule'))}
								triggerStyles={[bs.textSm, bs.hAuto, bs.w40]}
							/>
						) : null}
						{!state.sendNow && timeOptions.length > 0 ? (
							<DefaultSelectBox
								menuPopupPosition='top'
								onSelectionChanged={option => {
									if (state.selectedDate && option.value != null) {
										dispatch({
											type: 'scheduleHourChange',
											payload: option.value,
										});
									}
								}}
								options={timeOptions}
								optionStyles={[bs.textSm]}
								placeholder={<span className={css(bs.textGray400)}>Select a time...</span>}
								selectedOption={validSelectedHour}
								triggerStyles={[bs.textSm, bs.hAuto, bs.w36]}
							/>
						) : null}
						{!blogPost.status || blogPost.status === Api.BlogStatus.Draft || state.sendNow ? (
							<Button
								className={css(bs.ctaButton, bs.gap2, state.sendNow && bs.flexGrow)}
								disabled={disableSendButton}
								onClick={() => {
									if (state.sendNow) {
										handleSendNowClick();
										return;
									}
									handleScheduleClick();
								}}
								isLoading={showIsLoading}
								label={<>{state.sendNow ? 'Publish Now' : 'Schedule'}</>}
							/>
						) : (
							<Button
								className={css(bs.ctaButton, bs.gap2, state.sendNow && bs.flexGrow)}
								disabled={disableSendButton}
								onClick={() => hanldleChangeDateClick()}
								label='Change Date'
								isLoading={showIsLoading}
							/>
						)}
					</div>
				</div>
			</footer>
		</div>
	);
});
