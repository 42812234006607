import * as Api from '@ViewModels';
import { IUser, PostStatus, UserViewModel } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { Switch } from 'react-router';
import { AccountUserSelectBox } from '../../../../admin/components/user/AccountUserSelectBox';
import {
	IImpersonationContextComponentProps,
	ILocationState,
	IModalContext,
	ImpersonationContextKey,
	ModalChildComponentContextKey,
} from '../../../../models';
import { CampaignType } from '../../../../models/AdminModels';
import { AIContentGenerationStatus } from '../../../../models/Ai';
import { suppressDuplicateTemplateWarning } from '../../../../models/Campaigns';
import { Topics } from '../../../../models/LocalNotificationTopics';
import { useLocalNotificationService } from '../../../../models/LocalNotifications';
import {
	accountRequiresCompliance,
	createContentStateWithHtmlStringValue,
	isValidEmail,
} from '../../../../models/UiUtils';
import { useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateAllTemplateCategorySocialQueries,
	useCreateSocialPostMutation,
	useUpdateTemplateMutation,
} from '../../../../queries';
import { useUsersWithSocialMediaConnection } from '../../../../queries/User/useUsersWithSocialMediaConnection';
import { KnownCategories } from '../../../../viewmodels/AppViewModels';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { Modal } from '../../../components/Modal';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { PrivateRoute } from '../../../components/PrivateRoute';
import { SaveTemplate } from '../../../components/SaveTemplate';
import { ISelectOption } from '../../../components/Select';
import { TextInputModal } from '../../../components/TextInputModal';
import { PopoverType, TinyPopover } from '../../../components/TinyPopover';
import { UserSelectBox } from '../../../components/UserSelectBox';
import { AIContentGenerationWizard } from '../../../components/ai/AIContentGenerationWizard';
import { AIContentGenerationStatusContext } from '../../../components/ai/AIContentGenerationWizard/context';
import {
	AIContentGenerationWizardAssistantSocialMessages,
	AIContentGenerationWizardType,
} from '../../../components/ai/AIContentGenerationWizard/models';
import { lastUsedComplianceRecipientEmail } from '../../../components/campaigns/CampaignDetailsSummary';
import { ComplianceConfirmation } from '../../../components/campaigns/ComplianceConfirmation';
import { DuplicateWarningDialog } from '../../../components/campaigns/DuplicateWarningDialog';
import { InlineValueEditor } from '../../../components/entities/InlineValueEditor';
import { SocialMediaPlatformTable } from '../../../components/socialMedia/SocialMediaPlatformTable';
import { SocialMediaPlatformTableType } from '../../../components/socialMedia/SocialMediaPlatformTable/models';
import { SocialMediaPostContentEditor } from '../../../components/socialMedia/SocialMediaPostContentEditor';
import { SocialMediaPostGraphic } from '../../../components/svgs/graphics/SocialMediaPostGraphic';
import { EditItemPenIcon } from '../../../components/svgs/icons/EditItemPenIcon';
import { InfoIcon } from '../../../components/svgs/icons/InfoIcon';
import { LightBulbIcon } from '../../../components/svgs/icons/LightBulbIcon';
import { WarningIcon } from '../../../components/svgs/icons/WarningIcon';
import { ViewCitationsModal } from '../../../components/templates/ViewCitationsModal';
import { brandPrimaryHover } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { SocialMediaApprovalSetSchedule } from '../../socialMediaPostApproval/SocialMediaApprovalSetSchedule';
import { EditSocialMediaSendOptions } from '../../templates/EditSocialMediaSendOptions';
import { CopyPreviewLink } from '../CopyPreviewLink';
import { SocialMediaPostScheduleComplete } from '../SocialMediaPostScheduleComplete';
import { EditSocialMediaPostContext } from './context';
import { useAIContentGenerationWizard, useEditSocialMediaPost } from './hooks';
import { CancelConfirmationOptions, CancelUpdateConfirmationOptions, UpdateConfirmationOptions } from './models';
import { DefaultComposerContext, SocialMediaNavigation, StatusSpecificDetails } from './presentation';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps, IModalContext {
	className?: string;
	onPostUpdated?(): boolean;
	styles?: StyleDeclarationValue[];
}

const EditSocialMediaPostBase: React.FC<IProps> = observer(props => {
	const { className, styles = [], impersonationContext, parentModal, onPostUpdated } = props;
	const {
		context,
		duplicateType,
		errorMessages,
		h,
		impersonationCtx,
		initialSelectedUserOptionRef,
		isLoaded,
		loc,
		locationStateCreateRequest,
		logApiError,
		postReport,
		postToEdit,
		routeMatch,
		scheduledPost,
		setScheduledPost,
		setShowDuplicateWarning,
		setSocialMediaConnections,
		showDuplicateWarning,
		socialMediaConnections,
		source,
		suggestionId,
		templateId,
		toaster,
		userIsLoaded,
	} = useEditSocialMediaPost({ impersonationContext });
	const { postNotification } = useLocalNotificationService<Api.SocialMediaPostViewModel>();
	const userSession = useUserSession();
	const usersWithSocial = useUsersWithSocialMediaConnection({
		transformer: users => users.map(user => new UserViewModel(userSession, user)),
		userSession,
	});

	const [showSaveAsModal, setShowSaveAsModal] = React.useState(false);
	const [templateToBeSaved, setTemplateToBeSaved] = React.useState<Api.ITemplate | null>(null);

	const readOnly = source ? !source.canEdit : false;

	const fullscreenModal = useFullscreenModal();
	const [isSchedulingInProgress, setIsSchedulingInProgress] = React.useState(false);

	const [bypassCompliance, setBypassCompliance] = React.useState(false);
	const [editableCampaignName, setEditableCampaignName] = React.useState<string>(null);
	const [isEditingName, setIsEditingName] = React.useState(false);

	const creatingPostWithEmptyInitialContent = !source && !scheduledPost && !templateId;
	const isComplianceFlow = accountRequiresCompliance(userSession, impersonationContext);

	const [sendWithComplianceEmail, setSendWithComplianceEmail] = React.useState<string>(() => {
		return localStorage.getItem(lastUsedComplianceRecipientEmail) ?? '';
	});

	const { clearContentGenerationStatus, contentGenerationStatus, onGenerateClicked, onUndoClicked } =
		useAIContentGenerationWizard({
			context,
			creatingPostWithEmptyInitialContent,
		});

	const contentGenerationStatusContext = React.useMemo(
		() => ({ clearContentGenerationStatus, contentGenerationStatus }),
		[clearContentGenerationStatus, contentGenerationStatus]
	);

	const previewLink = source?.anonymousAccessLink || scheduledPost?.anonymousAccessLink || '';

	// #region handle connections
	const onSelectedConnectionsChanged = React.useCallback(
		(selected: Api.IPostTarget[]) => {
			context.setPostTargets(selected);
			context.setHasChanges(true);
		},
		[context]
	);
	// #endregion

	const reloadSource = React.useCallback(async () => {
		try {
			await source.impersonate(impersonationCtx)?.load();
		} catch (err) {
			logApiError('ReloadPostReport-Error', err);
			toaster.push({
				message: 'Report failed to reload. Please refresh the page to see the latest status.',
				type: 'errorMessage',
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [source]);

	const handlePostNow = (e?: React.MouseEvent<HTMLElement>) => {
		onSubmitPost(e, moment());
	};

	const handleScheduleWithoutCompliance = (e?: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setBypassCompliance(true);
		h.push(`${routeMatch.url}/send-options`);
	};

	const checkComplianceEmail = () => {
		if (sendWithComplianceEmail.length > 0 && !isValidEmail(sendWithComplianceEmail)) {
			toaster.push({
				message: `Please enter a valid email address`,
				type: 'errorMessage',
			});
			return false;
		}
		return true;
	};

	const handleScheduleForLater = (e?: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		if (isComplianceFlow && !checkComplianceEmail()) {
			return;
		}
		h.push(`${routeMatch.url}/send-options`);
	};

	const handleChangeDate = async () => {
		if (postReport) {
			await reloadSource();
		}
		h.push(`${routeMatch.url}/set-social-schedule`);
	};

	const onCancelPost = React.useCallback(async () => {
		if (scheduledPost) {
			try {
				await scheduledPost.cancel();
				postNotification({
					info: scheduledPost,
					topic: Topics.EDIT_SOCIAL_POST,
				});
				parentModal.onRequestClose();
			} catch (err) {
				errorMessages.pushApiError(err);
				logApiError('CancelPost-Error', err);
			}
		}
	}, [scheduledPost, logApiError, errorMessages, parentModal, postNotification]);

	// #region Cancel confirmation
	const [showingCancelConfirmation, setShowingCancelConfirmation] = React.useState(false);
	const [showingCancelUpdateConfirmation, setShowingCancelUpdateConfirmation] = React.useState(false);
	const [showingUpdateConfirmation, setShowingUpdateConfirmation] = React.useState(false);
	const [showingCitations, setShowingCitations] = React.useState(false);

	const onShowCancelConfirmation = (e?: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();

		setShowingCancelConfirmation(true);
	};

	const onShowCancelUpdateConfirmation = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		setShowingCancelUpdateConfirmation(true);
	};

	const onRequestClose = () => {
		parentModal?.onRequestClose();
	};

	const onShowUpdateConfirmation = () => {
		setShowingUpdateConfirmation(true);
	};

	const onCancelConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, cancelled?: boolean) => {
		if (!cancelled && result?.representedObject) {
			onCancelPost();
		}
		setShowingCancelConfirmation(false);
	};

	const onCancelUpdateConfirmationRequestClose = (result?: IConfirmationDialogOption<boolean>, cancelled?: boolean) => {
		if (!cancelled && result?.representedObject) {
			parentModal?.onRequestClose();
		}
		setShowingCancelUpdateConfirmation(false);
	};

	const onCitationRequestClose = () => {
		setShowingCitations(false);
	};

	const onUpdateConfirmationRequestClose = async (result?: IConfirmationDialogOption<boolean>, cancelled?: boolean) => {
		if (!cancelled && result?.representedObject) {
			const selectedSendTime =
				postToEdit?.status === Api.PostStatus.Draft || scheduledPost?.status === Api.PostStatus.Draft
					? undefined
					: moment(postReport?.dueDate || postToEdit?.dueDate);
			await onSubmitPost(undefined, selectedSendTime);
			postNotification({
				info: scheduledPost,
				topic: Topics.EDIT_SOCIAL_POST,
			});
		}
		setShowingUpdateConfirmation(false);
	};

	// #endregion

	const onSaveAsButtonClicked = () => {
		setShowSaveAsModal(true);
	};

	const createSocialPostMutation = useCreateSocialPostMutation({
		onSuccess: async data => {
			const socialMediaPostVm = new Api.SocialMediaPostViewModel(userSession, data);
			postNotification({
				info: socialMediaPostVm,
				topic: Topics.CREATE_SOCIAL_POST,
			});
			toaster.push({
				message: 'Your post has been saved as a draft.',
				type: 'successMessage',
			});

			try {
				await onPostScheduled(socialMediaPostVm);
				locationStateCreateRequest?.onFinish?.(true);
				if (onPostUpdated?.()) {
					return;
				}
				const locationState: ILocationState<Api.SocialMediaPostViewModel, null> = {
					viewModel: socialMediaPostVm,
				};
				fullscreenModal.history.replace({
					pathname: `${routeMatch.url}`,
					state: locationState,
				});
			} finally {
				setIsSchedulingInProgress(false);
			}
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('CreateSocialPost', error);
			setIsSchedulingInProgress(false);
		},
	});
	const onSaveAsDraftPost = async (newCampaignName?: string, cancel?: boolean) => {
		if (!cancel && newCampaignName) {
			setIsSchedulingInProgress(true);
			context.setCampaignName(newCampaignName);
			const post: Api.ICreateSocialMediaPostRequest = {
				...context.getPostBase(),
				forUserId: context.selectedCreator?.id,
				sendWithCompliance:
					impersonationContext?.account?.preferences?.complianceSettings?.enabled ||
					userSession?.account?.preferences?.complianceSettings?.enabled,
				sendWithComplianceEmail,
				name: newCampaignName,
			};
			createSocialPostMutation.mutate({
				request: post,
				forUserId: post?.forUserId,
				contentCalendarSuggestionId: suggestionId,
				impersonationContext,
			});
		}
		setShowSaveAsModal(false);
	};

	const onPostScheduled = React.useCallback(
		async (postViewModel: Api.SocialMediaPostViewModel, switchToDraftEditor = false) => {
			if (postViewModel) {
				await postViewModel.impersonate(impersonationCtx).load();
				setScheduledPost(postViewModel);
				context.setCampaignName(postViewModel.name);
				context.setHasChanges(true);

				if (
					switchToDraftEditor &&
					!fullscreenModal?.history?.location?.pathname?.includes('/social/edit') &&
					!impersonationContext?.isValid
				) {
					fullscreenModal?.history?.replace({
						pathname: `/social-media/post/edit-post-draft/${postViewModel.id}`,
						state: {
							viewModel: postViewModel,
						},
					});
				}
			}
		},
		[context, fullscreenModal?.history, impersonationContext?.isValid, impersonationCtx, setScheduledPost]
	);

	const onSubmitPost = React.useCallback(
		async (_: React.MouseEvent<HTMLElement>, selectedSendTime?: Moment, ignoreCompliance?: boolean) => {
			if (isSchedulingInProgress) {
				return;
			}
			setIsSchedulingInProgress(true);
			const { schedulePost, updatePost } = context;
			try {
				if (postReport || postToEdit || scheduledPost) {
					const [, error] = await updatePost(scheduledPost, selectedSendTime, ignoreCompliance);
					if (error) {
						logApiError('UpdateSocialMediaPost-Error', error);
						errorMessages.pushApiError(error);
						return;
					}

					if (source) {
						reloadSource();
					}

					if (postToEdit) {
						try {
							await postToEdit.load();
						} catch (err) {
							logApiError('UpdateSocialMediaPost-Error', err);
							errorMessages.pushApiError(err);
							return;
						}
					}

					toaster.push({
						message: !selectedSendTime
							? 'Your draft has been updated.'
							: selectedSendTime.isSame(moment(), 'hour')
								? 'Your post has been successfully sent.'
								: postToEdit?.status === Api.PostStatus.Pending
									? 'Your post has been successfully updated.'
									: 'Your post has been successfully scheduled.',
						type: 'successMessage',
					});

					if (onPostUpdated?.()) {
						return;
					} else if (isComplianceFlow && !ignoreCompliance && selectedSendTime) {
						h.replace(`${routeMatch.url}/compliance-complete`);
					} else if (selectedSendTime) {
						h.replace(`${routeMatch.url}/schedule-complete`);
					} else {
						const locationState: ILocationState<Api.SocialMediaPostViewModel, null> = {
							viewModel: postToEdit,
						};
						fullscreenModal.history.replace({
							pathname: `${routeMatch.url}`,
							state: locationState,
						});
					}
					postNotification({
						info: postToEdit,
						topic: Topics.EDIT_SOCIAL_POST,
					});
				} else {
					const [postViewModel, error] = await schedulePost(
						selectedSendTime,
						suggestionId,
						impersonationCtx,
						ignoreCompliance,
						sendWithComplianceEmail
					);
					if (error) {
						logApiError('CreateSocialMediaPost-Error', error);
						errorMessages.pushApiError(error);
						return;
					}

					toaster.push({
						message: selectedSendTime.isSame(moment(), 'hour')
							? 'Your post has been successfully sent.'
							: 'Your post has been successfully scheduled.',
						type: 'successMessage',
					});

					await onPostScheduled(postViewModel);
					locationStateCreateRequest?.onFinish?.(true);
					if (onPostUpdated?.()) {
						return;
					} else if (isComplianceFlow && !ignoreCompliance && selectedSendTime) {
						h.replace(`${routeMatch.url}/compliance-complete`);
					} else {
						h.replace(`${routeMatch.url}/schedule-complete`);
					}
					postNotification({
						info: postViewModel,
						topic: Topics.CREATE_SOCIAL_POST,
					});
				}
			} finally {
				context.setHasChanges(false);
				setIsSchedulingInProgress(false);
			}
		},
		[
			context,
			errorMessages,
			fullscreenModal.history,
			h,
			impersonationCtx,
			isComplianceFlow,
			isSchedulingInProgress,
			locationStateCreateRequest,
			logApiError,
			onPostScheduled,
			onPostUpdated,
			postNotification,
			postReport,
			postToEdit,
			reloadSource,
			routeMatch.url,
			scheduledPost,
			sendWithComplianceEmail,
			source,
			suggestionId,
			toaster,
		]
	);
	const onViewCitationsClicked = async () => {
		setShowingCitations(true);
	};

	const onUserOptionSelected = async (option: ISelectOption<UserViewModel<IUser>>, user?: UserViewModel<IUser>) => {
		initialSelectedUserOptionRef.current = option;
		if (impersonationCtx && option?.dataContext) {
			impersonationCtx.user = { id: user?.id };
			user.impersonate(impersonationCtx);
		}
		if (
			(!option.dataContext.socialMediaConnectedAccounts ||
				option.dataContext.socialMediaConnectedAccounts?.length === 0) &&
			user
		) {
			try {
				await user.load();
				context.setSelectedCreator(user.toJs());
			} catch (err) {
				errorMessages.pushApiError(err);
				logApiError('LoadUser-Error', err);
			}
		} else if (
			(!option.dataContext.socialMediaConnectedAccounts ||
				option.dataContext.socialMediaConnectedAccounts?.length === 0) &&
			!user
		) {
			try {
				await option.dataContext.load();
				context.setSelectedCreator(option.dataContext.toJs());
			} catch (err) {
				errorMessages.pushApiError(err);
				logApiError('LoadUser-Error', err);
			}
		} else {
			context.setSelectedCreator(option.dataContext);
		}
		context.setHasChanges(true);
		const connections =
			option.dataContext.socialMediaConnectedAccounts?.filter(
				x =>
					(x.state === Api.SocialMediaConnectionState.Connected ||
						x.state === Api.SocialMediaConnectionState.Disconnected) &&
					!!x.postTargetId
			) ||
			user?.socialMediaConnectedAccounts?.filter(
				x =>
					(x.state === Api.SocialMediaConnectionState.Connected ||
						x.state === Api.SocialMediaConnectionState.Disconnected) &&
					!!x.postTargetId
			) ||
			[];
		setSocialMediaConnections(
			connections?.filter(x => x.state === Api.SocialMediaConnectionState.Connected && !!x.postTargetId)
		);

		// Update below to account for locationStateCreateRequest
		context.setPostTargets(
			connections
				?.filter(
					x =>
						x.state === Api.SocialMediaConnectionState.Connected &&
						x?.postTargetId &&
						(!locationStateCreateRequest ||
							!locationStateCreateRequest?.targets?.length ||
							locationStateCreateRequest?.targets?.includes(x.type))
				)
				.map(y => ({ pageId: y.postTargetId, provider: y.type, userId: y.userId })) || []
		);
	};

	const onAIContentGenerationAssistantButtonClicked = () => {
		h.push({
			pathname: `${routeMatch.url}/ai-content-generation-wizard`,
			state: h.location.state,
		});
	};

	const IGNORE_EXPIRED_DATE = [
		Api.PostStatus.Draft,
		Api.PostStatus.Succeeded,
		Api.PostStatus.PartiallySucceeded,
		Api.PostStatus.Started,
		Api.PostStatus.Cancelled,
		Api.PostStatus.Failed,
	];

	const expirationDateInEffect =
		context?.template?.schedule?.expirationDate && !IGNORE_EXPIRED_DATE.includes(source?.status);

	const handleOnRequestClose = (suppress?: boolean, cancel?: boolean) => {
		if (suppress) {
			suppressDuplicateTemplateWarning(templateId);
		}
		if (cancel) {
			setShowDuplicateWarning(false);
			fullscreenModal?.dismissModal();
			return;
		}
		setShowDuplicateWarning(false);
	};

	const onEditNameCancelButtonClicked = () => {
		setIsEditingName(false);
	};

	const onEditNameValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setEditableCampaignName(value);
	};

	const onEditNameSaveButtonClicked = () => {
		context.setCampaignName(editableCampaignName);
		context.setHasChanges(true);
		setIsEditingName(false);
	};

	const onEditNameButtonClicked = () => {
		setIsEditingName(true);
	};

	const renderDefaultComposerContext = () => {
		return (
			<DefaultComposerContext
				context={context}
				impersonationCtx={impersonationCtx}
				expirationDateInEffect={expirationDateInEffect}
				onRenderStatusSpecificDetails={() => {
					return (
						<StatusSpecificDetails
							postToEdit={postToEdit}
							scheduledPost={scheduledPost}
							context={context}
							isComplianceFlow={isComplianceFlow}
							sendWithComplianceEmail={sendWithComplianceEmail}
							setSendWithComplianceEmail={setSendWithComplianceEmail}
							isSchedulingInProgress={isSchedulingInProgress}
							impersonationCtx={impersonationCtx}
							handleScheduleForLater={handleScheduleForLater}
							handleScheduleWithoutCompliance={handleScheduleWithoutCompliance}
							handlePostNow={handlePostNow}
							checkComplianceEmail={checkComplianceEmail}
							lastUsedComplianceRecipientEmail={lastUsedComplianceRecipientEmail}
							handleChangeDate={handleChangeDate}
							onViewCitationsClicked={onViewCitationsClicked}
							source={source}
						/>
					);
				}}
				overlayLogo={context.overlayLogo}
			/>
		);
	};
	const onSaveAsTemplateClicked = () => {
		const newTemplate: Api.ITemplate = {
			...context?.template,
			attachments: context.postImages,
			content: createContentStateWithHtmlStringValue(context.postContent).getRawRichTextContent(),
			name: context.campaignName,
			scope: context?.template?.scope,
			templateType: Api.TemplateType.SocialMediaPost,
		};
		delete newTemplate.id;
		setTemplateToBeSaved(newTemplate);
	};

	const onForkedTemplate = () => {
		setTemplateToBeSaved(null);
		invalidateAllTemplateCategorySocialQueries();
		h.push(`/campaigns/social?category=${KnownCategories.MyTemplates}`);
	};
	const updateTemplateMutation = useUpdateTemplateMutation({
		onSuccess: template => {
			toaster.push({
				message: 'Template saved successfully',
				type: 'successMessage',
			});
			context.setTemplate(template);
			invalidateAllTemplateCategorySocialQueries();
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('ErrorSavingSocialTemplate', error);
		},
	});
	const handleSave = () => {
		const template: Api.ITemplate = {
			...context?.template,
			attachments: context.postImages,
			content: createContentStateWithHtmlStringValue(context.postContent).getRawRichTextContent(),
			name: context.campaignName,
			scope: context?.template?.scope,
		};
		updateTemplateMutation.mutate({ template });
	};
	const canSaveTemplate =
		(context.template?.scope === Api.TemplateScope.User || context.template?.scope === Api.TemplateScope.Account) &&
		(context.template?.creator?.id === userSession.user.id || userSession.userRole.includes('admin'));

	const onRenderComposer = () => {
		if (!isLoaded || !userIsLoaded) {
			return <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='large' />;
		}
		const canSendOnBehalf =
			(userSession?.userRole === Api.EUserRole.Admin || userSession?.userRole === Api.EUserRole.SuperAdmin) &&
			userSession?.account?.preferences?.sendOnBehalfEnabled;
		const newPost = !source && !scheduledPost;
		const heading = !templateId && !source && !context.campaignName ? 'New Post' : context.campaignName;
		let socialMediaTableType: SocialMediaPlatformTableType = SocialMediaPlatformTableType.Reporting;
		socialMediaTableType = (!source || (source.canEdit && !impersonationCtx)) && SocialMediaPlatformTableType.Mutable;
		socialMediaTableType =
			((source && impersonationCtx) || (source && !source?.canEdit)) && SocialMediaPlatformTableType.ReadOnly;

		let socialUserOptions: ISelectOption<string | UserViewModel>[] = [];
		if (usersWithSocial?.data?.length > 0) {
			socialUserOptions = usersWithSocial.data
				.filter(x => x.id !== userSession?.user?.id)
				.filter(x => x.id !== context.selectedCreator?.id)
				.map<ISelectOption>(x => {
					return {
						dataContext: x,
						id: `option-${x.id}-social`,
						text: `${x.firstName} ${x.lastName}`,
					};
				});
		}
		return (
			<>
				<div className={css(styleSheet.composerContainer)}>
					<div className={css(styleSheet.composerContainerHeader)}>
						<div className={css(styleSheet.nameContainer, baseStyleSheet.truncateText)}>
							<div className={css(styleSheet.headingEditor)}>
								{!isEditingName ? (
									<div className={css(styleSheet.heading)}>{heading}</div>
								) : (
									<InlineValueEditor
										autoComplete='off'
										autoFocus={true}
										className={`inline-value-editor ${css(styleSheet.headingEditorInput)}`}
										id='post-name-editor-input'
										onCancelButtonClicked={onEditNameCancelButtonClicked}
										onChange={onEditNameValueChanged}
										onSaveButtonClicked={onEditNameSaveButtonClicked}
										value={editableCampaignName || context.campaignName || 'New Post'}
									/>
								)}
								{!isEditingName && !readOnly ? (
									<button disabled={false} onClick={onEditNameButtonClicked}>
										<EditItemPenIcon className={css(styleSheet.editIcon)} />
									</button>
								) : null}
							</div>
							<div className={css(styleSheet.buttonContainer)}>
								{!impersonationCtx || source ? <CopyPreviewLink anonymousAccessLink={previewLink} /> : null}

								{context?.template?.citation?.length > 0 ? (
									<button
										className={css(baseStyleSheet.ctaButtonReverseSmall, styleSheet.citationButton)}
										onClick={onViewCitationsClicked}
									>
										<span>View Citations</span>
									</button>
								) : null}
							</div>
						</div>
						{newPost && context?.template?.summary?.length > 0 ? (
							<div className={css(styleSheet.summary)}>
								<LightBulbIcon className={css(styleSheet.lightBulb)} fill='#00aae8' />
								<div>{context?.template?.summary}</div>
							</div>
						) : null}
						{newPost && (impersonationCtx || canSendOnBehalf) ? (
							<div className={css(styleSheet.postFrom)}>
								<span>Post From:</span>
								<div className={css(styleSheet.userDropdown)}>
									{impersonationCtx ? (
										<AccountUserSelectBox
											defaultOption={null}
											onOptionSelected={onUserOptionSelected}
											onRenderPlaceholder={() => <span>Select User</span>}
											adminsFilter={x => x.socialMediaConnectedAccounts?.length > 0}
											initialSelectedOption={initialSelectedUserOptionRef.current}
										/>
									) : (
										<UserSelectBox
											defaultOption={null}
											includeSearch={false}
											onOptionSelected={onUserOptionSelected}
											onRenderPlaceholder={() => <span>Select User</span>}
											initialSelectedOption={initialSelectedUserOptionRef.current}
											additionalPinnedOptions={socialUserOptions}
										/>
									)}
								</div>
								<TinyPopover
									anchor={
										<figure className={css(baseStyleSheet.flex, baseStyleSheet.itemsCenter)}>
											<InfoIcon className={css(styleSheet.infoIcon)} fillColor={brandPrimaryHover} />
										</figure>
									}
									className={css(styleSheet.infoPopover)}
									dismissOnOutsideAction={true}
									toggleOnHover={true}
									autoCloseOtherPopoversOnHover={true}
									type={PopoverType.background}
									placement={['right', 'top', 'bottom']}
								>
									<p className={css(styleSheet.popoverContent)}>Users with connected social media accounts.</p>
								</TinyPopover>
							</div>
						) : null}
					</div>
					{!readOnly ? (
						<SocialMediaNavigation
							creatingPostWithEmptyInitialContent={creatingPostWithEmptyInitialContent}
							onAIContentGenerationAssistantButtonClicked={onAIContentGenerationAssistantButtonClicked}
							canSaveTemplate={canSaveTemplate}
							updateTemplateMutation={updateTemplateMutation}
							handleSave={handleSave}
							onSaveAsTemplateClicked={onSaveAsTemplateClicked}
							newPost={newPost}
							impersonationCtx={impersonationCtx}
							context={context}
							onSaveAsButtonClicked={onSaveAsButtonClicked}
							source={source}
							onShowCancelConfirmation={onShowCancelConfirmation}
							onShowUpdateConfirmation={onShowUpdateConfirmation}
						/>
					) : null}
					<div className={css(styleSheet.composerContainerBody)}>
						{locationStateCreateRequest?.sendFromUser?.id === userSession.user.id || context?.selectedCreator ? (
							<div>
								<div className={css(styleSheet.whereToShare, styleSheet.whereToShareAdmin)}>
									<p>Where to Share</p>
								</div>
								{socialMediaConnections?.length ? (
									<SocialMediaPlatformTable
										connections={socialMediaConnections}
										onSelectionChanged={onSelectedConnectionsChanged}
										source={source}
										type={socialMediaTableType}
									/>
								) : (
									<div className={css(styleSheet.noAccountsWarning)}>
										Selected user is currently not linked to any social media accounts.
									</div>
								)}
							</div>
						) : null}
						<SocialMediaPostContentEditor
							imagesLabel='Add Media'
							messageLabel='Content'
							impersonationContext={impersonationContext}
							onPostScheduled={vm => onPostScheduled(vm, true)}
							post={postToEdit || scheduledPost}
							readOnly={
								(readOnly && scheduledPost?.status !== PostStatus.Draft) ||
								contentGenerationStatus === AIContentGenerationStatus.Generating
							}
						/>
					</div>
				</div>
				<div className={css(styleSheet.contextContainer)}>
					<Switch>
						<PrivateRoute
							location={loc}
							path={`${routeMatch.url}/ai-content-generation-wizard`}
							userSession={userSession}
						>
							<AIContentGenerationStatusContext.Provider value={contentGenerationStatusContext}>
								<AIContentGenerationWizard<
									Api.IRawRichTextContentState,
									Api.IAIGeneratedContent<Api.IRawRichTextContentState>
								>
									cautionMessage='Please review content created with AI before posting.'
									generateButtonCta={<span>Generate Post</span>}
									instructionsPlaceholder='Example: Write a post about Home Insurance benefits...'
									isInitiallyRewriting={!creatingPostWithEmptyInitialContent}
									onCloseClicked={h.goBack}
									onGenerateClicked={onGenerateClicked}
									onRetryClicked={onUndoClicked}
									styleDeclarationValues={[styleSheet.aiContentGenerationWizard]}
									type={AIContentGenerationWizardType.Social}
									assistantMessageMap={AIContentGenerationWizardAssistantSocialMessages}
								/>
							</AIContentGenerationStatusContext.Provider>
						</PrivateRoute>
						<PrivateRoute userSession={userSession} path={routeMatch.url}>
							{context.postContent || context?.postImages?.length > 0 ? (
								renderDefaultComposerContext()
							) : (
								<div className={css(styleSheet.graphicContainer)}>
									<div className={css(styleSheet.sloganContainer)}>Let&apos;s create a post</div>
									<SocialMediaPostGraphic />
								</div>
							)}
						</PrivateRoute>
					</Switch>
				</div>
			</>
		);
	};

	return (
		<div className={`${css(styleSheet.container, ...styles)} edit-social-media-post ${className || ''}`}>
			<MultiContainerHeader
				fullscreenHeader={
					!postReport && !postToEdit && !scheduledPost
						? 'Create Post'
						: readOnly || (scheduledPost && !postReport)
							? 'Viewing Post'
							: 'Edit Post'
				}
				onFullscreenRequestClose={context?.hasChanges ? onShowCancelUpdateConfirmation : onRequestClose}
			/>
			<DuplicateWarningDialog
				isOpen={showDuplicateWarning}
				onRequestClose={handleOnRequestClose}
				title='Social Media Post Duplicate'
				type={CampaignType.Social}
				duplicateType={duplicateType}
			/>
			<EditSocialMediaPostContext.Provider value={context}>
				<Switch>
					<PrivateRoute exact={false} path={`${routeMatch.url}/set-social-schedule`} userSession={userSession}>
						<SocialMediaApprovalSetSchedule
							postVm={scheduledPost}
							sendOnBehalf={
								(context?.selectedCreator?.id !== userSession.user.id && !impersonationContext?.isValid) ||
								impersonationContext?.isValid
							}
							expirationDate={
								scheduledPost?.status !== Api.PostStatus.Draft ? context?.template?.schedule?.expirationDate : null
							}
							scheduleCtaText={scheduledPost?.status === PostStatus.Pending ? 'Okay' : 'Update Schedule'}
						/>
					</PrivateRoute>
					<PrivateRoute path={`${routeMatch.url}/send-options`} userSession={userSession}>
						<EditSocialMediaSendOptions
							selectedUser={context?.selectedCreator}
							sendOnBehalf={
								(context?.selectedCreator?.id !== userSession.user.id && !impersonationContext?.isValid) ||
								impersonationContext?.isValid
							}
							ignoreCompliance={bypassCompliance}
							initialStartDate={moment(
								postReport?.dueDate || postToEdit?.dueDate || locationStateCreateRequest?.schedule?.startDate
							)}
							onSubmitPost={onSubmitPost}
							className={css(styleSheet.sendOptions)}
							expirationDate={
								postToEdit?.status !== Api.PostStatus.Draft ? context?.template?.schedule?.expirationDate : null
							}
							templateId={routeMatch.params.templateId}
							isSchedulingInProgress={isSchedulingInProgress}
						/>
						<ConfirmationDialog
							icon={<WarningIcon />}
							modalProps={{
								isOpen: showingCancelConfirmation,
								onRequestClose: onCancelConfirmationRequestClose,
							}}
							options={CancelConfirmationOptions}
							title={`Are you sure you want to ${
								source?.status === Api.PostStatus.Draft ? 'delete' : 'cancel'
							} this social media ${source?.status === Api.PostStatus.Draft ? 'draft' : 'post'}?`}
						/>
						<ConfirmationDialog
							icon={<WarningIcon />}
							modalProps={{
								isOpen: showingCancelUpdateConfirmation,
								onRequestClose: onCancelUpdateConfirmationRequestClose,
							}}
							options={CancelUpdateConfirmationOptions}
							title='Are you sure you want to cancel these updates?'
						/>
					</PrivateRoute>
					<PrivateRoute exact={true} path={`${routeMatch.url}/compliance-complete`} userSession={userSession}>
						<ComplianceConfirmation onDismiss={fullscreenModal?.dismissModal} styles={[styleSheet.childRoute]} />
					</PrivateRoute>
					<PrivateRoute userSession={userSession} path={`${routeMatch.url}/schedule-complete`}>
						<SocialMediaPostScheduleComplete
							post={scheduledPost}
							styles={[styleSheet.postCompleteContainer]}
							onClose={onRequestClose}
						/>
					</PrivateRoute>
					<PrivateRoute userSession={userSession}>
						{onRenderComposer()}
						<ConfirmationDialog
							icon={<WarningIcon />}
							modalProps={{
								isOpen: showingCancelConfirmation,
								onRequestClose: onCancelConfirmationRequestClose,
							}}
							options={CancelConfirmationOptions}
							title={`Are you sure you want to ${
								source?.status === Api.PostStatus.Draft ? 'delete' : 'cancel'
							} this social media ${source?.status === Api.PostStatus.Draft ? 'draft' : 'post'}?`}
						/>
						<ConfirmationDialog
							icon={<WarningIcon />}
							modalProps={{
								isOpen: showingCancelUpdateConfirmation,
								onRequestClose: onCancelUpdateConfirmationRequestClose,
							}}
							options={CancelUpdateConfirmationOptions}
							title='Are you sure you want to cancel these updates?'
						/>
						<ConfirmationDialog
							icon={<WarningIcon />}
							modalProps={{
								isOpen: showingUpdateConfirmation,
								onRequestClose: onUpdateConfirmationRequestClose,
							}}
							options={UpdateConfirmationOptions}
							title='Are you sure you want to update this social media post?'
						/>
						{showSaveAsModal ? (
							<TextInputModal
								cta='Save'
								modalProps={{
									isOpen: showSaveAsModal,
									onRequestClose: onSaveAsDraftPost,
								}}
								placeholderText='Name'
								title='Save as a Draft'
								initialValue={context.campaignName}
							/>
						) : null}
						<Modal
							isOpen={Boolean(templateToBeSaved)}
							onRequestClose={() => setTemplateToBeSaved(null)}
							className={css(styleSheet.saveTemplateModal)}
							useDefaultHeader
							shouldCloseOnOverlayClick={false}
						>
							{templateToBeSaved ? (
								<SaveTemplate
									canImpersonateOnTemplateSave
									template={templateToBeSaved}
									onCancel={() => setTemplateToBeSaved(null)}
									onSuccess={onForkedTemplate}
								/>
							) : null}
						</Modal>
						{showingCitations ? (
							<div>
								<ViewCitationsModal modalProps={{ isOpen: showingCitations, onRequestClose: onCitationRequestClose }}>
									{context?.template?.citation}
								</ViewCitationsModal>
							</div>
						) : null}
					</PrivateRoute>
				</Switch>
			</EditSocialMediaPostContext.Provider>
			<FabContext appearance={{ hidden: true }} />
		</div>
	);
});

export const EditSocialMediaPost = inject(
	ImpersonationContextKey,
	ModalChildComponentContextKey
)(EditSocialMediaPostBase);
