import { StyleSheet } from 'aphrodite';
import { destructiveHover, error, label } from '../../../../web/styles/colors';
import { CssSelectors } from '../../../../web/styles/styles';

export const styleSheet = StyleSheet.create({
	container: {
		...CssSelectors.allChildren({
			borderRadius: 6,
		}),
	},
	label: {
		color: label,
		marginBottom: 8,
		marginTop: 16,
		'::after': {
			color: destructiveHover,
			content: '"*"',
			display: 'inline',
			marginLeft: '0.25rem',
		},
	},
	formErrorLine: {
		color: error,
		fontSize: '0.75rem',
		marginTop: 0,
	},
	dropdown: {
		maxWidth: null,
	},
	dropdownDisabled: {
		pointerEvents: 'none',
		background: '#F9F9F9',
	},
});
