import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Automations } from '../../../../extViewmodels/Utils';
import { AdvancedTagFilter, AdvancedTagFilterCollection } from '../../../../models';
import { EditAutomationTemplateContext } from '../../../../models/Automations';
import { useToaster } from '../../../../models/hooks/appStateHooks';
import { useAllClassifiedContactPolicies } from '../../../../queries/Contact';
import { baseStyleSheet } from '../../../styles/styles';
import { FiltersEditor } from '../../FiltersEditor';
import { ISelectOption, Select } from '../../Select';
import { IAutoCompleteSearchFieldComponent } from '../../autocomplete/AutoCompleteSearchField';
import {
	ISimpleAutoCompleteSearchFieldEvent,
	ISimpleAutoCompleteSearchFieldItemSelectionEvent,
	SimpleAutoCompleteSearchField,
} from '../../autocomplete/SimpleAutoCompleteSearchField';
import { PolicySelector } from '../../email/PolicySelector';
import { styleSheet } from './styles';

interface IEditAutomationSwitchStepCaseStatement {
	criteria?: Api.IContactFilterCriteria;
	onCriteriaChanged?(criteria?: Api.IContactFilterCriteria): void;
	switchStep: Api.AutomationTemplateEditorStep<Api.SwitchAutomationStepViewModel>;
}

function getSelectedPoliciesFromFilterRequest(filterRequest?: Api.IContactsFilterRequest): string[] {
	if (!filterRequest) {
		return [];
	}
	const onlyOneValueSelected = !filterRequest.criteria || !filterRequest.criteria?.length;

	if (filterRequest?.value && onlyOneValueSelected) {
		return [filterRequest.value];
	} else if (
		filterRequest.criteria?.length &&
		filterRequest.criteria.every(y => y.property === Api.ContactFilterCriteriaProperty.Policy)
	) {
		// @ts-ignore
		return filterRequest.criteria.filter(x => x.value !== undefined).map(x => x.value);
	}
	return [];
}

const getEditAutomationSwitchStepCaseStatementSelectOptions = (
	trigger?: Api.IAutomationTrigger,
	includeDefaultSegmentOption = false
) => {
	const options: ISelectOption<Api.IContactFilterCriteria>[] = [];
	if (Automations.triggers.isResourceSelectorTrigger(trigger, Api.ResourceSelectorId.PolicyRenew)) {
		options.push({
			dataContext: {
				criteria: [],
				op: Api.FilterOperator.Or,
				property: Api.ContactFilterCriteriaProperty.Policy,
			},
			id: Api.ResourceSelectorId.PolicyRenew,
			text: 'this policy renewing is',
		});
	}

	options.push({
		dataContext: {
			op: Api.FilterOperator.And,
			property: Api.ContactFilterCriteriaProperty.Tag,
		},
		id: 'tag-and',
		text: 'contact has this tag',
	});

	options.push({
		dataContext: {
			op: Api.FilterOperator.Not,
			property: Api.ContactFilterCriteriaProperty.Tag,
		},
		id: 'tag-not',
		text: 'contact is missing this tag',
	});

	if (includeDefaultSegmentOption) {
		options.push({
			// @ts-ignore
			dataContext: null,
			id: 'default-case',
			text: 'contact is not in a segment (everyone else)',
		});
	}
	return options;
};

interface IEditAutomationSegmentPrimaryFilterProps extends IEditAutomationSwitchStepCaseStatement {
	caseStatement: Api.IAutomationStepCaseStatement;
	filterOperatorSelectBoxStyles?: StyleDeclarationValue[];
	styles?: StyleDeclarationValue[];
	impersonationContext?: Api.IImpersonationContext;
}

export const EditAutomationSegmentPrimaryFilter: React.FC<IEditAutomationSegmentPrimaryFilterProps> = props => {
	const {
		criteria,
		onCriteriaChanged,
		filterOperatorSelectBoxStyles,
		caseStatement,
		styles = [],
		impersonationContext,
	} = props;
	const context = React.useContext(EditAutomationTemplateContext);
	const trigger =
		context?.parentAutomationTemplate?.draftTriggerReference ||
		context?.parentAutomationTemplate?.publishedTriggerReference;

	const autocompleteInputRef = React.createRef<HTMLInputElement>();
	const autocompleteComponentRef = React.useRef<IAutoCompleteSearchFieldComponent>(null);
	const onAutocompleteComponentRef = React.useCallback((ref?: IAutoCompleteSearchFieldComponent) => {
		// @ts-ignore
		autocompleteComponentRef.current = ref;
	}, []);

	const options = React.useMemo(
		() => getEditAutomationSwitchStepCaseStatementSelectOptions(trigger, !!caseStatement?.isDefault),
		[caseStatement?.isDefault, trigger]
	);
	// @ts-ignore
	const [selectedOption, setSelectedOption] = React.useState<ISelectOption<Api.IContactFilterCriteria>>(() => {
		return (
			options.find(
				x => x.dataContext?.property === criteria?.property && (x.dataContext?.op === criteria?.op || !criteria?.op)
			) || options.find(x => x.dataContext === null)
		);
	});

	const selectedPolicies = React.useMemo(() => getSelectedPoliciesFromFilterRequest(criteria), [criteria]);

	const { data: allClassifiedPolicies, isLoading: isLoadingAllClassifiedPolicies } = useAllClassifiedContactPolicies({
		contactsRequest: { criteria: [] },
		refetchOnWindowFocus: false,
		impersonationContext,
	});

	const onSelectOptionClicked = React.useCallback(
		(option: ISelectOption<Api.IContactFilterCriteria>, wasSelected: boolean) => {
			if (wasSelected) {
				const propertyChanged = option.dataContext?.property !== criteria?.property;
				const opChanged = option.dataContext?.op !== criteria?.op;
				if (propertyChanged || opChanged) {
					autocompleteComponentRef.current?.clearInput();
				}

				setSelectedOption(option);
				onCriteriaChanged?.(
					// @ts-ignore
					option.dataContext
						? {
								...(option.dataContext || {}),
							}
						: null
				);
			}
		},
		[onCriteriaChanged, criteria]
	);

	const autocompleteType =
		Automations.triggers.isResourceSelectorTrigger(trigger, Api.ResourceSelectorId.PolicyRenew) &&
		selectedOption?.id === Api.ResourceSelectorId.PolicyRenew
			? Api.ResourceAutoCompleteViewModelType.LineOfBusiness
			: Api.ResourceAutoCompleteViewModelType.Tag;

	const onSearchFieldItemSelected = React.useCallback(
		(e: ISimpleAutoCompleteSearchFieldItemSelectionEvent<string>) => {
			const value = e.selection;
			if (e.target) {
				e.target.setSearchQuery(value);
			}

			onCriteriaChanged?.({
				...(selectedOption?.dataContext || {}),
				property: Api.ContactFilterCriteriaProperty.Tag,
				value: value || '',
			});
		},
		[onCriteriaChanged, selectedOption?.dataContext]
	);

	const onSearchFieldClearClicked = React.useCallback(() => {
		onCriteriaChanged?.({
			...(selectedOption?.dataContext || {}),
			property: Api.ContactFilterCriteriaProperty.Tag,
			value: '',
		});
	}, [onCriteriaChanged, selectedOption?.dataContext]);

	const onSearchFieldBlur = React.useCallback(
		(e: ISimpleAutoCompleteSearchFieldEvent<React.FocusEvent<HTMLInputElement>>) => {
			onCriteriaChanged?.({
				...(selectedOption?.dataContext || {}),
				property: Api.ContactFilterCriteriaProperty.Tag,
				value: e.sourceEvent?.target?.value || '',
			});
		},
		[onCriteriaChanged, selectedOption?.dataContext]
	);
	const onRenewalPoliciesChanged = React.useCallback(
		(policies: string[]) => {
			const nextRequest: Api.IContactsFilterRequest = policies.length
				? {
						criteria: policies.map(x => {
							return {
								property: Api.ContactFilterCriteriaProperty.Policy,
								value: x,
							};
						}),
						property: Api.ContactFilterCriteriaProperty.Policy,
						op: Api.FilterOperator.Or,
					}
				: {
						criteria: [],
					};
			onCriteriaChanged?.(nextRequest);
		},
		[onCriteriaChanged]
	);

	const searchFieldStyle = React.useRef([styleSheet.filterSearchField]).current;
	const placeholder = !criteria?.property
		? 'Search'
		: `Search ${criteria.property === Api.ContactFilterCriteriaProperty.Policy ? 'policy' : 'tags'}`;

	const primaryFilterStylesAlt = React.useRef<StyleDeclarationValue[]>([styleSheet.primaryFilterAlt]).current;

	return (
		<div
			className={css(
				baseStyleSheet.horizontalStack,
				criteria && autocompleteType === Api.ResourceAutoCompleteViewModelType.LineOfBusiness
					? [...styles]
					: [...primaryFilterStylesAlt]
			)}
		>
			{caseStatement?.isDefault ? (
				<div className={css(...(filterOperatorSelectBoxStyles || []))}>{selectedOption?.text}</div>
			) : (
				<Select
					disabled={!!caseStatement?.isDefault}
					onOptionClick={onSelectOptionClicked}
					options={options}
					selectedOption={selectedOption}
					showCaret={!caseStatement?.isDefault}
					styles={filterOperatorSelectBoxStyles}
				/>
			)}
			{criteria && autocompleteType === Api.ResourceAutoCompleteViewModelType.LineOfBusiness ? (
				<PolicySelector
					classifiedPolicies={allClassifiedPolicies}
					isLoadingClassifiedPolicies={isLoadingAllClassifiedPolicies}
					onSelectedPoliciesChanged={onRenewalPoliciesChanged}
					selectedPolicies={selectedPolicies}
				/>
			) : criteria ? (
				<SimpleAutoCompleteSearchField
					initialSearchQuery={criteria?.value || undefined}
					onBlur={onSearchFieldBlur}
					onClear={onSearchFieldClearClicked}
					onInputRef={autocompleteInputRef}
					// @ts-ignore
					onItemSelected={onSearchFieldItemSelected}
					onSearchFieldRef={onAutocompleteComponentRef}
					placeholder={placeholder}
					style={searchFieldStyle}
					type={autocompleteType}
				/>
			) : null}
		</div>
	);
};

interface IEditAutomationSegmentAdditionalFiltersProps {
	initialCriteria?: Api.IContactFilterCriteria[];
	onCriteriaChanged?(criteria: Api.IContactFilterCriteria[]): void;
}

const maxContactFiltersToDisplay = 50;
const filterOperatorSelectOptions: ISelectOption<Api.FilterOperator>[] = [
	{
		dataContext: Api.FilterOperator.And,
		id: 'filter-option-and',
		text: 'contact has this tag',
	},
	{
		dataContext: Api.FilterOperator.Or,
		id: 'filter-option-or',
		text: 'contact has at least one of these tags',
	},
];

export const EditAutomationSegmentAdditionalFilters: React.FC<IEditAutomationSegmentAdditionalFiltersProps> = observer(
	props => {
		const { onCriteriaChanged, initialCriteria } = props;
		const toaster = useToaster();
		const filterCollection = React.useRef<AdvancedTagFilterCollection>(
			initialCriteria
				? AdvancedTagFilterCollection.instanceWithCriteria(initialCriteria)
				: AdvancedTagFilterCollection.instanceWithFilters([])
		).current;
		// @ts-ignore
		const [filterOperator, setFilterOperator] = React.useState<Api.FilterOperator.And | Api.FilterOperator.Or>(() => {
			if (initialCriteria) {
				return initialCriteria.find(x => x.op !== Api.FilterOperator.Not)?.op as
					| Api.FilterOperator.And
					| Api.FilterOperator.Or;
			}
			return null;
		});

		const getFilters = React.useCallback(
			(type: Api.FilterOperator) => {
				return filterCollection.filters.filter(filter => {
					return filter.type === type;
				});
			},
			[filterCollection]
		);

		const onSetFilterOperatorOption = React.useCallback(
			(option: ISelectOption<Api.FilterOperator.And | Api.FilterOperator.Or>, wasSelected: boolean) => {
				if (wasSelected) {
					setFilterOperator(option.dataContext);
				}
			},
			[]
		);

		const notifyCriteriaChanged = React.useCallback(() => {
			if (onCriteriaChanged) {
				const criteriaCollection: Api.IContactFilterCriteria[] = [];

				if (filterOperator !== null && filterOperator !== undefined) {
					criteriaCollection.push({
						criteria: getFilters(filterOperator).map<Api.IContactFilterCriteria>(x => {
							const criteria = x.toContactFilterCriteria();
							delete criteria.op;
							return criteria;
						}),
						op: filterOperator,
						property: Api.ContactFilterCriteriaProperty.Tag,
					});
				}

				const notFilters = getFilters(Api.FilterOperator.Not);
				if (notFilters?.length > 0) {
					notFilters.forEach(x => criteriaCollection.push(x.toContactFilterCriteria()));
				}

				onCriteriaChanged(criteriaCollection);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [filterCollection, filterOperator, getFilters, onCriteriaChanged]);

		const onAddFilter = React.useCallback(
			(filter: AdvancedTagFilter) => {
				if (!filterCollection.hasValue(filter.tag)) {
					filterCollection.add(filter);
					notifyCriteriaChanged();
				} else {
					// @ts-ignore
					toaster.push({
						message: `"${filter.tag}" is already included as part of the filters.`,
						type: 'errorMessage',
					});
				}
			},
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[filterCollection, notifyCriteriaChanged]
		);

		const onRemoveFilter = React.useCallback(
			(filter: AdvancedTagFilter) => {
				filterCollection.remove(filter);
				notifyCriteriaChanged();
			},
			[notifyCriteriaChanged, filterCollection]
		);

		const filterOperatorSelectTitle = React.useMemo(() => {
			return filterOperator === null || filterOperator === undefined ? (
				<div>Please select...</div>
			) : (
				<div>{filterOperatorSelectOptions.find(opt => opt.dataContext === filterOperator)?.text}</div>
			);
		}, [filterOperator]);

		return (
			<div>
				<div className={css(styleSheet.tagFiltersLabel)}>Additional filters</div>
				<Select
					onOptionClick={onSetFilterOperatorOption}
					options={filterOperatorSelectOptions}
					selectedOptionTitle={filterOperatorSelectTitle}
					styles={[styleSheet.tagFiltersInput]}
				/>
				{filterOperator !== null && filterOperator !== undefined && (
					<FiltersEditor
						className={styleSheet.tagFiltersInput}
						disabled={filterCollection?.length >= maxContactFiltersToDisplay}
						filters={getFilters(filterOperator)}
						onAddFilter={onAddFilter}
						onRemoveFilter={onRemoveFilter}
						type={filterOperator}
					/>
				)}
				<div className={css(styleSheet.tagFiltersLabel)}>But exclude contacts that have...</div>
				<FiltersEditor
					className={styleSheet.tagFiltersInput}
					disabled={filterCollection?.length >= maxContactFiltersToDisplay}
					filters={getFilters(Api.FilterOperator.Not)}
					onAddFilter={onAddFilter}
					onRemoveFilter={onRemoveFilter}
					type={Api.FilterOperator.Not}
				/>
			</div>
		);
	}
);
