import { StyleSheet } from 'aphrodite';
import {
	brandPrimaryText,
	brandSecondary,
	darkGrayFontColor,
	dashboardNavBackground,
	label,
	mention,
	mentionDark,
	navigation,
	titles,
	warningDark,
} from '../../../styles/colors';
import { CssSelectors, cardStyle } from '../../../styles/styles';

const contextContainerWidth = 440;
const composerContainerMargin = 25;
const borderBottom = `1px solid ${mentionDark}`;

export const styleSheet = StyleSheet.create({
	aiContentGenerationWizard: {
		height: '100%',
	},
	approvalStatusTitle: {
		color: warningDark,
		fontSize: 14,
		lineSpacing: '1.1px',
		textAlign: 'left',
		textTransform: 'uppercase',
	},
	approve: {
		marginTop: 10,
	},
	advancedOptions: {
		textAlign: 'left',
		fontSize: 12,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: 12,
	},
	buttonContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		paddingLeft: 15,
		...CssSelectors.allChildren({
			marginTop: 5,
		}),
	},
	cancel: {
		justifyContent: 'center',
		marginTop: 20,
	},
	card: {
		...cardStyle,
		boxSizing: 'border-box',
		color: label,
		fontSize: 14,
		marginRight: 10,
		marginTop: 20,
		overflow: 'visible',
		padding: '15px 30px',
	},
	cardActions: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		...CssSelectors.allChildren(
			{
				fontSize: 14,
			},
			'button'
		),
	},
	cardSubSection: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 10,
		...CssSelectors.allChildren({
			height: 24,
			width: 30,
		}),
	},
	cardTitle: {
		color: titles,

		fontSize: 16,
		marginBottom: 6,
	},
	charCount: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginRight: 10,
	},
	childRoute: {
		boxSizing: 'border-box',
		height: '100%',
		overflow: 'auto',
		padding: 20,
	},
	citationButton: {
		color: brandPrimaryText,
		marginLeft: 10,
		minWidth: 120,
		padding: '4px 12px',
	},
	composerContainer: {
		border: `1px solid ${mentionDark}`,
		borderRadius: 3,
		boxShadow: `0px 0px 12px ${mention}`,
		boxSizing: 'border-box',
		height: `calc(100% - ${2 * composerContainerMargin}px)`,
		margin: 20,
		overflowY: 'auto',
		width: `calc(100% - ${contextContainerWidth + 2 * composerContainerMargin - 40}px)`,
	},
	composerContainerBody: {
		...CssSelectors.allChildren({
			color: brandSecondary,
			fontSize: 18,
			marginRight: 20,
			paddingLeft: 20,
		}),
	},
	composerContainerHeader: {
		borderBottom,
		boxSizing: 'border-box',
		color: brandPrimaryText,
		display: 'flex',
		flexDirection: 'column',
		fontSize: 24,
		justifyContent: 'center',
		paddingBottom: 16,
		paddingTop: 16,
	},
	complianceInput: {
		marginBottom: 10,
		minWidth: 240,
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
	},
	contentContainers: {
		color: navigation,
		fontSize: 14,
		marginRight: 20,
	},
	contextContainer: {
		display: 'flex',
		flexDirection: 'column',
		height: 'calc(100% - 30px)',
		overflow: 'auto',
		width: contextContainerWidth,
	},
	copyLinkField: {
		marginRight: 10,
		minWidth: 168,
		padding: '2px 12px',
	},
	ctaContainer: {
		color: brandPrimaryText,
		flex: 0.1,
		marginTop: 20,
		textAlign: 'center',
	},
	ctaContainerButtons: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		marginBottom: 16,
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 16,
	},
	disclaimerText: {
		fontSize: 12,
		paddingBottom: 10,
		textAlign: 'center',
		width: '60%',
	},
	draftSender: {
		alignItems: 'center',
		background: 'rgba(248, 145, 67, 0.12)',
		color: warningDark,
		display: 'flex',

		fontSize: 12,
		justifyContent: 'center',
		marginTop: 20,
		padding: '12px 0',
	},
	editIcon: {
		marginLeft: 8,
	},
	expirationDate: {
		color: brandPrimaryText,
	},
	expirationDateContainer: {
		alignItems: 'center',
		background: 'rgba(248, 145, 67, 0.12)',
		color: warningDark,
		display: 'flex',

		fontSize: 12,
		justifyContent: 'center',
		padding: '12px 0',
	},
	graphicContainer: {
		alignItems: 'center',
		background: mention,
		color: dashboardNavBackground,
		display: 'flex',
		flex: 1,
		flexDirection: 'column',

		fontSize: 30,
		marginBottom: 10,
		minHeight: 520,
		paddingTop: 30,
	},
	headerStackContainer: {
		marginLeft: 10,
	},
	heading: {
		minWidth: '28%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	headingEditor: {
		display: 'flex',
		maxWidth: `calc(100% - 200px)`,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	headingEditorInput: {
		flexWrap: 'wrap',
		width: 430,
		...CssSelectors.allChildren(
			{
				color: darkGrayFontColor,
				fontSize: 14,
				height: 34,
				marginBottom: 4,
				marginTop: 4,
				padding: '2px 8px',
				textOverflow: 'ellipsis',
			},
			'input'
		),
		...CssSelectors.allChildren(
			{
				marginBottom: 4,
				marginTop: 4,
			},
			'button'
		),
	},
	iconContainer: {
		display: 'flex',
		marginRight: 2,
	},
	iconHide: {
		display: 'none',
	},
	imageDisplayAreaPreview: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		...CssSelectors.allDescendants(
			{
				':last-child': {
					borderBottomLeftRadius: 10,
					borderBottomRightRadius: 10,
				},
			},
			'img'
		),
	},
	infoIcon: {
		padding: 6,
	},
	infoPopover: {
		marginLeft: 2,
	},
	infoPopoverToggle: {
		width: 14,
		height: 14,
	},
	lightBulb: {
		alignSelf: 'flex-start',
		display: 'flex',
		flexShrink: 0,
		marginRight: 6,
		width: 24,
	},
	nameContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		maxWidth: '100%',
		padding: '4px 20px',
	},
	noAccountsWarning: {
		color: navigation,
		fontSize: 12,
		marginTop: 10,
	},
	popover: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		marginLeft: 14,
		maxWidth: 300,
		padding: '6px 6px 6px 6px',
	},
	popoverContent: {
		borderRadius: 3,
		color: titles,
		fontSize: 12,
		margin: 4,
		padding: 6,
		width: 200,
	},
	postCompleteContainer: {
		padding: 30,
	},
	postFrom: {
		alignItems: 'center',
		display: 'flex',
		marginLeft: 20,
		...CssSelectors.allChildren(
			{
				color: darkGrayFontColor,
				fontSize: 14,
			},
			'span'
		),
	},
	postHeaderContainerLeft: {
		display: 'flex',
		paddingBottom: 4,
		paddingLeft: 24,
	},
	sendOptions: {
		display: 'block',
		minWidth: '100%',
	},
	sloganContainer: {
		paddingBottom: 40,
		paddingTop: 50,
	},
	standAloneButton: {
		marginLeft: 10,
	},
	summary: {
		boxSizing: 'border-box',
		color: navigation,
		display: 'flex',
		fontSize: 14,
		fontStyle: 'italic',
		margin: '4px 40px 8px 20px',
	},
	textAreaContainer: {
		...CssSelectors.allChildren({
			height: 110,
			padding: 14,
		}),
	},
	updateButton: {
		minWidth: 65,
		textAlign: 'center',
	},
	updateCancelContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	userDropdown: {
		alignItems: 'center',
		color: darkGrayFontColor,
		display: 'flex',
		fontSize: 14,
		marginLeft: 6,
		...CssSelectors.allChildren({
			width: 229,
		}),
	},
	userSelectBox: {
		maxWidth: '35%',
	},
	whereToShare: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 5,
		...CssSelectors.allChildren(
			{
				margin: 0,
			},
			'p'
		),
		...CssSelectors.allChildren(
			{
				color: darkGrayFontColor,
				fontSize: 14,
			},
			'span'
		),
	},
	whereToShareAdmin: {
		marginTop: 20,
	},
	saveTemplateModal: {
		':nth-child(1n) > .modal-overlay': {
			':nth-child(1n) > .modal-overlay-content': {
				width: 540,
			},
		},
	},
	toggle: {},
});
