import { StyleDeclarationValue, css } from 'aphrodite';
import { IEmojiData } from 'emoji-picker-react';
import * as React from 'react';
import { IFileAttachment, SocialMediaPostViewModel } from '../../../../extViewmodels';
import { useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { ImpersonationContextViewModel } from '../../../../viewmodels/AdminViewModels';
import { useEditSocialMediaPostContext } from '../../../containers/socialMedia/EditSocialMediaPost/context';
import { MediaChooserModal } from '../../MediaChooser';
import { IModalProps } from '../../Modal';
import { TextArea } from '../../TextArea';
import { EmojiPickerButton } from '../../texting/EmojiPickerButton';
import { FileInputModal } from '../FileInputModal';
import { SocialMediaImageSearchAndDisplay } from '../SocialMediaImageSearchAndDisplay';
import { SocialMediaImagesPreviewModal } from '../SocialMediaImagesPreviewModal';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	headerLabel?: string;
	imagesLabel?: string;
	impersonationContext?: ImpersonationContextViewModel;
	messageLabel?: string;
	onPostScheduled?: (postViewModel: SocialMediaPostViewModel) => void;
	post?: SocialMediaPostViewModel;
	readOnly?: boolean;
	styles?: StyleDeclarationValue[];
}

interface IPixabayModalProps extends IModalProps {
	content?: string;
}

export const SocialMediaPostContentEditor: React.FC<IProps> = props => {
	const {
		className,
		headerLabel,
		imagesLabel,
		impersonationContext,
		messageLabel,
		onPostScheduled,
		post,
		readOnly,
		styles = [],
	} = props;
	const { postContent, postImages, setContent, setImages, hasVideo, overlayLogo } = useEditSocialMediaPostContext();
	const userSession = useUserSession();
	const toaster = useToaster();

	const inputRef = React.useRef(null);

	const onInputRef = (ref: HTMLElement) => {
		inputRef.current = ref;
	};

	const onTextInputChanged = React.useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			setContent(e.target.value);
		},
		[setContent]
	);

	// #region Pixabay
	const onFreeImageChooserRequestCloseRef = React.useRef<(img?: IFileAttachment, cancel?: boolean) => void>(null);
	const [freeImageModalProps, setFreeImageModalProps] = React.useState<IPixabayModalProps>({
		isOpen: false,
		onRequestClose: (result, cancel) => onFreeImageChooserRequestCloseRef.current?.(result, cancel),
	});

	// The following will need to be updated to account for other media types
	const onFreeImageChooserRequestClose = React.useCallback((imageFile?: IFileAttachment, cancel?: boolean) => {
		setFreeImageModalProps(value => {
			return {
				...value,
				isOpen: false,
			};
		});
		if (cancel) {
			return;
		}
		setImages(val => {
			return [...val, imageFile];
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	onFreeImageChooserRequestCloseRef.current = onFreeImageChooserRequestClose;
	// #endregion

	const onClickFreeImage = () => {
		setFreeImageModalProps(value => {
			return {
				...value,
				isOpen: true,
			};
		});
	};
	const [fileInputModalOpen, setFileInputModalOpen] = React.useState(false);
	const onRequestFileInputClose = (vm?: SocialMediaPostViewModel, cancel?: boolean) => {
		if (onPostScheduled && !cancel && vm) {
			onPostScheduled(vm);
		}
		setFileInputModalOpen(false);
	};

	const [imagePreviewModalOpen, setImagePreviewModalOpen] = React.useState(false);
	const [initialImageUrl, setInitialImageUrl] = React.useState<string>('');

	const onClickEnlargeImage = (initialUrl: string) => {
		setInitialImageUrl(initialUrl);
		setImagePreviewModalOpen(true);
	};

	const onEmojiClick = React.useCallback(
		(_: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
			setContent(
				`${
					postContent.substring(0, inputRef.current.selectionStart) +
					data.emoji +
					postContent.substring(inputRef.current.selectionStart)
				}`
			);
		},
		[postContent, setContent]
	);

	const onValidateSelectedFile = (file: File) => {
		const mimeType = file.type?.toLocaleLowerCase();
		if (!(mimeType.startsWith('video/') || mimeType.startsWith('image/'))) {
			toaster.push({
				message: 'Please select a valid image or video file.',
				type: 'errorMessage',
			});
			return false;
		}

		if (mimeType.startsWith('video/') && !hasVideo && postImages?.length > 0) {
			toaster.push({
				message: 'This post already has a image. Please remove the image to add a video.',
				type: 'errorMessage',
			});
			return false;
		}

		if (mimeType.startsWith('image/') && hasVideo) {
			toaster.push({
				message: 'This post already has a video. Please remove the video to add an image.',
				type: 'errorMessage',
			});
			return false;
		}

		return true;
	};

	const acceptedMimeTypes =
		!hasVideo && postImages?.length > 0
			? 'image/png, image/jpeg, image/jpg, .svg'
			: 'image/png, image/jpeg, image/jpg, .svg, video/mp4, video/quicktime';

	return (
		<div className={`${css(styleSheet.container, ...styles)} social-media-post-content-editor ${className || ''}`}>
			<div className={css(styleSheet.headerContainer)}>
				{headerLabel && !readOnly && <div className={css(styleSheet.header)}>{headerLabel}</div>}
			</div>
			<div className={css(styleSheet.composerContainer)}>
				<div className={css(styleSheet.labelContainer)}>
					<div className={`emoji-label-container ${css(styleSheet.labelEmoji)}`}>
						{messageLabel && <label>{messageLabel}</label>}
					</div>
					<div className={css(styleSheet.charCount)}>
						<EmojiPickerButton onEmojiClick={!readOnly ? onEmojiClick : null} popoverPlacement={['left', 'top']} />
						{postContent?.length > 0 ? `${postContent?.length}/2200` : '2200'}
					</div>
				</div>
				<TextArea
					inputId='social-media-content'
					disabled={readOnly}
					className={css(styleSheet.textAreaContainer)}
					onChange={onTextInputChanged}
					inputRef={onInputRef}
					value={postContent || ''}
				/>
			</div>

			<div className={`add-media-container ${css(styleSheet.mediaContainer)}`}>
				<label>{imagesLabel && !readOnly ? imagesLabel : 'Media'}</label>
				<SocialMediaImageSearchAndDisplay
					onClickPixabayImage={onClickFreeImage}
					onClickMedia={() => setFileInputModalOpen(true)}
					onClickEnlargeImage={onClickEnlargeImage}
					hasVideo={hasVideo}
					readOnly={readOnly}
				/>
			</div>

			<MediaChooserModal
				className={css(styleSheet.modal)}
				content={postContent}
				imageOnly={false} // Not currently active to offer other media types
				modalProps={freeImageModalProps}
				onSave={onFreeImageChooserRequestClose}
				useBasicSearch={false}
			/>
			<FileInputModal
				acceptedMimeTypes={acceptedMimeTypes}
				impersonationContext={impersonationContext}
				limit={hasVideo ? 1 : undefined}
				modalProps={{
					isOpen: fileInputModalOpen,
					onRequestClose: onRequestFileInputClose,
				}}
				onPostScheduled={onPostScheduled}
				onValidateSelectedFile={onValidateSelectedFile}
				post={post}
			/>
			<SocialMediaImagesPreviewModal
				modalProps={{
					isOpen: imagePreviewModalOpen,
					onRequestClose: () => setImagePreviewModalOpen(false),
				}}
				initialImageUrl={initialImageUrl || ''}
				images={postImages}
				showOverlayLogo={overlayLogo}
				logoUrl={userSession?.account?.preferences?.logo?.url || ''}
			/>
		</div>
	);
};
