import * as Api from '@ViewModels';
import { css, StyleDeclarationValue } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useLocation } from 'react-router';
import { v4 as uuidgen } from 'uuid';
import {
	ICampaignApprovalCommand,
	IContentCalendarSelectedSuggestion,
	ICreateCampaignRequest,
	ICreateSocialMediaPostRequest,
	IImpersonationContextComponentProps,
	ILocationState,
	ImpersonationContextKey,
	IMultiCampaignSchedulerRequest,
	TemplateOrTemplateFilter,
} from '../../../models';
import { CampaignType } from '../../../models/AdminModels';
import { BlogPostRequest } from '../../../models/Blogs';
import {
	EDuplicateType,
	getDuplicateTemplateType,
	shouldShowDuplicateTemplateWarning,
	suppressDuplicateTemplateWarning,
} from '../../../models/Campaigns';
import { useErrorMessages, useFullscreenModal, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import { usePersistChangeInBrowserStorage } from '../../../models/hooks/usePersistChangeInBrowserStorage';
import { ILocalNotification } from '../../../models/LocalNotifications';
import { Topics } from '../../../models/LocalNotificationTopics';
import { useEventLogging } from '../../../models/Logging';
import { storagePrefixExcludedFromClear } from '../../../models/Storage';
import {
	getBlogPostAsync,
	getBlogPostTemplateAsync,
	invalidateAllContentCalendarSuggestions,
	invalidateEmailContentCalPendingApprovalQuery,
	invalidateSocialContentCalPendingApprovalQuery,
	useBlogContentCalApproveMutation,
	useBlogContentCalPendingApprovalQuery,
	useContentCalendarSuggestionFilterQueries,
	useContentCalendarSuggestionFilterQuery,
	useEmailContentCalApproveMutation,
	useEmailContentCalPendingApprovalQuery,
	useSocialContentCalApproveMutation,
	useSocialContentCalPendingApprovalQuery,
} from '../../../queries';
import { CampaignsApprovalViewModel, ITemplateCard } from '../../../viewmodels/AppViewModels';
import { CampaignCalendar, ICampaignCalendarComponent } from '../../components/campaigns/CampaignCalendar';
import {
	CampaignCalendarSuggestionsContext,
	useCreateCampaignCalendarSuggestionsContext,
} from '../../components/campaigns/CampaignCalendar/context';
import {
	EventDataType,
	ICampaignCalendarEventPlaceholder,
	ICampaignCalendarOptions,
} from '../../components/campaigns/CampaignCalendar/models';
import {
	DuplicateWarningDialog,
	IDuplicateWarningDialogProps,
} from '../../components/campaigns/DuplicateWarningDialog';
import {
	ConfirmationDialog,
	DefaultConfirmationOptions,
	IConfirmationDialogOption,
} from '../../components/ConfirmationDialog';
import { FabContext } from '../../components/FabContext';
import { MediaQueryObserver } from '../../components/helpers/mediaQuery';
import { LocalNotificationObserver } from '../../components/LocalNotificationObserver';
import { DarkMainContainerBackground } from '../../components/MainContainerBackground';
import { MultiContainerHeader } from '../../components/MultiContainerHeader';
import { ISelectOption, Select } from '../../components/Select';
import { ApproveAllIcon } from '../../components/svgs/icons/ApproveAllIcon';
import { WarningIcon } from '../../components/svgs/icons/WarningIcon';
import { Toggle } from '../../components/Toggle';
import { brandPrimary, inputBorderColor } from '../../styles/colors';
import { bs } from '../../styles/styles';
import {
	ContentCalendarTemplateBrowser,
	IContentCalendarTemplateBrowserComponent,
} from './ContentCalendarTemplateBrowser';
import { CalendarAccountAgeOptions, CalendarType, isSuggestionInCampaignTypes } from './models';
import { CampaignTypeLabel, ContentCalendarLegend, ContentCalendarTabs } from './presentation';
import { styleSheet } from './styles';

interface Props extends IImpersonationContextComponentProps {
	calendarOptions?: ICampaignCalendarOptions;
	styles?: StyleDeclarationValue[];
	className?: string;
	onOpenCampaignComposer?(template: Api.ITemplate, schedule?: Api.IScheduledSend, referrer?: string): boolean;
	onOpenMultiSuggestionsScheduler?(
		campaignType: CampaignType,
		suggestions: IContentCalendarSelectedSuggestion[],
		onFinish?: () => void
	): boolean;
	onCampaignClicked?(e: React.MouseEvent<HTMLElement>, campaign: Api.CampaignViewModel): void;
	onBlogPostClicked?(e: React.MouseEvent<HTMLElement, MouseEvent>, blog: Api.IBlogReportView): void;
	onCampaignGroupClicked?(e: React.MouseEvent<HTMLElement>, campaigns: Api.CampaignViewModel[]): void;
	/** @returns True if handled */
	onNotifyClientClicked?(campaign: EventDataType): void;
	onCampaignCalendarRef?(ref?: ICampaignCalendarComponent): void;
	onRenderHeaderAccessory?(): React.ReactNode;
	onSocialMediaPostClicked?(
		e: React.MouseEvent<HTMLElement, MouseEvent>,
		postReport: Api.SocialMediaPostReportViewModel
	): Promise<any>;
	onSuggestionClicked?(
		e: React.MouseEvent<HTMLElement, MouseEvent>,
		suggestion: Api.IContentCalendarSuggestion
	): Promise<any>;
	onSuggestedSocialMediaEventClicked?(
		e: React.MouseEvent<HTMLElement>,
		suggestion: Api.IContentCalendarSuggestion
	): Promise<any>;
}

export interface IContentCalendarComponent {
	loadAllCalendarData?(): Promise<any>;
}

const newAccountCutoffDays = 30;
const numMonthsInQuarterly = 3;
const droppableType = 'campaignTemplateCard';

const _ContentCalendar = React.forwardRef<IContentCalendarComponent, Props>(function ContentCalendarBase(props, ref) {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const location = useLocation<ICampaignApprovalCommand>();
	const { logApiError, logEvent } = useEventLogging('ContentCalendar');
	const fullscreenModal = useFullscreenModal();
	const [month, setMonth] = React.useState(moment().startOf('month').toDate());
	const [duplicateTemplateDialogProps, setDuplicateTemplateDialogProps] = React.useState<IDuplicateWarningDialogProps>({
		isOpen: false,
		onRequestClose: () => setDuplicateTemplateDialogProps(value => ({ ...value, isOpen: false })),
		title: 'Campaign Duplicate',
		type: CampaignType.Email,
		duplicateType: EDuplicateType.User,
	});
	const [calendarType, setCalendarType] = usePersistChangeInBrowserStorage<CalendarType>({
		storageType: 'localStorage',
		storageKey: `${storagePrefixExcludedFromClear}-content-calendar-calendar-type`,
		defaultIfEmpty: CalendarType.Quarterly,
		skipPersistFn: () => props.impersonationContext?.isValid,
	});
	const [selectedAccountAge, setSelectedAccountAge] = React.useState<
		ISelectOption<Api.ContentCalendarSuggestionAccountAge>
	>(() => {
		if (!props.impersonationContext) {
			const isNewAccount = moment(userSession.account.creationDate) > moment().subtract(newAccountCutoffDays, 'days');
			if (isNewAccount) {
				return CalendarAccountAgeOptions.find(
					option => option.dataContext === Api.ContentCalendarSuggestionAccountAge.New
				);
			}
		}
		return CalendarAccountAgeOptions[0];
	});
	const isImpersonating = props.impersonationContext?.account?.id;
	const isBlogsEnabled = isImpersonating
		? props.impersonationContext.account.features?.blogFeature?.enabled
		: userSession.account?.features?.blogFeature.enabled;

	const isSocialMediaEnabled = isImpersonating
		? props.impersonationContext.account.features?.socialMedia?.enabled
		: userSession.account?.features?.socialMedia?.enabled;

	const isComplianceEnabled = isImpersonating
		? props.impersonationContext.account.preferences?.complianceSettings?.enabled
		: userSession.account?.preferences?.complianceSettings?.enabled;

	const enabledTypes = React.useMemo<CampaignType[]>(() => {
		const types = [CampaignType.Email];
		if (isSocialMediaEnabled) {
			types.push(CampaignType.Social);
		}
		if (isBlogsEnabled) {
			types.push(CampaignType.Blog);
		}
		return types;
	}, [isBlogsEnabled, isSocialMediaEnabled]);
	const [campaignTypes, setCampaignTypes] = usePersistChangeInBrowserStorage<CampaignType[]>({
		storageKey: `${storagePrefixExcludedFromClear}-content-calendar-campaign-type`,
		storageType: 'localStorage',
		defaultIfEmpty: enabledTypes,
		skipPersistFn: () => props.impersonationContext?.isValid,
	});
	const [templateBrowserCampaignType, setTemplateBrowserCampaignType] = usePersistChangeInBrowserStorage<CampaignType>({
		storageKey: `${storagePrefixExcludedFromClear}-content-calendar-template-campaign-type`,
		storageType: 'localStorage',
		defaultIfEmpty: CampaignType.Email,
		skipPersistFn: () => props.impersonationContext?.isValid,
		setInitialValue: storedValue => {
			if (!campaignTypes.includes(storedValue)) {
				return campaignTypes[0];
			}
			return storedValue;
		},
	});

	const [placeholders, setPlaceholders] = React.useState<ICampaignCalendarEventPlaceholder<ITemplateCard>[]>([]);
	const [showSuggestionsToggleValue, setShowSuggestionsToggleValue] = usePersistChangeInBrowserStorage<boolean>({
		storageType: 'localStorage',
		storageKey: `${storagePrefixExcludedFromClear}-content-calendar-suggestion-toggle`,
		skipPersistFn: () => props.impersonationContext?.isValid,
		defaultIfEmpty: false,
	});
	const [showToggleSuggestionsOffConfirmation, setShowToggleSuggestionsOffConfirmation] = React.useState(false);
	const campaignCalendarRef = React.useRef<ICampaignCalendarComponent>(null);
	const contentCalendarTemplateBrowserRef = React.useRef<IContentCalendarTemplateBrowserComponent>();
	const lastCampaignIdForApprovalRef = React.useRef<string>(null);
	const canShowSuggestions =
		Api.isAdmin(userSession.user) ||
		(props.impersonationContext && Api.isImpersonatingAccountAdmin(props.impersonationContext));
	React.useImperativeHandle(
		ref,
		() => ({
			loadAllCalendarData,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[month]
	);

	const emailContentCalPendingApprovalQuery = useEmailContentCalPendingApprovalQuery({
		enabled: !isComplianceEnabled && !props.impersonationContext,
	});
	const socialContentCalPendingApprovalQuery = useSocialContentCalPendingApprovalQuery({
		enabled: !isComplianceEnabled && isSocialMediaEnabled && !props.impersonationContext,
	});
	const blogContentCalPendingApprovalQuery = useBlogContentCalPendingApprovalQuery({
		enabled: !isComplianceEnabled && isBlogsEnabled && !props.impersonationContext,
	});

	const emailsPendingApproval = emailContentCalPendingApprovalQuery.data ?? 0;
	const socialsPendingApproval = socialContentCalPendingApprovalQuery.data ?? 0;
	const blogsPendingApproval = blogContentCalPendingApprovalQuery.data ?? 0;
	const totalPendingApprovals = emailsPendingApproval + socialsPendingApproval + blogsPendingApproval;

	const emailContentCalApproveMutation = useEmailContentCalApproveMutation();
	const socialContentCalApproveMutation = useSocialContentCalApproveMutation();
	const blogContentCalApproveMutation = useBlogContentCalApproveMutation();
	const suggestionQueriesParams = Array.from({ length: numMonthsInQuarterly }, (_, i) => i).map(i => {
		const currentMonth = new Date(month);
		const suggestionMonth = new Date(currentMonth.setMonth(currentMonth.getMonth() + i));
		const account = props.impersonationContext?.account ?? userSession.account;
		const param: Parameters<typeof useContentCalendarSuggestionFilterQuery>[0] = {
			enabled: Boolean(showSuggestionsToggleValue),
			month: suggestionMonth.toISOString(),
			impersonationContext: props.impersonationContext?.toJs(),
			request: {
				accountAges:
					selectedAccountAge.dataContext === Api.ContentCalendarSuggestionAccountAge.New
						? [Api.ContentCalendarSuggestionAccountAge.New]
						: [Api.ContentCalendarSuggestionAccountAge.New, Api.ContentCalendarSuggestionAccountAge.Existing],
				industries: [account.additionalInfo?.industry],
				subverticals: account.preferences?.subverticalSettings?.subverticals ?? [Api.ContentCalendarSuggestionAll],
			},
		};
		return param;
	});
	const suggestionQueries = useContentCalendarSuggestionFilterQueries(suggestionQueriesParams);
	const suggestionsForCampaignTypes = suggestionQueries
		.filter(x => x.isSuccess)
		.flatMap(x => x.data)
		.filter(x => isSuggestionInCampaignTypes(x, campaignTypes));

	const selectSingleCampaignType = (campaignType: CampaignType) => {
		setTemplateBrowserCampaignType(campaignType);
		setCampaignTypes([campaignType]);
	};
	const updateCampaignTypes = (newCampaignTypes: CampaignType[]) => {
		setCampaignTypes(newCampaignTypes);
		if (!newCampaignTypes.includes(templateBrowserCampaignType)) {
			setTemplateBrowserCampaignType(newCampaignTypes[0]);
		}
	};

	const suggestionsContext = useCreateCampaignCalendarSuggestionsContext({
		supportsBulkScheduleSuggestions: showSuggestionsToggleValue,
		onScheduleSuggestions: selectedSuggestions => {
			const onFinish = () => {
				suggestionsContext.clearSelectedSuggestions();
				loadAllCalendarData();
			};
			if (!props.onOpenMultiSuggestionsScheduler?.(campaignTypes[0], selectedSuggestions, onFinish)) {
				const _suggestions = [...selectedSuggestions];
				_suggestions.sort(
					(a, b) => new Date(a.schedule.startDate).getTime() - new Date(b.schedule.startDate).getTime()
				);
				const locationState: ILocationState<any, IMultiCampaignSchedulerRequest> = {
					model: {
						suggestions: _suggestions,
						onFinish,
					},
				};

				let pathname = '/multi-campaign-scheduler';
				switch (campaignTypes[0]) {
					case CampaignType.Email: {
						pathname = `${pathname}/email`;
						break;
					}
					case CampaignType.Social: {
						pathname = `${pathname}/social`;
						break;
					}
					case CampaignType.Blog: {
						pathname = `${pathname}/blogs`;
						break;
					}
					default: {
						break;
					}
				}

				fullscreenModal.history.push({
					pathname,
					state: locationState,
				});
			}
		},
	});
	const selectSuggestionRef = React.useRef(suggestionsContext.selectSuggestion);
	selectSuggestionRef.current = suggestionsContext.selectSuggestion;

	React.useEffect(() => {
		if (!showSuggestionsToggleValue) {
			suggestionsContext.clearSelectedSuggestions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showSuggestionsToggleValue]);

	const loadAllCalendarData = React.useCallback(async () => {
		invalidateAllContentCalendarSuggestions();
		if (!props.impersonationContext) {
			invalidateEmailContentCalPendingApprovalQuery();
			invalidateSocialContentCalPendingApprovalQuery();
		}
		if (campaignCalendarRef.current) {
			await campaignCalendarRef.current.loadCampaigns();
		}
	}, [props.impersonationContext]);

	const launchReviewAndApproveSocial = async (campaign: Api.SocialMediaPostReportViewModel) => {
		lastCampaignIdForApprovalRef.current = campaign.id;
		try {
			const post = await campaign?.load();
			const locationState: ILocationState<Api.SocialMediaPostReportViewModel, any> = {
				viewModel: new Api.SocialMediaPostReportViewModel(userSession, post),
			};
			fullscreenModal?.history?.push({
				pathname: '/social-media/post/approval-preview',
				state: locationState,
			});
		} catch (error) {
			logApiError('LaunchReviewAndApproveSocial-Error', error);
			errorMessages.pushApiError(error);
		}
	};
	const loadBlogPostAsync = async (blogId: string) => {
		return getBlogPostAsync({ id: blogId, impersonationContext: props.impersonationContext, userSession });
	};
	const launchEditBlogPost = (post: Api.IBlogPost) => {
		const locationState: ILocationState<any, BlogPostRequest> = {
			model: {
				post,
			},
		};
		fullscreenModal?.history?.push({
			pathname: `/blog/post/edit/${post.id}`,
			state: locationState,
		});
	};
	React.useEffect(() => {
		if (props.impersonationContext?.account && props.impersonationContext?.user) {
			loadAllCalendarData();
		}
	}, [loadAllCalendarData, props.impersonationContext?.account, props.impersonationContext?.user]);

	React.useEffect(() => {
		const initialize = async () => {
			const campaignApprovalCommand = location.state;
			if (campaignApprovalCommand) {
				switch (campaignApprovalCommand.command) {
					case 'social-approval': {
						const vm = new Api.SocialMediaPostReportViewModel(userSession, {
							id: campaignApprovalCommand.id,
						}).impersonate(props.impersonationContext);
						launchReviewAndApproveSocial(vm);
						break;
					}
					case 'blog-approval': {
						try {
							const blogPost = await loadBlogPostAsync(campaignApprovalCommand.id);
							launchEditBlogPost(blogPost);
						} catch (error) {
							logApiError('BlogPostLoad-Error', error);
						}
						break;
					}
					default: {
						break;
					}
				}
			}
		};
		initialize();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		// sets suggestions to show email only when the toggle is turned on and more than one campaign type is selected
		if (showSuggestionsToggleValue && campaignTypes.length > 1) {
			setCampaignTypes([CampaignType.Email]);
		}
	}, [campaignTypes.length, setCampaignTypes, showSuggestionsToggleValue]);

	const loadTemplate = (templateId: string) => {
		try {
			return new Api.TemplatesViewModel(userSession)
				.impersonate(props.impersonationContext)
				.getByIdExpandedByLastUsedBy(templateId);
		} catch (error) {
			errorMessages.pushApiError(error);
			logApiError('LoadTemplate-Error', error);
		}
	};
	const openCampaignComposer = (
		template: Api.ITemplate,
		schedule?: Api.IScheduledSend,
		referrer = 'contentCalendar'
	) => {
		if (!props.onOpenCampaignComposer?.(template, schedule, referrer)) {
			if (template?.templateType === Api.TemplateType.Email || !template?.templateType) {
				const locationStateEmail: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
					model: {
						context: template,
						onFinish: (didSend: boolean) => {
							if (didSend) {
								loadAllCalendarData();
							}
						},
						schedule,
						type: 'Template',
					},
				};
				fullscreenModal?.history.push({
					pathname: '/email/campaigns/create/from-template',
					state: locationStateEmail,
				});
			} else if (template.templateType === Api.TemplateType.Blog) {
				const locationState: ILocationState<any, BlogPostRequest> = {
					model: {
						schedule,
						template,
					},
				};
				fullscreenModal?.history.push({
					pathname: `/blog/post/create-from-template/${template.id}`,
					state: locationState,
				});
			} else if (template.templateType === Api.TemplateType.SocialMediaPost) {
				const locationStateSocial: ILocationState<any, ICreateSocialMediaPostRequest<Api.ITemplate>> = {
					model: {
						context: template,
						onFinish: (didSend: boolean) => {
							if (didSend) {
								loadAllCalendarData();
							}
						},
						schedule,
						sendFromUser: userSession.user,
						type: 'Template',
					},
				};
				fullscreenModal?.history.push({
					pathname: `/social-media/post/create/from-template`,
					state: locationStateSocial,
				});
			}
		}
	};

	const createSuggestionFromDropResult = async (result: DropResult) => {
		if (result.destination?.droppableId && result.destination?.droppableId !== result.source?.droppableId) {
			const templateId = result.draggableId;
			const templateCard: ITemplateCard =
				contentCalendarTemplateBrowserRef.current?.getTemplateCards?.()?.[result.source.index];
			if (templateCard) {
				try {
					const template = await loadTemplate(templateId);
					let startDateAsString = result.destination.droppableId;
					/**
					 * @NOTE If the start date is before today and the calendar is quarterly, set the start date to today.
					 * This is to prevent users from scheduling suggestions in the past.
					 */
					const startDateMoment = moment(startDateAsString);
					if (startDateMoment.isBefore(moment().startOf('day')) && calendarType === CalendarType.Quarterly) {
						startDateAsString = moment().toISOString();
					}

					const suggestion: IContentCalendarSelectedSuggestion = {
						dragDropId: uuidgen(), // need this because we dont have a suggestion id
						schedule: {
							startDate: startDateAsString,
							expirationDate: templateCard?.schedule?.expirationDate,
						},
						templateReference: {
							isCustomized: false,
							isSystemTemplate: [Api.TemplateScope.System, Api.TemplateScope.Industry].some(x => x === template.scope),
							name: template.name,
							templateId,
							templateType: template.templateType,
						},
					};

					const showDuplicateWarning = shouldShowDuplicateTemplateWarning(template);
					if (showDuplicateWarning) {
						setDuplicateTemplateDialogProps(modalPropsBeforeOpen => ({
							...modalPropsBeforeOpen,
							isOpen: true,
							onRequestClose: (suppress?: boolean, cancel = false) => {
								setDuplicateTemplateDialogProps(value => ({ ...value, isOpen: false }));
								if (suppress) {
									suppressDuplicateTemplateWarning(template.id);
								}
								if (!cancel && selectSuggestionRef.current) {
									selectSuggestionRef.current(suggestion);
									toaster.push({
										message: 'Suggestion added to selection',
										type: 'successMessage',
									});
								}
							},
							type: campaignTypes[0], // should only be 1 selected
							duplicateType: getDuplicateTemplateType(template),
						}));
						return;
					}
					suggestionsContext.selectSuggestion(suggestion);
				} catch (error) {
					errorMessages.pushApiError(error);
					logApiError('CreateSuggestionFromDropResult-Error', error);
				}
			}
		}
	};

	const onDragEnd = async (result: DropResult) => {
		if (showSuggestionsToggleValue) {
			return createSuggestionFromDropResult(result);
		}

		if (result.destination?.droppableId && result.destination?.droppableId !== result.source?.droppableId) {
			const templateId = result.draggableId;
			const templateCard: ITemplateCard =
				contentCalendarTemplateBrowserRef.current?.getTemplateCards?.()?.[result.source.index];
			if (templateCard) {
				setPlaceholders([
					{
						campaignType: templateBrowserCampaignType,
						dataContext: templateCard,
						moment: moment(result.destination.droppableId),
						status:
							templateBrowserCampaignType === CampaignType.Email
								? Api.EmailSendStatus.Queued
								: templateBrowserCampaignType === CampaignType.Social
									? Api.PostStatus.Scheduled
									: Api.BlogStatus.Queued,
						subject: templateCard.name,
					},
				]);
			}
			try {
				const template = await loadTemplate(templateId);
				openCampaignComposer(template, {
					startDate: result.destination?.droppableId,
				});
			} catch (error) {
				setPlaceholders([]);
			}
		}
	};
	const onApprovePendingApprovals = async () => {
		logEvent('ApprovePendingApprovals');
		try {
			await emailContentCalApproveMutation.mutateAsync({
				impersonationContext: props.impersonationContext,
			});

			if (isSocialMediaEnabled) {
				await socialContentCalApproveMutation.mutateAsync({
					impersonationContext: props.impersonationContext,
				});
			}

			if (isBlogsEnabled) {
				await blogContentCalApproveMutation.mutateAsync({
					impersonationContext: props.impersonationContext,
				});
			}
			loadAllCalendarData();
		} catch (error) {
			logApiError('ApprovePendingApprovals-Error', error);
		}
	};
	const confirmToggle = () => {
		setShowSuggestionsToggleValue(!showSuggestionsToggleValue);
		suggestionsContext.clearSelectedSuggestions();
		setShowToggleSuggestionsOffConfirmation(false);
	};
	const toggle = () => {
		if (suggestionsContext.selectedSuggestions.length > 0) {
			setShowToggleSuggestionsOffConfirmation(true);
		} else {
			confirmToggle();
		}
	};
	const onCancelConfirmClose = (_result?: IConfirmationDialogOption, canceled?: boolean) => {
		if (!canceled) {
			confirmToggle();
		}
		setShowToggleSuggestionsOffConfirmation(false);
	};
	const launchReviewAndApprove = (campaign: Api.CampaignViewModel) => {
		lastCampaignIdForApprovalRef.current = campaign.id;
		const locationState: ILocationState<CampaignsApprovalViewModel, any> =
			campaign instanceof Api.CampaignViewModel
				? {
						viewModel: new CampaignsApprovalViewModel(userSession, [campaign]),
					}
				: null;
		fullscreenModal?.history?.push({
			pathname: '/email/campaigns/edit',
			state: locationState,
		});
	};
	const onCampaignClicked = async (e: React.MouseEvent<HTMLElement>, campaign: Api.CampaignViewModel) => {
		props.onCampaignClicked?.(e, campaign);
		if (!e.defaultPrevented && campaign.status === Api.EmailSendStatus.Queued) {
			e.preventDefault();
			const locationState: ILocationState<Api.CampaignViewModel, any> = {
				viewModel: campaign,
			};
			fullscreenModal?.history?.push({
				pathname: '/email/campaigns/edit',
				state: locationState,
			});
		}
	};
	const onBlogPostClicked = async (e: React.MouseEvent<HTMLElement>, blog: Api.IBlogReportView) => {
		props.onBlogPostClicked?.(e, blog);
		if (!e.defaultPrevented && blog.id) {
			e.preventDefault();
			try {
				const result = await loadBlogPostAsync(blog.id);
				launchEditBlogPost(result);
			} catch (error) {
				errorMessages.pushApiError(error);
			}
		}
	};
	const onMonthsChange = (value: moment.Moment) => {
		const newMonth = value.toDate();
		setMonth(newMonth);
	};
	const onCalendarRef = (calRef: ICampaignCalendarComponent) => {
		campaignCalendarRef.current = calRef;
		props.onCampaignCalendarRef?.(calRef);
	};
	const onSocialMediaEventClicked = async (
		e: React.MouseEvent<HTMLElement, MouseEvent>,
		postReport: Api.SocialMediaPostReportViewModel
	) => {
		props.onSocialMediaPostClicked?.(e, postReport);
		if (e.defaultPrevented) {
			return;
		}
		e.preventDefault();
		e?.stopPropagation();
		const locationState: ILocationState<Api.SocialMediaPostReportViewModel, any> = { viewModel: postReport };

		if (postReport?.status === Api.PostStatus.Pending) {
			fullscreenModal?.history.push({
				pathname: '/social-media/post/approval-preview',
				state: locationState,
			});
		} else {
			fullscreenModal?.history.push({
				pathname: `/social-media/post/edit-from-post-report`,
				state: locationState,
			});
		}
	};

	const onSuggestedEventClicked = async (
		e: React.MouseEvent<HTMLElement, MouseEvent>,
		suggestion: Api.IContentCalendarSuggestion
	) => {
		props.onSuggestionClicked?.(e, suggestion);
		if (e.defaultPrevented) {
			return;
		}
		e.preventDefault();
		if (suggestionsContext.supportsBulkScheduleSuggestions && suggestionsContext.hasSelectedSuggestion(suggestion)) {
			toaster.push({
				message: 'To schedule, please schedule all suggestions at once using the "Schedule suggested content" button.',
				type: 'errorMessage',
			});
			return;
		}

		if (suggestion.templateReference.templateType === Api.TemplateType.Blog) {
			try {
				const result = await getBlogPostTemplateAsync({
					id: suggestion.templateReference.templateId,
					userSession,
					impersonationContext: props.impersonationContext?.toJs(),
				});
				if (!result.contentCalendarSuggestionId) {
					result.contentCalendarSuggestionId = suggestion.id;
				}
				const locationState: ILocationState<any, BlogPostRequest> = {
					model: {
						impersonationContext: props.impersonationContext?.toJs(),
						template: result,
						schedule: suggestion.schedule,
					},
				};
				fullscreenModal?.history?.push({
					pathname: `/blog/post/create-from-template/${result.id}`,
					state: locationState,
				});
			} catch (error) {
				logApiError('LoadSuggestion-Error', error);
				errorMessages.pushApiError(error);
			}
			return;
		}

		const viewModel = new Api.ContentCalenderSuggestionViewModel(userSession, suggestion).impersonate(
			props.impersonationContext
		);
		try {
			await viewModel.load();
			const locationState: ILocationState<Api.ContentCalenderSuggestionViewModel, any> = { viewModel };
			fullscreenModal?.history?.push({
				pathname: '/email/campaigns/create/from-suggested-campaign',
				state: locationState,
			});
		} catch (error) {
			errorMessages.pushApiError(error);
			logApiError('LoadSuggestion-Error', error);
		}
	};

	const onSuggestedSocialMediaEventClicked = async (
		e: React.MouseEvent<HTMLElement>,
		suggestion: Api.IContentCalendarSuggestion
	) => {
		props.onSuggestedSocialMediaEventClicked?.(e, suggestion);
		if (e.defaultPrevented) {
			return;
		}
		e.preventDefault();

		if (suggestionsContext.supportsBulkScheduleSuggestions && suggestionsContext.hasSelectedSuggestion(suggestion)) {
			toaster.push({
				message: 'To schedule, please schedule all suggestions at once using the "Schedule suggested content" button.',
				type: 'errorMessage',
			});
			return;
		}

		try {
			const locationState: ILocationState<any, ICreateSocialMediaPostRequest<Api.ITemplate | string>> = {
				model: {
					context: suggestion?.templateReference?.templateId,
					onFinish: (didSend: boolean) => {
						if (didSend) {
							loadAllCalendarData();
						}
					},
					schedule: suggestion.schedule,
					targets: suggestion.targets,
					type: 'Template',
				},
			};
			fullscreenModal?.history.push({
				pathname: `/social-media/post/edit-template/${suggestion.templateReference.templateId}/${suggestion.id}`,
				state: locationState,
			});
		} catch (error) {
			errorMessages.pushApiError(error);
			logApiError('LoadSuggestion-Error', error);
		}
	};
	const onFullscreenModalLifecycleNotification = (notification: ILocalNotification<string>) => {
		if (notification.info === 'onAfterClose') {
			setPlaceholders([]);
		}
	};
	const onEditCampaignNotificationReceived = async (notification?: ILocalNotification<Api.ICampaign[]>) => {
		if (
			notification?.info?.some(
				x => x.id === lastCampaignIdForApprovalRef.current && x.status !== Api.EmailSendStatus.WaitingForApproval
			) ||
			notification?.info.length === 0 ||
			notification?.info[0]?.status === Api.EmailSendStatus.Cancelled
		) {
			// reload if the status has changed
			loadAllCalendarData();
			lastCampaignIdForApprovalRef.current = null;
		}
	};
	const onRefreshFromNotification = async (notification?: ILocalNotification<any>) => {
		if (notification?.info) {
			loadAllCalendarData();
		}
	};
	const onEditSocialNotificationReceived = async (notification?: ILocalNotification<Api.SocialMediaPostViewModel>) => {
		if (notification?.info) {
			// reload if the status has changed
			loadAllCalendarData();
		}
	};
	const onEditBlogPost = (notification?: ILocalNotification<Api.IBlogPost>) => {
		if (notification?.info) {
			// reload if the status has changed
			loadAllCalendarData();
		}
	};

	// order matters
	const computedCalendarContainerCardStyles = [
		styleSheet.calendarContainerCard,
		showSuggestionsToggleValue && calendarType === CalendarType.Quarterly
			? styleSheet.calendarContainerCardWithSuggestions
			: null,
		totalPendingApprovals > 0 &&
			calendarType === CalendarType.Quarterly &&
			(showSuggestionsToggleValue
				? styleSheet.calendarContainerCardWithAlertAndSuggestions
				: styleSheet.calendarContainerCardWithAlert),
	];
	let monthsToShow: number;
	switch (calendarType) {
		case CalendarType.Month:
			monthsToShow = 1;
			break;
		case CalendarType.Weekly:
			monthsToShow = 0;
			break;
		case CalendarType.Quarterly:
		default:
			monthsToShow = numMonthsInQuarterly;
			break;
	}

	return (
		<div className={`${css(styleSheet.container, ...(props.styles || []))} content-calendar ${props.className || ''}`}>
			<MultiContainerHeader appBarHeader={<h1 className={css(bs.breadcrumbTitle)}>Content Calendar</h1>} />
			<DarkMainContainerBackground />
			<FabContext appearance={{ hidden: true }} />
			<CampaignCalendarSuggestionsContext.Provider value={suggestionsContext}>
				<div className={css(styleSheet.contentContainer)}>
					<DragDropContext onDragEnd={onDragEnd}>
						<>
							<div className={css(styleSheet.calendarContainer)}>
								{totalPendingApprovals > 0 ? (
									<div className={css(styleSheet.pendingApprovalAlert)}>
										<figure>
											<ApproveAllIcon />
										</figure>
										<span className={css(bs.uppercase, styleSheet.pendingApprovalAlertText)}>
											<strong className={css(styleSheet.pendingApprovalText)}>Pending Approval</strong>
										</span>

										<div className={css(styleSheet.pendingApprovalCounts)}>
											{emailsPendingApproval > 0 ? (
												<CampaignTypeLabel
													type={CampaignType.Email}
													label={`${emailsPendingApproval} Email Campaigns`}
												/>
											) : null}
											{socialsPendingApproval > 0 ? (
												<CampaignTypeLabel
													type={CampaignType.Social}
													label={`${socialsPendingApproval} Social Media Posts`}
												/>
											) : null}
											{blogsPendingApproval > 0 ? (
												<CampaignTypeLabel type={CampaignType.Blog} label={`${blogsPendingApproval} Blog Posts`} />
											) : null}
										</div>

										<div className={css(styleSheet.pendingApprovalAlertButtonContainer)}>
											<button
												className={css(styleSheet.pendingApprovalAlertButton)}
												onClick={onApprovePendingApprovals}
											>
												<span>Approve All</span>
											</button>
										</div>
									</div>
								) : null}

								{month ? (
									<div className={css(bs.horizontalStack, styleSheet.suggestionsToggleContainer)}>
										{!showSuggestionsToggleValue ? (
											<div className={css(styleSheet.typesKey)}>
												<label className={css(styleSheet.selectLabel, styleSheet.campaignTypeLabel)}>Campaigns</label>
												<ContentCalendarLegend campaignTypes={campaignTypes} onSetCampaignTypes={updateCampaignTypes} />
											</div>
										) : (
											<ContentCalendarTabs
												campaignTypes={enabledTypes}
												activeCampaignType={campaignTypes?.[0]}
												onSetCampaignActiveCampaignType={selectSingleCampaignType}
											/>
										)}
									</div>
								) : null}
								<div className={css(...computedCalendarContainerCardStyles)}>
									<MediaQueryObserver minWidth={1300}>
										{() => {
											return (
												<CampaignCalendar
													calendarType={calendarType}
													droppableType={droppableType}
													initialStartDate={month}
													key={monthsToShow}
													numberOfMonthsToShow={monthsToShow}
													onApproveCampaignClicked={launchReviewAndApprove}
													onApproveSocialCampaignClicked={launchReviewAndApproveSocial}
													onCalendarTypeChanged={setCalendarType}
													onCampaignClicked={onCampaignClicked}
													onCampaignGroupClicked={props.onCampaignGroupClicked}
													onBlogPostClicked={onBlogPostClicked}
													onMonthsChange={onMonthsChange}
													onNotifyClientClicked={props.onNotifyClientClicked}
													ref={onCalendarRef}
													onRenderHeaderAccessory={() => (
														<>
															<div>
																{canShowSuggestions ? (
																	<div className={css(styleSheet.suggestionToggleWrap)}>
																		<Toggle
																			checkedColor={brandPrimary}
																			id='show-suggestions-toggle'
																			isOn={showSuggestionsToggleValue}
																			onToggleCheckChanged={toggle}
																			text={
																				!showSuggestionsToggleValue ? 'Turn on suggestions!' : 'Turn off suggestions'
																			}
																			textStyles={[styleSheet.suggestionsToggleText]}
																			uncheckedColor={inputBorderColor}
																		/>
																	</div>
																) : null}
															</div>

															<div className={css(styleSheet.filterDropdownContainer)}>
																{props.onRenderHeaderAccessory?.()}
																{showSuggestionsToggleValue ? (
																	<>
																		<div>
																			<Select
																				onOptionClick={setSelectedAccountAge}
																				options={CalendarAccountAgeOptions}
																				selectedOptionTitle={
																					<div className={css(styleSheet.triggerContainer)}>
																						<span className={css(styleSheet.triggerValue)}>
																							{selectedAccountAge?.text}
																						</span>
																					</div>
																				}
																				styles={[styleSheet.triggerContainerShowing]}
																			/>
																		</div>
																	</>
																) : null}

																{showSuggestionsToggleValue ? (
																	<div>
																		<button
																			className={css(bs.ctaButton, styleSheet.suggestedButton)}
																			onClick={suggestionsContext.scheduleSelectedSuggestions}
																			disabled={suggestionsContext.selectedSuggestions.length === 0}
																		>
																			Schedule suggested content
																		</button>
																	</div>
																) : null}
															</div>
														</>
													)}
													onSocialMediaEventClicked={onSocialMediaEventClicked}
													onSuggestedEventClicked={onSuggestedEventClicked}
													onSuggestedSocialMediaEventClicked={onSuggestedSocialMediaEventClicked}
													options={props.calendarOptions}
													placeholders={placeholders}
													styles={[styleSheet.calendar]}
													suggestions={showSuggestionsToggleValue ? suggestionsForCampaignTypes : null}
													selectedCampaignType={templateBrowserCampaignType}
													campaignTypes={campaignTypes}
												/>
											);
										}}
									</MediaQueryObserver>
								</div>
							</div>

							<ContentCalendarTemplateBrowser
								ref={contentCalendarTemplateBrowserRef}
								dragDropProps={{
									droppableId: 'campaignTemplatesList',
									type: droppableType,
									draggingStyles: [
										calendarType === CalendarType.Month
											? styleSheet.campaignTemplateCardDraggingOverDay
											: styleSheet.campaignTemplateCardDragging,
									],
								}}
								onTemplateTypeSelected={setTemplateBrowserCampaignType}
								onCampaignTemplateClicked={openCampaignComposer}
								selectedCampaignType={templateBrowserCampaignType}
								campaignTypes={campaignTypes}
							/>
						</>
					</DragDropContext>
				</div>
			</CampaignCalendarSuggestionsContext.Provider>
			<LocalNotificationObserver
				topic={Topics.APP_FULLSCREEN_MODAL_APPEARANCE}
				onNotificationReceived={onFullscreenModalLifecycleNotification}
			/>
			<LocalNotificationObserver
				onNotificationReceived={onEditCampaignNotificationReceived}
				topic={Topics.EDIT_CAMPAIGNS_ITEM}
			/>
			<LocalNotificationObserver
				onNotificationReceived={onRefreshFromNotification}
				topic={Topics.CREATE_CAMPAIGNS_ITEM}
			/>
			<LocalNotificationObserver onNotificationReceived={onRefreshFromNotification} topic={Topics.SEND_EMAIL} />
			<LocalNotificationObserver
				onNotificationReceived={onEditSocialNotificationReceived}
				topic={Topics.EDIT_SOCIAL_POST}
			/>
			<LocalNotificationObserver
				onNotificationReceived={onEditSocialNotificationReceived}
				topic={Topics.CREATE_SOCIAL_POST}
			/>
			<LocalNotificationObserver onNotificationReceived={onEditBlogPost} topic={Topics.EDIT_BLOG_POST} />

			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: !!showToggleSuggestionsOffConfirmation,
					onRequestClose: onCancelConfirmClose,
				}}
				options={DefaultConfirmationOptions}
				title='Turning off suggestions will unselect all suggestions'
			/>
			<DuplicateWarningDialog {...duplicateTemplateDialogProps} />
		</div>
	);
});
const ContentCalendarAsObserver = observer(_ContentCalendar);
export const ContentCalendar = inject(ImpersonationContextKey)(ContentCalendarAsObserver);
