import * as Api from '@ViewModels';
import { css, StyleDeclarationValue } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	IContentCalendarSelectedSuggestion,
	IImpersonationContextComponentProps,
	IModalContext,
	ImpersonationContextKey,
	IMultiCampaignSchedulerResult,
	ModalChildComponentContextKey,
} from '../../../../../../models';
import { CampaignType } from '../../../../../../models/AdminModels';
import { useErrorMessages, useToaster, useUserSession } from '../../../../../../models/hooks/appStateHooks';
import { useEventLogging } from '../../../../../../models/Logging';
import { accountRequiresCompliance, getUniqueIdForSuggestion } from '../../../../../../models/UiUtils';
import { useTemplateQueries } from '../../../../../../queries';
import { Button } from '../../../../../components/Button';
import { useCampaignCalendarSuggestionsContext } from '../../../../../components/campaigns/CampaignCalendar/context';
import { ComplianceApprovalPrompt } from '../../../../../components/campaigns/ComplianceApprovalPrompt';
import { baseStyleSheet } from '../../../../../styles/styles';
import { WarningBanner, WarningBannerMessage } from '../../../presentation';
import { MultiCampaignSchedulerLayout } from '../../MultiCampaignSchedulerLayout';
import { MultiBlogTemplateItem } from '../MultiBlogTemplateItem';
import { useScheduleBlogSuggestions } from './hooks';
import { styleSheet } from './styles';

interface IProps extends IImpersonationContextComponentProps, IModalContext {
	styles?: StyleDeclarationValue[];
}

type Author = {
	sendFrom: Api.ISendEmailFrom;
	sendFromUserId: string | null;
};

export const MultiBlogScheduler = inject(
	ImpersonationContextKey,
	ModalChildComponentContextKey
)(
	observer(function MultiBlogScheduler({ styles = [], impersonationContext, parentModal }: IProps) {
		const userSession = useUserSession();
		const errorMessages = useErrorMessages();
		const { logApiError } = useEventLogging('MultiBlogScheduler');
		const toaster = useToaster();
		const requiresCompliance = accountRequiresCompliance(userSession, impersonationContext);

		const {
			selectedSuggestions,
			deselectSuggestion,
			updateStartDateForSuggestion,
			getStartDateForSuggestion,
			onScheduleSuccess,
		} = useCampaignCalendarSuggestionsContext();

		const [author, setAuthor] = React.useState<Author>(() => {
			let sendFrom: Api.ISendEmailFrom = Api.SendEmailFrom.CurrentUser;
			let sendFromUserId: string = null;
			if (impersonationContext?.isValid) {
				sendFrom = Api.SendEmailFrom.SelectedUser;
				sendFromUserId = impersonationContext.user?.id || null;
			} else {
				sendFromUserId = userSession.user.id;
			}

			return {
				sendFrom,
				sendFromUserId,
			};
		});

		const scheduledPostsRef = React.useRef<Map<string, Api.IBlogPost>>(new Map());
		const suggestionPostErrorsRef = React.useRef<
			Set<[IContentCalendarSelectedSuggestion, Api.IOperationResultNoValue]>
		>(new Set());

		const templateQueries = useTemplateQueries({
			templateIds: selectedSuggestions.map(t => t.templateReference.templateId),
			refetchOnWindowFocus: false,
			queryKeyProvider: (_, index) => {
				const suggestion = selectedSuggestions[index];
				return getUniqueIdForSuggestion(suggestion);
			},
		});

		const scheduleMutation = useScheduleBlogSuggestions({
			onError: error => {
				logApiError('ScheduleBlogSuggestions-Error', error);
				errorMessages.pushApiError(error);
			},
			onSuccess: (results, params) => {
				results.forEach((result, i) => {
					const suggestion = params.suggestions[i];
					if (result.status === 'fulfilled') {
						const blogPost = result.value;
						scheduledPostsRef.current.set(getUniqueIdForSuggestion(suggestion), blogPost);
					} else {
						const error = Api.asApiError(result.reason);
						const matchingSuggestion = selectedSuggestions.find(
							s => getUniqueIdForSuggestion(suggestion) === getUniqueIdForSuggestion(s)
						);
						suggestionPostErrorsRef.current.add([matchingSuggestion, error]);
					}
				});

				if (scheduledPostsRef.current.size === selectedSuggestions.length) {
					const scheduled = Array.from(scheduledPostsRef.current?.values()) || [];
					const scheduleResult: IMultiCampaignSchedulerResult = {
						scheduledSuggestions: selectedSuggestions,
						withCompliance: params.sendWithCompliance,
					};

					toaster.push({
						message: `${scheduled.length} blog posts scheduled.`,
						type: 'successMessage',
					});

					const event = onScheduleSuccess(scheduleResult);
					if (!event.defaultPrevented) {
						parentModal.onRequestClose();
					}
				}
			},
		});

		const scheduleSuggestions = (sendWithCompliance = false, sendWithComplianceEmail?: string) => {
			suggestionPostErrorsRef.current?.clear();
			const unscheduledSuggestions = scheduledPostsRef.current
				? selectedSuggestions.filter(suggestion => {
						return !scheduledPostsRef.current.has(getUniqueIdForSuggestion(suggestion));
					})
				: selectedSuggestions;
			if (unscheduledSuggestions?.length) {
				scheduleMutation.mutate({
					impersonationContext,
					suggestions: unscheduledSuggestions.map(suggestion => {
						return {
							...suggestion,
							schedule: {
								startDate: getStartDateForSuggestion(suggestion).toISOString(),
							},
						} as IContentCalendarSelectedSuggestion;
					}),
					templates: templateQueries.map(q => q.data),
					sendWithCompliance,
					sendWithComplianceEmail,
					forUserId: author.sendFromUserId,
				});
			}
		};

		const suggestionsWithoutDatesCount = selectedSuggestions.filter(s => !getStartDateForSuggestion(s)).length;

		const canSchedule =
			suggestionsWithoutDatesCount === 0 &&
			!scheduleMutation.isLoading &&
			!templateQueries.some(q => q.isLoading) &&
			(impersonationContext?.isValid ? impersonationContext.user?.id : true);

		const renderSideBar = () => {
			if (requiresCompliance) {
				return (
					<ComplianceApprovalPrompt
						disabled={!canSchedule}
						isLoading={scheduleMutation.isLoading}
						campaignType={CampaignType.Blog}
						onScheduleCompliance={(e: React.MouseEvent<HTMLElement>, sendWithComplianceEmail: string) => {
							e.preventDefault();
							scheduleSuggestions(true, sendWithComplianceEmail);
						}}
						onScheduleWithoutCompliance={() => scheduleSuggestions()}
					/>
				);
			}

			return (
				<div>
					<Button
						disabled={!canSchedule || scheduleMutation.isLoading}
						className={css(baseStyleSheet.horizontalStack, baseStyleSheet.fullWidth)}
						onClick={() => scheduleSuggestions()}
						label='Schedule'
						isLoading={scheduleMutation.isLoading}
					/>
				</div>
			);
		};

		return (
			<div className={css(styleSheet.container, ...styles)}>
				{suggestionsWithoutDatesCount > 0 ? (
					<WarningBanner>
						<>
							<WarningBannerMessage>
								{`You have `}
								<strong>{suggestionsWithoutDatesCount}</strong>
								{` without a send date. Please select a date/time to continue scheduling these posts.`}
							</WarningBannerMessage>
						</>
					</WarningBanner>
				) : null}
				<MultiCampaignSchedulerLayout
					{...author}
					hideContactOwnerOption
					onSendFromChange={nextAuthor => {
						impersonationContext?.updateUser(nextAuthor.sendFromUserId ? { id: nextAuthor.sendFromUserId } : null);
						setAuthor(nextAuthor);
					}}
					sideBar={renderSideBar()}
					title='Schedule Blog Posts'
					userSelectLabel='Author'
					styles={[styleSheet.schedulerContainer]}
				>
					<div className={css(styleSheet.content)}>
						{templateQueries.map((templateQuery, i) => {
							const suggestion = selectedSuggestions[i];
							return (
								<MultiBlogTemplateItem
									onUpdateDate={newDate => updateStartDateForSuggestion(suggestion, newDate)}
									scheduled={scheduledPostsRef.current?.has(getUniqueIdForSuggestion(suggestion))}
									error={Array.from(suggestionPostErrorsRef.current || []).find(([s]) => s === suggestion)?.[1]}
									suggestion={suggestion}
									key={getUniqueIdForSuggestion(suggestion)}
									query={templateQuery}
									scheduleDate={getStartDateForSuggestion(suggestion)}
									onRemove={
										templateQueries.length > 1 && !scheduleMutation.isLoading
											? () => deselectSuggestion(suggestion)
											: undefined
									}
									{...author}
								/>
							);
						})}
					</div>
				</MultiCampaignSchedulerLayout>
			</div>
		);
	})
);
