import { useEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { useLocation } from 'react-router';
import { RecipientsCount } from '../../../../admin/components/email/RecipientsCount';
import { DefaultBulkContactsRequest } from '../../../../extViewmodels/Utils';
import {
	ContactSortKey,
	ICreateCampaignRequest,
	IImpersonationContextComponentProps,
	ILocationState,
	ImpersonationContextKey,
} from '../../../../models';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	ComposeEmailViewModel,
	ComposeFollowUpEmailViewModel,
	ComposeResourceEmailViewModel,
	ContactFilterCriteriaProperty,
	ContactViewModel,
	DefaultBulkSendExcludedTags,
	IContactFilterCriteria,
	IContactsFilterRequest,
	IOperationResultNoValue,
	VmUtils,
} from '../../../../viewmodels/AppViewModels';
import { Search } from '../../../containers/templates/Search';
import { success } from '../../../styles/colors';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { AdvancedFiltersFlyout2 } from '../../AdvancedFiltersFlyout2';
import { OwnershipFilterDropdown } from '../../AdvancedFiltersFlyout2/OwnershipFilters';
import { Button } from '../../Button';
import { ISelectOption, Select } from '../../Select';
import { DefaultSelectBox, ISelectBoxOption } from '../../SelectBox';
import { HomeIcon } from '../../svgs/icons/HomeIcon';
import { EmailDuplicateContactSendCondition } from '../EmailDuplicateContactSendCondition';
import { EmailRecipientsList } from '../EmailRecipientsList';
import { RenewalEmailPolicySelector } from '../PolicySelector';
import { TagEmailTagList } from '../TagEmailTagList';
import { styleSheet } from './styles';

export type BulkDeliveryMethodOptions = 'all' | 'withEmails' | 'withTexts' | 'withAddress';
const BulkDeliveryOptionsMap: Record<BulkDeliveryMethodOptions, ISelectBoxOption<BulkDeliveryMethodOptions>> = {
	all: {
		title: 'Show All',
		value: 'all',
	},
	withEmails: {
		title: 'Have Emails',
		value: 'withEmails',
	},
	withTexts: {
		title: 'Have Texts',
		value: 'withTexts',
	},
	withAddress: {
		title: 'Have an Address',
		value: 'withAddress',
	},
};
export const BulkDeliveryMethodOptions: ISelectBoxOption<BulkDeliveryMethodOptions>[] = [
	BulkDeliveryOptionsMap.all,
	BulkDeliveryOptionsMap.withEmails,
	BulkDeliveryOptionsMap.withTexts,
	BulkDeliveryOptionsMap.withAddress,
];

export const bulkDeliveryMethodFromFilterCriteria = (filterCriterias: IContactFilterCriteria[]) => {
	const isAll =
		['ReceiveEmail', 'ReceiveCard', 'ReceiveText'].every(capableOfValue => {
			for (const filterCriteria of filterCriterias) {
				if (
					VmUtils.findFilterCritiera({
						criteria: filterCriteria,
						predicate: c => c.property === ContactFilterCriteriaProperty.CapableOf && c.value === capableOfValue,
					})
				) {
					return true;
				}
			}
			return false;
		}) ||
		// handle the case where we don't have anything that specifies ContactFilterCriteriaProperty.CapableOf
		filterCriterias.every(filterCriteria =>
			Boolean(
				VmUtils.findFilterCritiera({
					criteria: filterCriteria,
					predicate: c => c.property !== ContactFilterCriteriaProperty.CapableOf,
				})
			)
		);
	if (isAll) {
		return BulkDeliveryOptionsMap.all;
	}
	if (
		filterCriterias.find(
			filter => filter.property === ContactFilterCriteriaProperty.CapableOf && filter.value === 'ReceiveEmail'
		) != null
	) {
		return BulkDeliveryOptionsMap.withEmails;
	}
	if (
		filterCriterias.find(
			filter => filter.property === ContactFilterCriteriaProperty.CapableOf && filter.value === 'ReceiveText'
		) != null
	) {
		return BulkDeliveryOptionsMap.withTexts;
	}
	if (
		filterCriterias.find(
			filter => filter.property === ContactFilterCriteriaProperty.CapableOf && filter.value === 'ReceiveCard'
		) != null
	) {
		return BulkDeliveryOptionsMap.withAddress;
	}
	return null;
};
interface IProps extends IImpersonationContextComponentProps {
	canClearFilters?: boolean;
	ctaButtonText: string;
	disableContactAdding?: boolean;
	disableContactEdits?: boolean;
	disableContactRemoval?: boolean;
	disableCta?: boolean;
	disableDropdown?: boolean;
	emailComposer: ComposeEmailViewModel | ComposeResourceEmailViewModel | ComposeFollowUpEmailViewModel;
	isAutomation: boolean;
	onContactSelected?(contact: ContactViewModel): void;
	onCtaClick: (ev: React.MouseEvent<HTMLButtonElement>) => void;
	onScrollerRef?(ref?: HTMLElement): void;
	resetOnMount?: boolean;
	showForInitialSelection?: boolean;
	styles?: StyleDeclarationValue[];
	initialOwnershipFilter?: Api.IOwnershipFilter;
}

const getSelectedTags = (criteria: Api.IContactFilterCriteria[] = []) => {
	let selected: IContactFilterCriteria[] = [];

	criteria.forEach(c => {
		if (c.criteria) {
			selected = [...selected, ...getSelectedTags(c.criteria)];
		} else if (c.property === Api.ContactFilterCriteriaProperty.Tag) {
			selected.push(c);
		}
	});

	return selected;
};

function _TagEmailRecipientsList({
	canClearFilters = true,
	ctaButtonText,
	disableContactAdding,
	disableContactEdits,
	disableContactRemoval,
	disableCta,
	disableDropdown,
	emailComposer,
	isAutomation = false,
	onContactSelected,
	onCtaClick,
	onScrollerRef,
	resetOnMount = true,
	styles,
	initialOwnershipFilter,
	impersonationContext,
}: IProps) {
	const userSession = useUserSession();
	const location = useLocation<ILocationState<any, ICreateCampaignRequest>>();
	const { logApiError, logEvent } = useEventLogging('TagEmailRecipientList');
	const resourceVm = emailComposer as ComposeResourceEmailViewModel;
	const isForResource = resourceVm?.resourceSelector;
	const alreadyExistsAsCampaign = !!emailComposer?.campaign?.id;
	const sortedCriteria = VmUtils.sortContactFilterCriteria(
		emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria
	);
	const hasUnsubscribeTagNot =
		sortedCriteria.searches.filter(x => x.value === 'Unsubscribe' && x.op === Api.FilterOperator.Not).length > 0;
	const hasEmailBouncedTagNot =
		sortedCriteria.searches.filter(x => x.value === 'Email Bounced' && x.op === Api.FilterOperator.Not).length > 0;
	const [showSearch, setShowSearch] = React.useState(false);
	const [showingAdvancedFiltersFlyout, setShowingAdvancedFiltersFlyout] = React.useState(false);
	const [selectedResourceSortOption, setSelectedResourceSortOption] = React.useState<ISelectOption<string>>();
	const selectedDeliveryMethodOption = bulkDeliveryMethodFromFilterCriteria(sortedCriteria.filters);

	const selectedTags = getSelectedTags(
		emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria
	);
	const onlyDefaultTagsAreSelected =
		selectedTags.length === 0 ||
		(selectedTags.length === DefaultBulkSendExcludedTags.size &&
			Array.from(DefaultBulkSendExcludedTags).filter(d => !!selectedTags.find(s => s.value === d)).length ===
				DefaultBulkSendExcludedTags.size);

	const getInitialOwnershipFilter = (overridenFilter: Api.IOwnershipFilter) => {
		if (overridenFilter) {
			return overridenFilter;
		}
		const firstFilter = userSession.user.ownershipFilters?.length > 0 ? userSession.user.ownershipFilters[0] : null;
		const existingOwnershipFilter =
			emailComposer.emailMessage?.contactsFilterRequest?.ownershipFilter ??
			emailComposer.emailMessage?.contactsFilterRequest?.calculatedOwnershipFilter;
		return existingOwnershipFilter ?? firstFilter;
	};

	const [selectedOwnershipFilter, setSelectedOwnershipFilter] = React.useState(
		getInitialOwnershipFilter(initialOwnershipFilter)
	);
	React.useEffect(() => {
		setSelectedOwnershipFilter(getInitialOwnershipFilter(initialOwnershipFilter));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialOwnershipFilter]);

	const duplicateContactsFeatureFlag = !!userSession.account.preferences.showDuplicateContacts;
	const canShowShowingDropdown = React.useMemo(() => {
		const canShowSuggestion = emailComposer.isSuggestion && userSession?.account?.isAdmin;
		return (
			(canShowSuggestion || (!emailComposer?.reachOutInfo && !alreadyExistsAsCampaign)) &&
			(!isForResource ||
				resourceVm.resourceSelector === Api.ResourceSelectorId.HappyBirthday ||
				resourceVm.resourceSelector === Api.ResourceSelectorId.PolicyRenew) &&
			!emailComposer.isFollowUp
		);
	}, [
		emailComposer.isSuggestion,
		emailComposer?.reachOutInfo,
		emailComposer.isFollowUp,
		userSession?.account?.isAdmin,
		alreadyExistsAsCampaign,
		isForResource,
		resourceVm.resourceSelector,
	]);
	const resourceSortOptions = React.useMemo(() => {
		let type = '';
		if (isForResource) {
			switch (resourceVm.resourceSelector) {
				case Api.ResourceSelectorId.PolicyRenew:
					type = 'renewal date';
					break;
				case Api.ResourceSelectorId.FinancialReview:
					type = 'review date';
					break;
				case Api.ResourceSelectorId.HappyBirthday:
					type = 'birthday';
					break;
				default:
					break;
			}
		}
		return [
			{
				dataContext: ContactSortKey.Handle,
				id: 'sort-option-first-name',
				text: 'Sort by first name A - Z',
				type: 'default',
			},
			{
				dataContext: ContactSortKey.HandleReverse,
				id: 'sort-option-last-name',
				text: 'Sort by last name A - Z',
				type: 'default',
			},
			{
				dataContext: ContactSortKey.KeyDate,
				id: 'sort-option-type',
				text: `Sort by ${type}`,
				type: 'default',
			},
		] as ISelectOption<ContactSortKey>[];
	}, [isForResource, resourceVm]);

	const getContacts = React.useCallback(
		(reset = false, loadApproximation = false) =>
			() => {
				const resourceSelector = (emailComposer as ComposeResourceEmailViewModel)?.resourceSelector ?? null;

				let makeRequest = true;

				if (!resourceSelector) {
					if (emailComposer.emailMessage.contactsToAdd.length > 0 && onlyDefaultTagsAreSelected) {
						// if there are individual contacts selected and no tags have been selected
						// do not make the getContacts request.
						makeRequest = false;
					} else if (!emailComposer?.emailMessage?.contactsFilterRequest && !emailComposer?.isFollowUp) {
						makeRequest = false;
					}
				}
				if (loadApproximation) {
					const approxPromise = isForResource
						? resourceVm.getEmailApproximationWithResource()
						: // If existing campaign and  only default tags, pass default filter else use normal approx.
							alreadyExistsAsCampaign &&
								onlyDefaultTagsAreSelected &&
								emailComposer?.emailMessage?.contactsFilterRequest
							? emailComposer.emailMessage.getEmailApproximation(DefaultBulkContactsRequest)
							: emailComposer.emailMessage.getEmailApproximation();
					approxPromise?.catch((error: IOperationResultNoValue) => {
						logApiError('HasEmailApproximationLoad-Error', error);
					});
				}
				// if there are individual contacts selected do not make the getContacts request if there are no tags selected.
				if (makeRequest) {
					if (reset) {
						emailComposer?.resetRecipientsResultsList();
					}
					const promise = emailComposer?.getNextBatchOfRecipients();
					if (promise) {
						logEvent('ContactsLoad');
						promise.catch((error: IOperationResultNoValue) => {
							logApiError('ContactsLoad-Error', error);
						});
					}
					return promise;
				}
			},
		[
			alreadyExistsAsCampaign,
			emailComposer,
			isForResource,
			logApiError,
			logEvent,
			onlyDefaultTagsAreSelected,
			resourceVm,
		]
	);
	const handleDuplicateContactsToggleChange = () => {
		emailComposer.emailMessage.contactsFilterRequest.groupByDuplicate =
			!emailComposer.emailMessage.contactsFilterRequest.groupByDuplicate;

		/** Below's taken from the getContacts logic because calling getContacts(true, true) here often ends up doing nothing
		 * because you can't control makeRequest from the outside.
		 **/
		const approxPromise = isForResource
			? resourceVm.getEmailApproximationWithResource()
			: // If existing campaign and  only default tags, pass default filter else use normal approx.
				alreadyExistsAsCampaign && onlyDefaultTagsAreSelected && emailComposer?.emailMessage?.contactsFilterRequest
				? emailComposer.emailMessage.getEmailApproximation(DefaultBulkContactsRequest)
				: emailComposer.emailMessage.getEmailApproximation();
		approxPromise?.catch((error: IOperationResultNoValue) => {
			logApiError('HasEmailApproximationLoad-Error', error);
		});
		emailComposer?.resetRecipientsResultsList();
		const promise = emailComposer?.getNextBatchOfRecipients();
		if (promise) {
			logEvent('ContactsLoad');
			promise.catch((error: IOperationResultNoValue) => {
				logApiError('ContactsLoad-Error', error);
			});
		}
		return promise;
	};

	const onFilterOptionChanged = (selectedOption: Api.IOwnershipFilter) => {
		// do not want to change filter if the campaign has already been created.
		if (emailComposer?.campaign?.id) {
			return;
		}
		emailComposer.userSelectedFilterOption = selectedOption; // preserve the user's selection in case of customizing per contact
		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest.ownershipFilter = selectedOption;
		}
		setSelectedOwnershipFilter(selectedOption);
		getContacts(true, true)();
	};
	const onDeliveryMethodOptionChanged = (selectedOption: ISelectBoxOption<BulkDeliveryMethodOptions>) => {
		const sorted = VmUtils.sortContactFilterCriteria(
			emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria
		);
		const filterCriteriaToBeAdded: Record<string, IContactFilterCriteria> = {
			all: {
				criteria: [
					{
						property: ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveEmail',
					},
					{
						property: ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveCard',
					},
					{
						property: ContactFilterCriteriaProperty.CapableOf,
						value: 'ReceiveText',
					},
				],
				op: Api.FilterOperator.Or,
			},
			withEmails: {
				property: ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveEmail',
			},
			withTexts: {
				property: ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveText',
			},
			withAddress: {
				property: ContactFilterCriteriaProperty.CapableOf,
				value: 'ReceiveCard',
			},
		};
		const filterRequest: IContactsFilterRequest = {
			criteria: VmUtils.concatContactFilterCriteria(
				[
					...(sorted.filters || []).filter(
						x => !VmUtils.areAllCriteriaOfProperty({ criteria: x, property: ContactFilterCriteriaProperty.CapableOf })
					),
					...sorted.searches,
				],
				selectedOption.value ? [filterCriteriaToBeAdded[selectedOption.value]] : []
			),
		};
		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = filterRequest;
		}
		getContacts(true, true)();
	};

	const onAdvancedFiltersFlyoutSave = (
		filterCriteria: IContactFilterCriteria[],
		householdGrouping?: boolean,
		ownershipFilter?: Api.IOwnershipFilter
	) => {
		const nextFilterRequest: IContactsFilterRequest = {
			criteria: filterCriteria,
		};
		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = nextFilterRequest;
			emailComposer.emailMessage.contactsFilterRequest.ownershipFilter = ownershipFilter;
		}
		if (householdGrouping !== null) {
			emailComposer.emailMessage.groupByHousehold = householdGrouping;
		}
		getContacts(true, true)();
		setShowingAdvancedFiltersFlyout(false);
		setSelectedOwnershipFilter(ownershipFilter);
	};
	const onAdvancedFiltersFlyoutCancel = () => {
		setShowingAdvancedFiltersFlyout(false);
	};
	const onSearchesChanged = (searches: IContactFilterCriteria[]) => {
		const sorted = VmUtils.sortContactFilterCriteria(
			(emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest || {}).criteria
		);
		const nextFilterRequest: IContactsFilterRequest = {
			...(emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest || {}),
			criteria: sorted.filters.concat(searches),
		};

		emailComposer?.resetRecipientsResultsList();
		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = nextFilterRequest;
		}
		getContacts(true, true)();
	};
	const onResourceSortOptionClick = (_selectedResourceSortOption: ISelectOption<ContactSortKey>) => {
		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest = {
				...emailComposer.emailMessage.contactsFilterRequest,
				sortAscending: true,
				sortProperty: _selectedResourceSortOption.dataContext as any,
			};
		}
		getContacts(true, true)();
		setSelectedResourceSortOption(_selectedResourceSortOption);
	};

	React.useEffect(() => {
		if (disableDropdown && emailComposer?.emailMessage?.options?.sendEmailFrom === Api.SendEmailFrom.ContactOwner) {
			onFilterOptionChanged(
				userSession.user.ownershipFilters?.find(
					option => option.criteria?.property === ContactFilterCriteriaProperty.All
				)
			);
		}

		const isSwitchingFromContactsTheyOwn =
			emailComposer?.emailMessage?.options?.sendEmailFrom !== Api.SendEmailFrom.SelectedUser &&
			selectedOwnershipFilter?.criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy &&
			selectedOwnershipFilter?.criteria?.value !== userSession.user.id;
		const isSwitchingSelectedUser =
			emailComposer?.emailMessage?.options?.sendEmailFrom === Api.SendEmailFrom.SelectedUser;

		if (isSwitchingFromContactsTheyOwn || isSwitchingSelectedUser) {
			onFilterOptionChanged(
				userSession.user.ownershipFilters?.find(
					option => option.criteria?.property === ContactFilterCriteriaProperty.All
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		disableDropdown,
		emailComposer?.emailMessage?.options?.sendEmailFrom,
		emailComposer?.emailMessage?.options?.sendEmailFromUserId,
		userSession,
	]);

	// effect for what used to be setDefaultSelectedOption() on mount
	React.useEffect(() => {
		if (!emailComposer?.allowSenderSelection) {
			return;
		}
		const ownedByFilter =
			emailComposer.emailMessage.contactsFilterRequest?.ownershipFilter ??
			emailComposer.emailMessage.contactsFilterRequest?.calculatedOwnershipFilter;

		let defaultSelectedOption: Api.IOwnershipFilter;
		// If OwnedBy has been explicitly set, do not augment the filter at all.
		// There are a few future scenarios where this early return could be a problem:
		// - We ever have a scenario that we'd allow providing or setting emailComposer.userSelectedFilterOption
		// - If we show the Showing filter/dropdown, we wouldn't have a filter option for the specified user.
		if (ownedByFilter !== null) {
			return;
		}
		// If the user has already changed the filter, use that value over the defaults
		if (emailComposer?.userSelectedFilterOption) {
			defaultSelectedOption = emailComposer?.userSelectedFilterOption;
		} else if (emailComposer?.emailMessage?.isSendFromOption(Api.SendEmailFrom.SelectedUser)) {
			defaultSelectedOption = userSession.user.ownershipFilters?.find(
				x =>
					x.criteria?.property === ContactFilterCriteriaProperty.OwnedBy &&
					x.criteria?.value === emailComposer?.emailMessage?.options?.sendEmailFromUserId
			);
		} else {
			defaultSelectedOption = userSession.user.ownershipFilters?.find(
				x => x.criteria?.property === ContactFilterCriteriaProperty.All
			);
		}
		const sorted = VmUtils.sortContactFilterCriteria(
			emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria
		);
		const filterRequest: IContactsFilterRequest = {
			criteria: VmUtils.concatContactFilterCriteria([
				...(sorted.filters || []).filter(x => x.property !== ContactFilterCriteriaProperty.OwnedBy),
				...sorted.searches,
			]),
		};

		if (isForResource) {
			filterRequest.criteria = VmUtils.concatContactFilterCriteria(filterRequest.criteria, [
				{
					criteria: [
						{
							property: ContactFilterCriteriaProperty.CapableOf,
							value: 'ReceiveEmail',
						},
						{
							property: ContactFilterCriteriaProperty.CapableOf,
							value: 'ReceiveCard',
						},
						{
							property: ContactFilterCriteriaProperty.CapableOf,
							value: 'ReceiveText',
						},
					],
					op: Api.FilterOperator.Or,
				},
			]);
		}

		if (emailComposer?.emailMessage?.contactsFilterRequest) {
			emailComposer.emailMessage.contactsFilterRequest.contactFilterRequest = filterRequest;
			emailComposer.emailMessage.contactsFilterRequest.ownershipFilter = defaultSelectedOption;
		}
		getContacts(resetOnMount, true)();
	}, [
		emailComposer,
		getContacts,
		isForResource,
		resetOnMount,
		userSession,
		userSession.account.preferences?.showAllContactsByDefault,
	]);

	React.useEffect(() => {
		if (
			!showSearch &&
			!!emailComposer &&
			!isForResource &&
			!emailComposer.isFollowUp &&
			!emailComposer.emailMessage?.hasUserSelectedContactFilterSearchCriteria &&
			!emailComposer.campaign?.id &&
			emailComposer.emailMessage.contactsToAdd?.length === 0
		) {
			logEvent('ShowGlobalSearchPanel');
			// reset excluded
			emailComposer.emailMessage.contactsToOmit.clear();
			setShowSearch(true);
		}
	}, [
		showSearch,
		emailComposer,
		isForResource,
		emailComposer.isFollowUp,
		emailComposer.emailMessage?.hasUserSelectedContactFilterSearchCriteria,
		emailComposer.campaign?.id,
		emailComposer.emailMessage.contactsToAdd,
		logEvent,
	]);
	function renderContactCount() {
		const onlyIndividualContactsSelected =
			emailComposer.emailMessage.contactsToAdd.length > 0 &&
			onlyDefaultTagsAreSelected &&
			location.state?.model?.type === 'Contacts';
		const approximation = isForResource
			? resourceVm?.approximation
			: emailComposer.emailMessage?.contactEmailApproximation;

		const sortOption = selectedResourceSortOption || resourceSortOptions[0];
		const followUpCount =
			emailComposer.emailMessage?.options?.followUp?.excludeContactIds?.length > 0 &&
			emailComposer.followUpCount > 0 &&
			emailComposer.campaign?.status !== Api.EmailSendStatus.Queued
				? emailComposer.followUpCount - emailComposer.emailMessage?.options?.followUp?.excludeContactIds?.length
				: emailComposer.followUpCount ?? 0;
		return (
			<div className={css(styleSheet.headerCount, styleSheet.headerCountPolicies)}>
				<RecipientsCount
					className={css(
						styleSheet.headerCountNumbers,
						(approximation?.total || 0) <= 0 && !emailComposer.followUpCount && styleSheet.headerCountNumbersImportant
					)}
					approximation={onlyIndividualContactsSelected || emailComposer?.isFollowUp ? null : approximation}
					followUpCount={emailComposer.emailMessage?.options?.followUp ? followUpCount : undefined}
					hasUnsubscribeTagNot={hasUnsubscribeTagNot}
					hasEmailBouncedTagNot={hasEmailBouncedTagNot}
					loading={isForResource ? resourceVm.isApproximating : emailComposer.emailMessage.isApproximating}
					fallbackCount={
						onlyIndividualContactsSelected
							? emailComposer.emailMessage.contactsToAdd.length
							: emailComposer.estimatedRecipientsTotal
					}
					styles={[isForResource && styleSheet.recipientCountWithSort]}
				/>
				{isForResource && (
					<div>
						<Select
							onOptionClick={onResourceSortOptionClick}
							options={resourceSortOptions}
							selectedOption={sortOption}
							selectedOptionTitle={<span className={css(styleSheet.sortText)}>{sortOption.text}</span>}
							styles={[styleSheet.sort]}
							triggerStyles={[styleSheet.sortTrigger]}
						/>
					</div>
				)}
			</div>
		);
	}
	const renderRecipientsTagHeader = () => {
		const isHouseholdOptionEnabled =
			userSession.account.features?.households?.enabled &&
			!(isForResource && resourceVm.householdOffForResourceSelector) &&
			!(emailComposer.emailMessage.contactsToAdd.length > 0 && onlyDefaultTagsAreSelected) &&
			!isAutomation;

		const type = isForResource
			? resourceVm.resourceSelector === Api.ResourceSelectorId.PolicyRenew
				? 'Renewals'
				: resourceVm.resourceSelector === Api.ResourceSelectorId.FinancialReview
					? 'Reviews'
					: ''
			: '';

		const title =
			isForResource &&
			(resourceVm.resourceSelector === Api.ResourceSelectorId.PolicyRenew ||
				resourceVm.resourceSelector === Api.ResourceSelectorId.FinancialReview)
				? `${moment()
						.set('month', parseInt(resourceVm.qualifier, 10) - 1)
						.format('MMMM')} ${type}`
				: 'Recipients';
		return (
			<div className={css(styleSheet.header)}>
				<div className={css(styleSheet.headerTitle)}>
					<div>{title}</div>
					{!emailComposer.isFollowUp && emailComposer.allowContactFilterTagSearchChanges ? (
						<>
							<button className={css(baseStyleSheet.brandLink)} onClick={() => setShowingAdvancedFiltersFlyout(true)}>
								<div>Advanced Filters</div>
							</button>
							<AdvancedFiltersFlyout2
								initialFilterCriteria={
									emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest?.criteria || []
								}
								initialOwnershipFilter={selectedOwnershipFilter}
								initialSelectedUserId={emailComposer.emailMessage.options.sendEmailFromUserId}
								onSave={onAdvancedFiltersFlyoutSave}
								isOpen={showingAdvancedFiltersFlyout}
								initialHouseholdGroupingOn={emailComposer.emailMessage.groupByHousehold}
								onDismissFlyout={onAdvancedFiltersFlyoutCancel}
								showHousehold={isHouseholdOptionEnabled}
								showOwnershipFilters
								disableOwnershipFilters={
									emailComposer?.emailMessage?.options?.sendEmailFrom === Api.SendEmailFrom.ContactOwner
								}
								impersonationContext={impersonationContext?.toJs()}
							/>
						</>
					) : null}
				</div>
				<div>
					{(emailComposer as ComposeResourceEmailViewModel).resourceSelector === Api.ResourceSelectorId.PolicyRenew ? (
						<RenewalEmailPolicySelector />
					) : null}
				</div>
				{!emailComposer.isFollowUp ? (
					<div>
						<TagEmailTagList
							emailComposer={emailComposer as ComposeEmailViewModel}
							onSearchesChanged={onSearchesChanged}
							readOnly={false}
						/>
					</div>
				) : null}
				{emailComposer.emailMessage.groupByHousehold ? (
					<div className={css(baseStyleSheet.horizontalStack, styleSheet.groupingLabel)}>
						<HomeIcon stroke={success} width={12} height={14} />
						<span>Grouping recipients by households</span>
					</div>
				) : null}
				<div className={css(baseStyleSheet.horizontalStack, baseStyleSheet.flex)}>
					{canShowShowingDropdown ? (
						<div className={css(baseStyleSheet.verticalStack, styleSheet.headerOptions)}>
							<div>Showing:</div>
							<OwnershipFilterDropdown
								initialOwnershipFilter={selectedOwnershipFilter}
								onOwnershipFilterChanged={onFilterOptionChanged}
								disabled={
									disableDropdown || (emailComposer.emailMessage.contactsToAdd.length > 0 && onlyDefaultTagsAreSelected)
								}
								allowSelectAUserOption={false}
								selectedUserId={emailComposer.emailMessage.options.sendEmailFromUserId}
								impersonationContext={impersonationContext?.toJs()}
							/>
						</div>
					) : null}
					{isForResource ? (
						<div className={css(baseStyleSheet.verticalStack, styleSheet.headerOptions)}>
							<div>Filter by available info</div>
							<DefaultSelectBox
								menuStyles={[styleSheet.filterDropdownMenu]}
								contentClassName={css(styleSheet.filterDropdownContent)}
								onSelectionChanged={onDeliveryMethodOptionChanged}
								options={BulkDeliveryMethodOptions}
								selectedOption={selectedDeliveryMethodOption}
								triggerStyles={[styleSheet.filterDropdownTrigger]}
							/>
						</div>
					) : null}
				</div>
				{renderContactCount()}
			</div>
		);
	};
	const renderRecipientsSearch = () => {
		return (
			<Search
				onNextClick={explicitelyPreventContactFilterRequest => {
					if (explicitelyPreventContactFilterRequest) {
						emailComposer?.setAllowContactFilterTagSearchChangesOverride(false);
					}
					onApplySearchCriteria();
				}}
				canClearFilters={canClearFilters}
			/>
		);
	};

	const onApplySearchCriteria = () => {
		if (emailComposer.emailMessage?.hasUserSelectedContactFilterSearchCriteria) {
			emailComposer?.reloadRecipientsList();
		} else {
			emailComposer?.resetRecipientsResultsList();
		}
		// TODO: This hides the search, then it resets above which clears criteria and starts over
		setShowSearch(false);
	};

	if (showSearch) {
		return renderRecipientsSearch();
	}

	const hasContactFilter = !!emailComposer?.emailMessage?.contactsFilterRequest?.contactFilterRequest;

	return (
		<>
			<div className={`${css(styleSheet.TagEmailRecipientsList, ...(styles || []))}`}>
				{renderRecipientsTagHeader()}
				<EmailRecipientsList
					allowNoContactsMessaging={
						selectedOwnershipFilter?.criteria?.property === Api.ContactFilterCriteriaProperty.Connections
					}
					bulkEmailComposer={emailComposer}
					className={css(styleSheet.list)}
					disableContactAdding={emailComposer.isFollowUp ? true : disableContactAdding}
					disableContactEdits={emailComposer.isFollowUp ? true : disableContactEdits}
					disableContactRemoval={disableContactRemoval}
					onContactSelected={onContactSelected}
					onScrollerRef={onScrollerRef}
					onScrollToBottom={getContacts(
						false,
						emailComposer?.emailMessage &&
							!emailComposer.emailMessage.isApproximating &&
							!emailComposer.emailMessage.contactEmailApproximation
					)}
				/>
			</div>
			<footer className={css(styleSheet.footer)}>
				{!isForResource && hasContactFilter && duplicateContactsFeatureFlag ? (
					<div>
						<EmailDuplicateContactSendCondition
							filterRequest={emailComposer.emailMessage.bulkContactsRequest}
							isSelected={emailComposer.emailMessage.contactsFilterRequest.groupByDuplicate ?? false}
							onChange={handleDuplicateContactsToggleChange}
						/>
					</div>
				) : null}
				<Button
					className={css(bs.wFull)}
					onClick={onCtaClick}
					disabled={
						disableCta ||
						emailComposer?.recipients?.length <= 0 ||
						emailComposer?.emailMessage.isBusy ||
						emailComposer?.isBusy
					}
					label={ctaButtonText}
					isLoading={emailComposer?.emailMessage.isBusy || emailComposer?.isBusy}
				/>
			</footer>
		</>
	);
}

const TagEmailRecipientsListAsObserver = observer(_TagEmailRecipientsList);
export const TagEmailRecipientsList = inject(ImpersonationContextKey)(TagEmailRecipientsListAsObserver);
