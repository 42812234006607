import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { IImpersonationContextComponentProps, ImpersonationContextKey } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useImageAttachmentMutation } from '../../../../queries';
import { ImageFileChooserModal } from '../../../components/ImageFileChooserModal';
import { ImagePreview } from '../../../components/ImagePreview';
import { ImageUploadButtons } from '../../../components/ImageUploadButtons';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MediaChooserModal } from '../../../components/MediaChooser';
import { DefaultHighResHorizontalPixabayImageSearchOptions } from '../../../components/MediaChooser/models';
import { IModalProps } from '../../../components/Modal';
import { TextInput } from '../../../components/TextInput';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../../components/richContent/RichContentDocumentEditor';
import { AIAssistantButton } from '../../AIAssistantButton';
import { ImageStage, REDUCER_ACTION_KEYS, useBlogPostEditorContext } from './hooks';
import { styleSheet } from './styles';

const defaultEditorConfig: IRichContentDocumentEditorConfig = {
	plugins: 'autolink',
	toolbar: 'h1 h2 h3 h4 h5 h6 | listmenu table | link insert-image',
	validStyles: { ['*']: '' }, // don't allow any styles
};

export interface IProps extends IImpersonationContextComponentProps {
	showWizardButton?: boolean;
}

function BlogPostEditorBase({ showWizardButton, impersonationContext }: IProps) {
	const { state, dispatch } = useBlogPostEditorContext();
	const userSession = useUserSession();
	const toaster = useToaster();
	const { logApiError } = useEventLogging('BlogPostEditor');
	const errorMessages = useErrorMessages();

	const onFreeImageChooserRequestCloseRef = React.useRef<(img?: Api.IFileAttachment, cancel?: boolean) => void>(null);
	const [freeImageModalProps, setFreeImageModalProps] = React.useState<IModalProps>({
		isOpen: false,
		onRequestClose: (result, cancel) => onFreeImageChooserRequestCloseRef.current?.(result, cancel),
	});

	const modalContent = React.useMemo(() => {
		const _content = state.content.getRawRichTextContent();
		// remove all tags to use as content for the image search modal
		const contentString = _content.document.replace(/<[^>]*>?/gm, '');
		return contentString;
	}, [state.content]);

	const hasImageSelected = Boolean(state.selectedImage);

	React.useEffect(() => {
		if (
			state.content.getRawRichTextContent().document.length &&
			!state.selectedImage &&
			state.imageState === ImageStage.Loading
		) {
			onClickFreeImage();
			toaster.push({
				message: 'Select a blog image',
				type: 'successMessage',
			});
		}
	}, [state.selectedImage, state.imageState, toaster, state.content]);

	const imageAttachmentMutation = useImageAttachmentMutation({
		onError: (error: Api.IOperationResultNoValue): void => {
			errorMessages.pushApiError(error);

			toaster.push({
				message: 'Failed to upload image',
				type: 'errorMessage',
			});
			logApiError('UploadImageEventRegistrationSurvey-Error', error);
		},
		onSuccess: result => {
			dispatch({ type: REDUCER_ACTION_KEYS.IMAGE_ATTACH_SUCCESS, payload: { image: result[0] } });
		},
	});

	const handleFileInputModalClose = (val?: File | null, cancel?: boolean) => {
		dispatch({ type: REDUCER_ACTION_KEYS.TOGGLE_FILE_INPUT_MODAL, payload: { open: false } });
		if (val && !cancel) {
			imageAttachmentMutation.mutate({ files: [val], impersonationContext });
		}
	};

	const resetImage = () => {
		dispatch({ type: REDUCER_ACTION_KEYS.IMAGE_SELECTED, payload: { image: null } });
	};

	const onContentEditorStateChanged = (contentEditorState: Api.IRichContentEditorState) => {
		dispatch({ type: REDUCER_ACTION_KEYS.SET_CONTENT, payload: { content: contentEditorState } });
	};

	const onClickMedia = () => {
		dispatch({ type: REDUCER_ACTION_KEYS.TOGGLE_FILE_INPUT_MODAL, payload: { open: true } });
	};

	function onClickFreeImage() {
		setFreeImageModalProps(value => {
			return {
				...value,
				isOpen: true,
			};
		});
	}

	const onFreeImageChooserRequestClose = React.useCallback(
		(imageFile?: Api.IFileAttachment, cancel?: boolean) => {
			if (cancel || !imageFile) {
				setFreeImageModalProps(value => {
					return {
						...value,
						isOpen: false,
					};
				});
				dispatch({
					type: REDUCER_ACTION_KEYS.GENERATING_CONTENT,
					payload: {
						imageState: ImageStage.Loaded,
						imageSuggestingLoading: false,
					},
				});
				return;
			}
			dispatch({ type: REDUCER_ACTION_KEYS.IMAGE_ATTACH_SUCCESS, payload: { image: imageFile } });
			setFreeImageModalProps(value => {
				return {
					...value,
					isOpen: false,
				};
			});
		},
		[dispatch]
	);

	onFreeImageChooserRequestCloseRef.current = onFreeImageChooserRequestClose;

	return (
		<>
			{state.status !== Api.BlogStatus.Pending ? (
				<>
					{showWizardButton && userSession.account?.features?.contentGeneration?.enabled ? (
						<div className={css(styleSheet.aiAssistantWrap)}>
							<AIAssistantButton
								onClick={() => dispatch({ type: REDUCER_ACTION_KEYS.SHOWINITIAL_AI_WIZARD, payload: { value: true } })}
							>
								Create this blog post with AI
							</AIAssistantButton>
						</div>
					) : null}
				</>
			) : null}
			<div className={css(styleSheet.formControl)}>
				<label htmlFor='title'>
					Title <sup>*</sup>
				</label>
				<TextInput
					inputId='title'
					name='title'
					type='text'
					value={state.title}
					onChange={e => dispatch({ type: REDUCER_ACTION_KEYS.SET_TITLE, payload: { title: e.target.value } })}
				/>
			</div>
			<div className={css(styleSheet.formControl, styleSheet.contentBlock)}>
				<label htmlFor='content'>
					Blog Content <sup>*</sup>
				</label>
				<RichContentDocumentEditor
					className={css(styleSheet.editor)}
					config={defaultEditorConfig}
					contentState={state.content}
					onContentStateChanged={onContentEditorStateChanged}
				/>
			</div>
			<div>
				{!state.imageSuggestingLoading ? (
					<>
						{!hasImageSelected ? (
							<>
								<label>Add Media</label>
								<ImageUploadButtons
									hasImage={hasImageSelected}
									onClickFreeImage={() => onClickFreeImage()}
									onClickMedia={onClickMedia}
								/>
							</>
						) : (
							<section>
								<h2 className={css(styleSheet.subTitle)}>
									{state.suggestedImage ? 'Ai suggested image' : 'Selected Image'}
								</h2>
								{state.selectedImage ? (
									<ImagePreview
										fileName={state.selectedImage?.fileName}
										fileSize={state.selectedImage?.fileSize}
										source={state.selectedImage?.source}
										onRemove={resetImage}
										url={state.selectedImage?.url}
									/>
								) : null}
							</section>
						)}
					</>
				) : (
					<section>
						<figure className={css(styleSheet.loadingImages)}>
							<LoadingSpinner type='large' />{' '}
							<figcaption style={{ marginLeft: 8 }}>Loading suggested images...</figcaption>
						</figure>
					</section>
				)}
			</div>
			<ImageFileChooserModal
				modalProps={{
					isOpen: state.fileInputModalOpen,
					onRequestClose: handleFileInputModalClose,
				}}
			/>
			{freeImageModalProps.isOpen ? (
				<MediaChooserModal
					content={modalContent ?? undefined}
					imageOnly={true}
					impersonationContext={impersonationContext}
					modalProps={freeImageModalProps}
					onSave={onFreeImageChooserRequestClose}
					styles={[styleSheet.mediaModalStyle]}
					useBasicSearch={false}
					imageSearchOptions={DefaultHighResHorizontalPixabayImageSearchOptions}
				/>
			) : null}
		</>
	);
}

export const BlogPostEditor = inject(ImpersonationContextKey)(observer(BlogPostEditorBase));
