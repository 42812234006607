import { StyleSheet } from 'aphrodite';
import { error, titles } from '../../../styles/colors';
import { CssSelectors, Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	contactsCountSpinner: {
		margin: '0 auto',
	},
	TagEmailRecipientsList: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: 'auto',
		width: '100%',
		...CssSelectors.allChildren({
			width: '100%',
		}),
	},
	filterDropdownMenu: {
		fontSize: 14,
	},
	filterDropdownContent: {
		width: '100% !important',
	},
	filterDropdownTrigger: {
		background: '#fff',
		color: '#858585',
		fontSize: 14,
	},
	groupingLabel: {
		fontSize: '0.75rem',
	},
	header: {
		...Layouts.verticalStack(20),
		boxSizing: 'border-box',
		padding: '30px 0 10px 0',
	},
	headerCount: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		fontSize: 14,
		justifyContent: 'flex-start',
	},
	headerCountNumbers: {},
	headerCountNumbersImportant: {
		color: error,
	},
	headerCountPolicies: {
		// to override the verticalStack
		':nth-child(1n):nth-child(1n)': {
			marginTop: 0,
		},
	},
	headerOptions: {
		color: '#858585',
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		fontSize: 14,
		marginBottom: '0.5rem',
	},
	headerTitle: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 18,
		justifyContent: 'space-between',
	},
	list: {
		flexGrow: 1,
		overflowY: 'auto',
		minHeight: 250,
	},
	recipientCountWithSort: {
		marginRight: 8,
	},
	select: {
		':not(:last-child)': {
			marginBottom: 10,
		},
		maxWidth: '100%',
	},
	sort: {
		border: 'none',
	},
	sortText: {
		color: titles,
		fontSize: 14,
		marginRight: 8,
	},
	sortTrigger: {
		padding: 0,
	},
	showingContentClassName: {
		width: '100% !important',
	},
	triggerText: {
		color: titles,
		paddingLeft: 10,
		width: '80%',
	},
	footer: {
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		paddingTop: 8,
		marginTop: 'auto',
	},
});
