import { css, StyleDeclarationValue } from 'aphrodite';
import React from 'react';
import { CampaignType } from '../../../../models/AdminModels';
import { CalendarType } from '../../../containers/ContentCalendar/models';
import { baseStyleSheet } from '../../../styles/styles';
import { CampaignTypeFeaturedLabel, CampaignTypeLabel, CampaignTypeMonthlyLabel } from './presentation';
import { styleSheet } from './styles';

interface CampaignCalendarEventCardProps {
	className?: string;
	type: CampaignType;
	footer?: React.ReactNode;
	title: string;
	eventDate: React.ReactNode;
	calendarType?: CalendarType;
	isSuggestion?: boolean;
	isFeatured?: boolean;
	category?: string;
	onCardClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export function CampaignCalendarCard({
	className,
	children,
	footer,
	type,
	title,
	eventDate,
	calendarType,
	isSuggestion,
	isFeatured,
	category,
	onCardClick,
}: React.PropsWithChildren<CampaignCalendarEventCardProps>) {
	const styles: StyleDeclarationValue[] = [];
	if (type === CampaignType.Email) {
		styles.push(styleSheet.cardWrapperEmail);
	}
	if (type === CampaignType.Social) {
		styles.push(styleSheet.cardWrapperSocial);
	}
	if (type === CampaignType.Blog) {
		styles.push(styleSheet.cardWrapperBlog);
	}
	if (isSuggestion) {
		styles.push(styleSheet.cardWrapperSuggestion);
	}
	if (isFeatured) {
		styles.push(styleSheet.cardWrapperFeature);
	}

	if (calendarType === CalendarType.Quarterly) {
		return (
			<button type='button' className={css(styleSheet.button)} onClick={onCardClick}>
				<div className={`${className ?? ''} ${css(styleSheet.cardWrapper, ...styles)}`}>
					<header className={css(styleSheet.cardHeader, isSuggestion ? styleSheet.cardHeaderSuggestion : undefined)}>
						{isFeatured ? <CampaignTypeFeaturedLabel isQuarterly type={type} /> : <CampaignTypeLabel type={type} />}

						<div
							className={css(styleSheet.cardHeaderTitle, baseStyleSheet.boldOnHighDpi)}
							style={{ display: '-webkit-box' }}
						>
							<span>{title}</span>
						</div>
						<div className={css(styleSheet.eventDate, isFeatured ? styleSheet.eventFeaturedDate : undefined)}>
							{eventDate}
						</div>
					</header>
					{category ? (
						<div className={css(styleSheet.cardCategory, styleSheet.cardCategoryQuarterly)}>{category}</div>
					) : null}
					{children ? <div className={css(styleSheet.cardBody)}>{children}</div> : null}
				</div>
				<footer className={css(styleSheet.footer)}>{footer}</footer>
			</button>
		);
	}
	if (calendarType === CalendarType.Month) {
		return (
			<button type='button' className={css(styleSheet.button, styleSheet.buttonSmall)} onClick={onCardClick}>
				<div
					className={`${className ?? ''} ${css(styleSheet.cardWrapper, styleSheet.cardWrapperSmallCards, ...styles)}`}
				>
					<header
						className={css(
							styleSheet.cardHeader,
							styleSheet.smallCardHeader,
							isSuggestion ? styleSheet.cardHeaderSmallCardSuggestion : undefined
						)}
					>
						{isFeatured ? <CampaignTypeFeaturedLabel type={type} /> : <CampaignTypeMonthlyLabel type={type} />}
						<div
							className={css(styleSheet.cardHeaderTitle, styleSheet.smallCardHeaderTitle, baseStyleSheet.boldOnHighDpi)}
							style={{ display: '-webkit-box' }}
						>
							<span>{title}</span>
						</div>
					</header>
					{category ? (
						<div className={css(styleSheet.cardCategory, styleSheet.cardCategoryMonth)}>{category}</div>
					) : null}
					<div className={css(styleSheet.cardBody, styleSheet.smallCardBody)}>{children}</div>
				</div>
				<footer className={css(styleSheet.footer, styleSheet.footerSmallCards)}>{footer}</footer>
			</button>
		);
	}

	return (
		<button type='button' className={css(styleSheet.button, styleSheet.buttonSmall)} onClick={onCardClick}>
			<div className={`${className ?? ''} ${css(styleSheet.cardWrapper, styleSheet.cardWrapperSmallCards, ...styles)}`}>
				<header
					className={css(
						styleSheet.cardHeader,
						styleSheet.smallCardHeader,
						isSuggestion ? styleSheet.cardHeaderSmallCardSuggestion : undefined
					)}
				>
					{isFeatured ? <CampaignTypeFeaturedLabel type={type} /> : <CampaignTypeMonthlyLabel type={type} />}
					<div
						className={css(styleSheet.cardHeaderTitle, styleSheet.smallCardHeaderTitle, baseStyleSheet.boldOnHighDpi)}
						style={{ display: '-webkit-box' }}
					>
						<span>{title}</span>
					</div>
				</header>
				{/** @TODO - Post image goes here */}
				{category ? <div className={css(styleSheet.cardCategory, styleSheet.cardCategoryMonth)}>{category}</div> : null}
				{children ? <div className={css(styleSheet.cardBody, styleSheet.smallCardBody)}>{children}</div> : null}
			</div>
			<footer className={css(styleSheet.footer, styleSheet.footerSmallCards)}>{footer}</footer>
		</button>
	);
}
