import { StyleSheet } from 'aphrodite';
import { hexToRGBA } from '../../../../../../models/UiUtils';
import { brandPrimary, charmGray, destructive, navigation, white } from '../../../../../styles/colors';
import { CssSelectors } from '../../../../../styles/styles';

export const styleSheet = StyleSheet.create({
	advancedOptions: {
		textAlign: 'left',
		fontSize: 12,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: 12,
	},
	arrows: {
		fontSize: 0,
		color: 'transparent',
	},
	bulletPointButton: {
		fontSize: 0,
		color: 'transparent',
	},
	bulletPoint: {
		width: 8,
		height: 8,
		borderRadius: '50%',
		display: 'inline-block',
		border: `1px solid ${charmGray}`,
	},
	bulletPointActive: {
		background: brandPrimary,
		boder: `1px solid transparent`,
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	clearButton: {
		color: brandPrimary,
		marginLeft: 4,
	},
	dropdownDisabled: {
		background: hexToRGBA(charmGray, 0.17),
	},
	disconnectedAccount: {
		color: destructive,
	},
	tagTokenContainer: {
		maxHeight: 'unset',
		...CssSelectors.allChildren({
			marginLeft: '0.5rem',
		}),
	},
	richContentEditor: {
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
	},
	infoPopoverToggle: {
		width: 14,
		height: 14,
	},
	itemImageWrap: {
		width: 112,
		height: 112,
		borderTopLeftRadius: 8,
		borderBottomLeftRadius: 8,
		position: 'relative',
		flex: '1 0 112px',
		display: 'flex',
		alignItems: 'center',
		...CssSelectors.allChildren(
			{
				width: 112,
				height: 112,
				objectFit: 'cover',
			},
			'img'
		),
		...CssSelectors.allChildren(
			{
				width: 112,
				height: 112,
				objectFit: 'cover',
			},
			'video'
		),
	},
	noImagePlaceholder: {
		border: `1px solid ${navigation}`,
		borderRight: 0,
		boxSizing: 'border-box',
		color: navigation,
		fontSize: '0.875rem',
		textAlign: 'center',
	},
	previewMenu: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		margin: '0.75rem 0 0.875rem',
		justifyContent: 'center',
		...CssSelectors.allChildren({
			marginRight: '0.25rem',
		}),
	},
	popover: {
		borderBottomRightRadius: 0,
		boxSizing: 'border-box',
		color: '#4A4A4A',
		fontSize: 12,
		lineHeight: '18px',
		marginLeft: 14,
		maxWidth: 300,
		padding: '6px 6px 6px 6px',
	},
	doubleArrowPrev: {
		transform: 'rotate(180deg)',
	},
	scheduler: {
		flex: 1,
		minHeight: 0,
	},
	sidebarFooter: {
		maxWidth: 250,
		margin: '0 auto',
	},
	socialMediaDropdowns: {
		display: 'flex',
		gap: '1rem',
		padding: '1.5rem 1.5rem 1rem',
		background: white,
		marginTop: -2,
		position: 'relative',
		marginBottom: 30,
		':after': {
			content: '""',
			position: 'absolute',
			bottom: -30,
			left: '1.5rem',
			width: 'calc(100% - 3rem)',
			height: 1,
			background: hexToRGBA(charmGray, 0.25),
		},
	},
	socialMediaDropdown: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
	},
	selectOption: {
		height: '100%',
		padding: '0 8px',
		maxWidth: 280,
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		gap: 8,
	},
});
