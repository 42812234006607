import * as Api from '@ViewModels';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IModalContext } from '../../../../models';
import { asModalComponent } from '../../Modal';
import {
	SocialMediaImagesPreviewAlert,
	SocialMediaImagesPreviewImages,
	SocialMediaImagesPreviewRadioButtons,
} from './presentation';

interface IProps extends IModalContext<Api.IFileAttachment> {
	images?: Api.IFileAttachment[];
	initialImageUrl?: string;
	showOverlayLogo?: boolean;
	logoUrl: string;
}

function _SocialMediaImagesPreviewModal({ images, initialImageUrl, showOverlayLogo, logoUrl }: IProps) {
	const [currentImageIndex, setCurrentImageIndex] = React.useState<number>(
		initialImageUrl ? images.findIndex(i => i.url === initialImageUrl) : 0
	);

	const onImageIndexChange = (index: number) => () => {
		setCurrentImageIndex(index);
	};
	return (
		<div>
			<div>
				{showOverlayLogo ? <SocialMediaImagesPreviewAlert /> : null}
				<SocialMediaImagesPreviewImages
					images={images}
					currentImageIndex={currentImageIndex}
					showOverlayLogo={showOverlayLogo}
					url={logoUrl}
					onImageIndexChange={onImageIndexChange}
				/>
				{images?.length > 1 ? (
					<SocialMediaImagesPreviewRadioButtons
						key='images-radio-buttons'
						currentImageIndex={currentImageIndex}
						images={images}
						onImageIndexChange={onImageIndexChange}
					/>
				) : null}
			</div>
		</div>
	);
}

const SocialMediaImagesPreview = observer(_SocialMediaImagesPreviewModal);

export const SocialMediaImagesPreviewModal = asModalComponent(SocialMediaImagesPreview, {
	className: `social-media-images-preview-modal`,
	shouldCloseOnOverlayClick: true,
	useDefaultHeader: true,
});
