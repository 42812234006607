import * as Api from '@ViewModels';
import * as React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { ICreateSocialMediaPostRequest, ILocationState } from '../../../../models';
import { AIContentGenerationStatus } from '../../../../models/Ai';
import {
	EDuplicateType,
	getDuplicateTemplateType,
	shouldShowDuplicateTemplateWarning,
} from '../../../../models/Campaigns';
import { useEventLogging } from '../../../../models/Logging';
import {
	createContentStateWithHtmlStringValue,
	getSocialMediaPlainTextContentFromRawRichContent,
	isScheduledOrPendingOrDraftOrNew,
} from '../../../../models/UiUtils';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useSocialContentGeneration } from '../../../../queries';
import { ImpersonationContextViewModel } from '../../../../viewmodels/AdminViewModels';
import { ISelectOption } from '../../../components/Select';
import { IEditSocialMediaPostContext, useCreateEditSocialMediaContext } from './context';

export function useAIContentGenerationWizard({
	context,
	creatingPostWithEmptyInitialContent,
}: {
	context: IEditSocialMediaPostContext;
	creatingPostWithEmptyInitialContent?: boolean;
}) {
	const { logApiError } = useEventLogging('AiContentGeneratedPost');
	const userSession = useUserSession();
	const [contentGenerationStatus, setContentGenerationStatus] = React.useState<AIContentGenerationStatus>(
		AIContentGenerationStatus.Idle
	);
	const { mutateAsync } = useSocialContentGeneration({
		onError: error => {
			setContentGenerationStatus(
				error.systemCode === 408 ? AIContentGenerationStatus.TimeOut : AIContentGenerationStatus.Error
			);
		},
		onSuccess: () => setContentGenerationStatus(AIContentGenerationStatus.Success),
		userSession,
	});

	const generateContentAsync = (request: Api.IAIContentGenerationRequest) => {
		setContentGenerationStatus(AIContentGenerationStatus.Generating);
		return mutateAsync(request);
	};

	// @ts-ignore
	const [undoContent, setUndoContent] = React.useState<Api.IRawRichTextContentState>(null);
	const onUndoClicked = () => {
		if (undoContent) {
			// @ts-ignore
			context.setContent(getSocialMediaPlainTextContentFromRawRichContent(undoContent));
		}
	};

	const onGenerateClicked = async (options: Api.IAIContentGenerationOptions) => {
		try {
			const request: Api.IAIContentGenerationRequest = { ...options };
			// @ts-ignore
			const currentContent = createContentStateWithHtmlStringValue(context.postContent).getRawRichTextContent();
			if (!creatingPostWithEmptyInitialContent) {
				request.content = currentContent;
			}

			setUndoContent(currentContent);
			const generatedContent = await generateContentAsync(request);
			// @ts-ignore
			context.setContent(getSocialMediaPlainTextContentFromRawRichContent(generatedContent.content));
			// @ts-ignore
			// @ts-ignore
			context.setAIReference({ sessionId: generatedContent.sessionId });
			return generatedContent;
		} catch (error) {
			// @ts-ignore
			logApiError('AiContentGeneration-Error', error);
		}
	};

	const clearContentGenerationStatus = React.useRef(() => {
		setContentGenerationStatus(AIContentGenerationStatus.Idle);
	}).current;

	return {
		clearContentGenerationStatus,
		contentGenerationStatus,
		onGenerateClicked,
		onUndoClicked,
	} as const;
}

export function useEditSocialMediaPost({
	impersonationContext,
}: {
	impersonationContext?: ImpersonationContextViewModel;
}) {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('EditSocialMediaPost');
	const toaster = useToaster();
	const [context] = useCreateEditSocialMediaContext();
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);
	const [userIsLoaded, setUserIsLoaded] = React.useState(false);
	const routeMatch = useRouteMatch<{ templateId?: string; suggestionId?: string }>();
	const loc = useLocation();
	const suggestionId = routeMatch?.params?.suggestionId;
	const h =
		useHistory<
			ILocationState<
				Api.SocialMediaPostReportViewModel | Api.SocialMediaPostViewModel | Api.ContentCalenderSuggestionViewModel,
				ICreateSocialMediaPostRequest<Api.ITemplate | string>
			>
		>();

	const [postReport, setPostReport] = React.useState<Api.SocialMediaPostReportViewModel>(
		// @ts-ignore
		h.location.state?.viewModel instanceof Api.SocialMediaPostReportViewModel ? h.location.state.viewModel : null
	);
	const [postToEdit, setPostToEdit] = React.useState<Api.SocialMediaPostViewModel>(
		// @ts-ignore
		h.location.state?.viewModel instanceof Api.SocialMediaPostViewModel ? h.location.state.viewModel : null
	);
	React.useEffect(() => {
		if (h.location.state?.viewModel instanceof Api.SocialMediaPostViewModel) {
			setPostToEdit(h.location.state.viewModel as Api.SocialMediaPostViewModel);
		} else if (h.location.state?.viewModel instanceof Api.SocialMediaPostReportViewModel) {
			setPostReport(h.location.state.viewModel as Api.SocialMediaPostReportViewModel);
		}
	}, [h.location.state?.viewModel]);
	const source = postReport || postToEdit;

	const locationStateCreateRequest = React.useRef(h.location.state?.model).current;

	const impersonationCtx = impersonationContext || postReport?.impersonationContext;

	const templates = React.useRef<Api.TemplatesViewModel>(
		new Api.TemplatesViewModel(userSession).impersonate(impersonationCtx)
	).current;

	const templateId = React.useRef(
		routeMatch.params?.templateId ||
			(typeof locationStateCreateRequest?.context === 'string'
				? (locationStateCreateRequest?.context as string)
				: locationStateCreateRequest?.context?.id)
	).current;

	const [duplicateType, setDuplicateType] = React.useState<EDuplicateType | null>(null);
	const [showDuplicateWarning, setShowDuplicateWarning] = React.useState(false);
	// @ts-ignore
	const [scheduledPost, setScheduledPost] = React.useState<Api.SocialMediaPostViewModel>(null);
	const [socialMediaConnections, setSocialMediaConnections] = React.useState<Api.ISocialMediaConnection[]>([]);
	const initialSelectedUserOptionRef = React.useRef<ISelectOption<Api.UserViewModel<Api.IUser>>>(null);

	React.useEffect(() => {
		if (!isLoaded && !isLoading) {
			(async () => {
				setIsLoading(true);
				const account = impersonationCtx?.account || userSession.account;
				const alwaysOverlayLogo = account?.features?.socialMedia?.alwaysOverlayLogo;
				context.setOverlayLogo(alwaysOverlayLogo);

				const loadFromSocialMediaTemplate = (socialMediaTemplate: Api.ITemplate) => {
					if (socialMediaTemplate?.templateType !== Api.TemplateType.SocialMediaPost) {
						return;
					}

					const dupType = getDuplicateTemplateType(socialMediaTemplate);
					setDuplicateType(dupType);

					const shouldShowWarning = shouldShowDuplicateTemplateWarning(socialMediaTemplate);
					if (shouldShowWarning) {
						setShowDuplicateWarning(shouldShowWarning);
					}
					// @ts-ignore
					// @ts-ignore
					context.setContent(getSocialMediaPlainTextContentFromRawRichContent(socialMediaTemplate.content));
					// @ts-ignore
					context.setImages(socialMediaTemplate.attachments);
					// @ts-ignore
					context.setTemplate(socialMediaTemplate);
					// @ts-ignore
					context.setHasChanges(false);
					// @ts-ignore
					// @ts-ignore
					context.setCampaignName(socialMediaTemplate.name);
				};

				const resolveSocialMediaPostTemplate = async (id: string) => {
					try {
						const template = await templates.getByIdExpandedByLastUsedBy(id);

						// check the template type and try to load socal media template from "associatedTemplates" collection if
						// the provided "templateId" actually points to an email template
						if (template.templateType === Api.TemplateType.SocialMediaPost) {
							loadFromSocialMediaTemplate(template);
							return template;
							// @ts-ignore
						} else if (template.templateType === Api.TemplateType.Email && template.associatedTemplates?.length > 0) {
							try {
								// @ts-ignore
								const socialMediaTemplate = await templates.getById(template.associatedTemplates[0].id);
								loadFromSocialMediaTemplate(socialMediaTemplate);
								return socialMediaTemplate;
							} catch (err) {
								// @ts-ignore
								// @ts-ignore
								errorMessages.pushApiError(err);
								// @ts-ignore
								logApiError('LoadTemplate-Error', err);
							}
						}
					} catch (err) {
						// @ts-ignore
						// @ts-ignore
						errorMessages.pushApiError(err);
						// @ts-ignore
						logApiError('LoadTemplate-Error', err);
					}
				};

				if (postReport || postToEdit) {
					try {
						const post = (await postReport?.loadPost()) || (await postToEdit?.load());
						if (post) {
							if (post?.templateReference?.templateId) {
								try {
									const template = await templates.getById(post.templateReference.templateId);
									if (template.templateType === Api.TemplateType.SocialMediaPost) {
										// @ts-ignore
										context.setTemplate(template);
									} else if (
										template.templateType === Api.TemplateType.Email &&
										// @ts-ignore
										template.associatedTemplates?.length > 0
									) {
										// @ts-ignore
										const socialMediaTemplate = await templates.getById(template.associatedTemplates[0].id);
										// @ts-ignore
										context.setTemplate(socialMediaTemplate);
									}
								} catch (err) {
									// @ts-ignore
									if (err?.systemCode !== 404) {
										// skip error message when original social template has been deleted
										// @ts-ignore
										// @ts-ignore
										errorMessages.pushApiError(err);
										// @ts-ignore
										logApiError('LoadTemplate-Error', err);
									}
								}
							}
							// WIP: once post data is backfilled this can be changed to simply assign post.target
							// @ts-ignore
							const targetsMap: Api.IPostTarget[] =
								// @ts-ignore
								post?.designatedTargets?.length > 0 ? post.designatedTargets : post?.targets;
							// @ts-ignore
							context.setContent(getSocialMediaPlainTextContentFromRawRichContent(post?.content));
							// @ts-ignore
							context.setImages(post.attachments);
							// @ts-ignore
							// @ts-ignore
							context.setSelectedCreator(post.creator);
							// @ts-ignore
							context.setPostTargets(targetsMap);
							// @ts-ignore
							context.setHasChanges(false);
							// @ts-ignore
							// @ts-ignore
							context.setCampaignName(post.name);
							context.setOverlayLogo(post.overlayLogo);
							setScheduledPost(
								postToEdit || new Api.SocialMediaPostViewModel(userSession, post).impersonate(impersonationCtx)
							);
						}
					} catch (err) {
						// @ts-ignore
						// @ts-ignore
						errorMessages.pushApiError(err);
						// @ts-ignore
						logApiError('LoadPost-Error', err);
					}
				} else if (locationStateCreateRequest?.context) {
					if (typeof locationStateCreateRequest.context === 'string') {
						try {
							await resolveSocialMediaPostTemplate(locationStateCreateRequest.context);
						} catch (err) {
							// @ts-ignore
							// @ts-ignore
							errorMessages.pushApiError(err);
							// @ts-ignore
							logApiError('LoadTemplate-Error', err);
						}
					} else {
						loadFromSocialMediaTemplate(locationStateCreateRequest.context);
					}
				} else if (templateId) {
					try {
						await resolveSocialMediaPostTemplate(templateId);
					} catch (err) {
						// @ts-ignore
						// @ts-ignore
						errorMessages.pushApiError(err);
						// @ts-ignore
						logApiError('LoadTemplate-Error', err);
					}
				}

				if (locationStateCreateRequest?.sendFromUser && !source) {
					// @ts-ignore
					context.setSelectedCreator(locationStateCreateRequest.sendFromUser);
				} else if (!impersonationCtx && !context.selectedCreator && !source) {
					// @ts-ignore
					// @ts-ignore
					context.setSelectedCreator(userSession.user);
				}
				setIsLoaded(true);
				setIsLoading(false);
			})();
		}
	}, [
		context,
		errorMessages,
		impersonationCtx,
		isLoaded,
		isLoading,
		locationStateCreateRequest?.context,
		locationStateCreateRequest?.sendFromUser,
		logApiError,
		postReport,
		postToEdit,
		socialMediaConnections?.length,
		source,
		templateId,
		templates,
		userSession,
	]);

	React.useEffect(() => {
		if (isLoaded && !userIsLoaded) {
			(async () => {
				if (!socialMediaConnections?.length && context.selectedCreator && isLoaded && !userIsLoaded) {
					const activeUser = new Api.UserViewModel(userSession, context.selectedCreator).impersonate(impersonationCtx);
					// @ts-ignore
					initialSelectedUserOptionRef.current = {
						dataContext: activeUser,
						id: activeUser.id,
					};
					try {
						const { socialMediaConnectedAccounts } = await activeUser.load();
						const activeConnections = socialMediaConnectedAccounts?.filter(
							x =>
								(x.state === Api.SocialMediaConnectionState.Connected ||
									x.state === Api.SocialMediaConnectionState.Disconnected) &&
								!!x.postTargetId
						);
						// Update postTargets based on active connections when relavent
						// The backend should be properly updating lastConnectionState to contain a valid postTargetId but the following
						// is a check to ensure display names etc display with "unknown" rather than null or undefined
						let selectedTargetConnections = context?.postTargets?.map(x =>
							x.state?.lastConnectionState?.postTargetId
								? x.state?.lastConnectionState
								: {
										postTargetDisplayName: 'Unknown',
										postTargetId: x?.pageId,
										state: Api.SocialMediaConnectionState.Unknown,
										type: x.provider,
										userId: x?.userId || x?.state?.lastConnectionState?.userId,
										userName: x?.state?.lastConnectionState?.userName || 'Unknown',
									}
						);

						// extra connections defines the set of connections that are any connections remaining in activeConnections that are not part
						// of the selected postTargets
						const extraConnections =
							activeConnections?.filter(
								x => !selectedTargetConnections?.find(y => y?.postTargetId === x.postTargetId && y?.type === x.type)
							) || [];
						if (!source) {
							// @ts-ignore
							setSocialMediaConnections(activeConnections);
						} else if (
							isScheduledOrPendingOrDraftOrNew(source) &&
							context.selectedCreator?.id === userSession.user?.id
						) {
							// When an existing post is editable social media connections are set as the union of selectedTargetConnections and extraConnections
							if (
								extraConnections?.length > 0 &&
								// @ts-ignore
								selectedTargetConnections?.filter(x => !x?.postTargetId)?.length > 0 &&
								// @ts-ignore
								context?.postTargets?.length > 0
							) {
								// If we are in a data state where one of the selectedPostTargets (and therefore selectedTargetConnections) does not have a valid postTargetId
								// we need to update the postTargets and the selectedTargetConnections
								// This is a failsafe for any bug in the backend where the postTargetId is not being set correctly
								// Replace postTargets with a userId match from extraConnections and update selectedTargetConnections to match
								const updatedTargets = context?.postTargets?.map(x => {
									if (x.state?.lastConnectionState?.postTargetId) {
										return x;
									} else {
										const matchTargetConnection = extraConnections?.find(
											y =>
												y?.type === x.provider && y?.userId === x?.state?.lastConnectionState?.userId && y?.postTargetId
										);
										const matchTargetConnectionIndex = extraConnections?.findIndex(
											y =>
												y?.type === x.provider && y?.userId === x?.state?.lastConnectionState?.userId && y?.postTargetId
										);
										if (matchTargetConnectionIndex > -1) {
											extraConnections?.splice(matchTargetConnectionIndex, 1);
										}
										return {
											pageId: matchTargetConnection?.postTargetId,
											provider: x.provider,
											state: {
												lastConnectionState: matchTargetConnection,
												status: Api.PostTargetStatus.Unknown,
											},
											userId: x.userId,
										};
									}
								});

								// @ts-ignore
								context.setPostTargets(updatedTargets);
								selectedTargetConnections = updatedTargets?.map(x =>
									x.state?.lastConnectionState?.postTargetId
										? x.state?.lastConnectionState
										: {
												postTargetDisplayName: 'Unknown',
												postTargetId: x?.pageId,
												state: Api.SocialMediaConnectionState.Unknown,
												type: x.provider,
												userId: x?.userId || x?.state?.lastConnectionState?.userId,
												userName: x?.state?.lastConnectionState?.userName || 'Unknown',
											}
								);
							}
							// @ts-ignore
							setSocialMediaConnections(selectedTargetConnections.concat(extraConnections));
						} else {
							// @ts-ignore
							setSocialMediaConnections(selectedTargetConnections);
						}

						// @ts-ignore
						if (!context.postTargets.length) {
							// @ts-ignore
							const selectedTargets: Api.IPostTarget[] = activeConnections
								?.filter(z => z.state === Api.SocialMediaConnectionState.Connected) // for setting targets only
								.map(x => ({
									pageId: x.postTargetId,
									provider: x.type,
									userId: x.userId,
								}))
								.filter(y =>
									// @ts-ignore
									locationStateCreateRequest?.targets?.length > 0
										? locationStateCreateRequest?.targets?.includes(y.provider)
										: y
								);
							// @ts-ignore
							context.setPostTargets(selectedTargets);
						}
					} catch (err) {
						// @ts-ignore
						// @ts-ignore
						errorMessages.pushApiError(err);
						// @ts-ignore
						logApiError('SetUserConnections-Error', err);
					}
				}
				setUserIsLoaded(true);
			})();
		}
	}, [
		socialMediaConnections?.length,
		context.selectedCreator,
		isLoaded,
		userIsLoaded,
		context,
		userSession,
		impersonationCtx,
		source,
		locationStateCreateRequest?.targets,
		errorMessages,
		logApiError,
	]);

	return {
		context,
		duplicateType,
		errorMessages,
		h,
		impersonationCtx,
		initialSelectedUserOptionRef,
		isLoaded,
		loc,
		locationStateCreateRequest,
		logApiError,
		postReport,
		postToEdit,
		routeMatch,
		scheduledPost,
		setScheduledPost,
		setShowDuplicateWarning,
		setSocialMediaConnections,
		showDuplicateWarning,
		socialMediaConnections,
		source,
		suggestionId,
		templateId,
		toaster,
		userIsLoaded,
	} as const;
}
