import * as Api from '@ViewModels';
import { SimpleAutoCompleteSearchField } from '@WebComponents/autocomplete/SimpleAutoCompleteSearchField';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { v4 as uuidgen } from 'uuid';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { areOwnershipFiltersEqual, getDisplayName, getUserFromOwnershipFilter } from '../../../../models/UiUtils';
import { useUserQuery } from '../../../../queries';
import { ISelectOption, Select } from '../../../components/Select';
import { styleSheet } from './styles';

export interface IOwnershipFilterDropdownProps {
	allowSelectAUserOption?: boolean;
	disableUserSearch?: boolean;
	selectedUserId?: string;
	initialOwnershipFilter: Api.IOwnershipFilter | null;
	onOwnershipFilterChanged: (option: Api.IOwnershipFilter) => void;
	onSelectedUserChanged?: React.Dispatch<React.SetStateAction<string | null>>;
	className?: string;
	styles?: StyleDeclarationValue[];
	disabled?: boolean;
	impersonationContext?: Api.IImpersonationContext;
}

export const OwnershipFilterDropdown = ({
	allowSelectAUserOption,
	disableUserSearch,
	selectedUserId,
	initialOwnershipFilter,
	onOwnershipFilterChanged,
	onSelectedUserChanged,
	className,
	styles,
	disabled,
	impersonationContext,
}: IOwnershipFilterDropdownProps) => {
	const userSession = useUserSession();

	const selectedUser = selectedUserId ?? getUserFromOwnershipFilter(initialOwnershipFilter);
	const userQuery = useUserQuery({
		enabled: Boolean(selectedUser),
		id: selectedUser ?? '',
		impersonationContext,
	});
	const onRenderOwnershipOptionPlaceholder = React.useCallback(() => 'Please select...', []);

	const options = React.useMemo(() => {
		let value =
			userSession.user.ownershipFilters?.map(x => {
				return { id: uuidgen(), dataContext: x, text: x.title };
			}) ?? [];

		if (selectedUser) {
			if (selectedUser !== userSession.user.id) {
				const ownedByFilter = {
					criteria: { property: Api.ContactFilterCriteriaProperty.OwnedBy, value: selectedUser },
				};
				value.push({ id: uuidgen(), dataContext: ownedByFilter, text: 'Contacts they own' });
			}
		}
		if (impersonationContext) {
			value = value.filter(option => {
				// Filter out 'My Connections' since impersonation context is valid
				if (
					option.dataContext.criteria.property === Api.ContactFilterCriteriaProperty.Connections ||
					(option.dataContext.criteria.property === Api.ContactFilterCriteriaProperty.OwnedBy &&
						option.dataContext.criteria.value === userSession.user.id)
				) {
					return false;
				}
				return true;
			});
		}
		return value;
	}, [selectedUser, userSession.user, impersonationContext]);

	const [selectedOption, setSelectedOption] = React.useState<ISelectOption<Api.IOwnershipFilter>>(
		options.find(x => areOwnershipFiltersEqual(userSession.user.id, x.dataContext, initialOwnershipFilter))
	);

	React.useEffect(() => {
		setSelectedOption(
			options.find(x => areOwnershipFiltersEqual(userSession.user.id, x.dataContext, initialOwnershipFilter))
		);
	}, [initialOwnershipFilter, userSession.user.id, options]);

	const [showUserSearch, setShowUserSearch] = React.useState(
		initialOwnershipFilter?.criteria?.property === Api.ContactFilterCriteriaProperty.OwnedBy &&
			initialOwnershipFilter.criteria.value !== userSession.user.id
	);

	const onOwnershipOptionChanged = (value: ISelectOption<Api.IOwnershipFilter>) => {
		setSelectedOption(value);
		setShowUserSearch(
			value.dataContext.criteria?.property === Api.ContactFilterCriteriaProperty.OwnedBy &&
				value.dataContext.criteria.value !== userSession.user.id
		);
		onOwnershipFilterChanged(value.dataContext);
	};

	const selectedUserChanged = (value: string) => {
		if (onSelectedUserChanged) {
			onSelectedUserChanged(value);
		}
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
			<Select
				disabled={disabled}
				onOptionClick={onOwnershipOptionChanged}
				onRenderPlaceholder={onRenderOwnershipOptionPlaceholder}
				options={options}
				selectedOption={selectedOption}
				styles={[styleSheet.dropdown]}
				triggerStyles={[disabled && styleSheet.dropdownDisabled]}
			/>

			{allowSelectAUserOption && showUserSearch ? (
				<>
					<h6 className={css(styleSheet.label)}>Owner</h6>
					<SimpleAutoCompleteSearchField
						key={userQuery.data?.id && !userQuery.isLoading ? userQuery.data?.id : 'searching'}
						disabled={disabled || disableUserSearch}
						// @ts-ignore
						onItemSelected={selectedUserChanged}
						placeholder='Search name'
						type={Api.ResourceAutoCompleteViewModelType.User}
						initialSearchQuery={userQuery.data ? getDisplayName(userQuery.data) : ''}
					/>
					{!selectedUser ? (
						<p role='alert' className={css(styleSheet.formErrorLine)}>
							Owner is required when selecting Contacts owned by
						</p>
					) : null}
				</>
			) : null}
		</div>
	);
};
