import { TemplateScope } from '@ViewModels';
import { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { Checkbox } from '../Checkbox';

export type ShareScope = TemplateScope.User | TemplateScope.Account;

interface IShareTemplateCheckboxProps {
	disabled?: boolean;
	shareScope?: ShareScope;
	onScopeChange: Dispatch<SetStateAction<ShareScope>>;
}

export const ShareTemplateCheckbox: FC<IShareTemplateCheckboxProps> = ({ disabled, shareScope, onScopeChange }) => {
	const handleScopeChange = useCallback((prev: ShareScope) => {
		return prev === TemplateScope.User ? TemplateScope.Account : TemplateScope.User;
	}, []);

	return (
		<Checkbox
			checked={shareScope === TemplateScope.Account}
			aria-checked={shareScope === TemplateScope.Account}
			disabled={disabled}
			onChange={() => onScopeChange(handleScopeChange)}
		>
			<span>Share template with co-workers</span>
		</Checkbox>
	);
};
