import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { IEmojiData } from 'emoji-picker-react';
import equal from 'fast-deep-equal';
import { observer } from 'mobx-react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Editor } from 'tinymce';
import { isCustomResourceSelector } from '../../../../extViewmodels/Utils';
import { Browser } from '../../../../models/Browser';
import { useEventLogging } from '../../../../models/Logging';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultTimeStringValue,
	getRichContentMessageForTextingStep,
} from '../../../../models/UiUtils';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { brandPrimary, oldSilver } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { AttachInputButton } from '../../AttachInputButton';
import { Checkbox } from '../../Checkbox';
import { ConfirmationDialog } from '../../ConfirmationDialog';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { InputFieldError } from '../../InputFieldError';
import { LoadingSpinner } from '../../LoadingSpinner';
import { RadioButton } from '../../RadioButton';
import { ISelectOption, Select } from '../../Select';
import { DefaultSelectBox, ISelectBoxOption } from '../../SelectBox';
import { TextCampaignEditor } from '../../TextCampaignEditor';
import { TextInput } from '../../TextInput';
import { DisclosureIcon } from '../../svgs/icons/DisclosureIcon';
import { WarningIcon } from '../../svgs/icons/WarningIcon';
import { EmojiPickerButton } from '../../texting/EmojiPickerButton';
import { EditAutomationStepEmailComposerModal } from '../EditAutomationStepEmailComposerModal';
import { styleSheet } from './styles';

interface IEditAutomationTemplateStepCardHeaderProps {
	canEdit?: boolean;
	onRenderTitle?(): React.ReactNode;
	styles?: StyleDeclarationValue[];
	ActionMenu?: React.ReactNode;
}

export const EditAutomationTemplateStepCardHeader: React.FC<IEditAutomationTemplateStepCardHeaderProps> = props => {
	const { onRenderTitle, canEdit, styles = [], ActionMenu } = props;

	return (
		<div className={`edit-automation-template-step-card-header ${css(styleSheet.header, ...styles)}`}>
			<div className={css(styleSheet.headerTitle)}>{onRenderTitle ? onRenderTitle() : 'Add a Step'}</div>
			{ActionMenu && canEdit ? ActionMenu : null}
		</div>
	);
};

interface IAutomationStepCardScheduleProps {
	canEdit?: boolean;
	isBusy?: boolean;
	index: number;
	onRequestSetSchedule?(schedule: Api.IAutomationStepSchedule): void;
	schedule?: Api.IAutomationStepSchedule;
	step: Api.AutomationTemplateEditorStep;
	stepType: Api.AutomationStepType;
	trigger?: Api.IAutomationTrigger;
}

export enum EScheduleAnchorOptions {
	Before = 'before',
	After = 'after',
	SameDay = 'sameDay',
}

export type AutomationStepScheduleAnchorQualifierSelectOption = ISelectOption<
	EScheduleAnchorOptions.Before | EScheduleAnchorOptions.After | EScheduleAnchorOptions.SameDay
>;

const stepTypesSupportingTimeOfDayInput = [Api.AutomationStepType.Email, Api.AutomationStepType.Texting];

export const getTriggerDescription = (trigger?: Api.IAutomationTrigger) => {
	let triggerDescription: string = null;
	switch (trigger?._type) {
		case Api.AutomationTriggerType.ResourceSelector: {
			const t = trigger as Api.IResourceSelectorAutomationTrigger;
			switch (t.resourceSelector) {
				case Api.ResourceSelectorId.TurningXX:
				case Api.ResourceSelectorId.Turning72:
				case Api.ResourceSelectorId.Turning73:
				case Api.ResourceSelectorId.HappyBirthday:
				case Api.ResourceSelectorId.Turning65: {
					triggerDescription = 'birthday';
					break;
				}
				case Api.ResourceSelectorId.PolicyRenew: {
					triggerDescription = 'renewal';
					break;
				}
				case Api.ResourceSelectorId.FinancialReview: {
					triggerDescription = 'review';
					break;
				}
				case Api.ResourceSelectorId.Custom1:
				case Api.ResourceSelectorId.Custom2:
				case Api.ResourceSelectorId.Custom3:
				case Api.ResourceSelectorId.Custom4:
				case Api.ResourceSelectorId.Custom5:
				case Api.ResourceSelectorId.Custom6:
				case Api.ResourceSelectorId.Custom7:
				case Api.ResourceSelectorId.Custom8:
				case Api.ResourceSelectorId.Custom9:
				case Api.ResourceSelectorId.Custom10: {
					return 'custom trigger';
				}
				default: {
					break;
				}
			}
			break;
		}
		case Api.AutomationTriggerType.NewLead: {
			triggerDescription = 'new lead';
			break;
		}
		case Api.AutomationTriggerType.NewClient: {
			triggerDescription = 'new client';
			break;
		}
		case Api.AutomationTriggerType.NewDonor: {
			triggerDescription = 'new donor';
			break;
		}
		case Api.AutomationTriggerType.Tag: {
			triggerDescription = 'tag';
			break;
		}
		case Api.AutomationTriggerType.Meeting: {
			triggerDescription = 'meeting';
			break;
		}
		case Api.AutomationTriggerType.EventRegistration: {
			triggerDescription = 'event';
			break;
		}
		default: {
			triggerDescription = 'automation starts';
			break;
		}
	}

	return triggerDescription;
};

enum EHourDaysTypes {
	Hours = 'hours',
	Days = 'days',
}

type TDaysHoursOptions = ISelectOption<EHourDaysTypes.Hours | EHourDaysTypes.Days>;

const getAutomationStepScheduleAnchorQualifierSelectOptions = (trigger?: Api.IAutomationTrigger) => {
	let options: AutomationStepScheduleAnchorQualifierSelectOption[] = [];
	const addBefore = Api.VmUtils.Automations.triggers.canSetAutomationStepScheduleUsingNegativeNumberOfDays(trigger);
	if (addBefore) {
		options.push({
			dataContext: EScheduleAnchorOptions.Before,
			id: 'before',
			text: 'before',
		});
	}
	options = [
		...options,
		{
			dataContext: EScheduleAnchorOptions.After,
			id: 'after',
			text: 'after',
		},
	];
	if (
		trigger?._type !== Api.AutomationTriggerType.Meeting &&
		trigger?._type !== Api.AutomationTriggerType.EventRegistration
	) {
		options.push({
			dataContext: EScheduleAnchorOptions.SameDay,
			id: 'sameDay',
			text: 'same day as',
		});
	}
	return options;
};

const getAutomationStepScheduleAnchorQualifierSelectedOptions = (
	options: AutomationStepScheduleAnchorQualifierSelectOption[],
	schedule?: Api.IAutomationStepSchedule,
	trigger?: Api.IAutomationTrigger
) => {
	if (
		trigger?._type !== Api.AutomationTriggerType.Meeting &&
		trigger?._type !== Api.AutomationTriggerType.EventRegistration
	) {
		return options.filter(
			x =>
				x.dataContext ===
				(schedule?.numberOfDays < 0
					? EScheduleAnchorOptions.Before
					: schedule?.numberOfDays > 0
						? EScheduleAnchorOptions.After
						: EScheduleAnchorOptions.SameDay)
		)?.[0];
	}

	return options.filter(
		x =>
			x.dataContext ===
			((schedule?.numberOfDays || schedule?.numberOfHours) < 0
				? EScheduleAnchorOptions.Before
				: EScheduleAnchorOptions.After)
	)?.[0];
};

const getAutomationStepScheduleAnchorDaysHoursSelectOptions = (
	options: TDaysHoursOptions[],
	schedule?: Api.IAutomationStepSchedule
) => {
	return options.filter(
		x => x.dataContext === (schedule?.numberOfDays !== 0 ? EHourDaysTypes.Days : EHourDaysTypes.Hours)
	)?.[0];
};

const getDayOfInputValueForNumberOfDays = (numberOfDays: number, defaultValue = 1) => {
	return (isNaN(numberOfDays) ? defaultValue : numberOfDays).toString();
};

const AutomationScheduleTimeOfDayInput = (props: IAutomationStepCardScheduleProps) => {
	const { schedule, onRequestSetSchedule, canEdit } = props;
	const minutes = React.useRef(schedule?.numberOfMinutes || 0).current;
	const scheduleOptions = React.useRef<ISelectBoxOption<number>[]>(
		Array(6)
			.fill(null)
			.map<ISelectBoxOption<number>>((_, i) => {
				const date = new Date();
				date.setHours(7 + i, minutes);
				return {
					isDefault: false,
					title: getDefaultTimeStringValue(date),
					value: 7 + i,
				};
			})
			.concat(
				Array(5)
					.fill(null)
					.map<ISelectBoxOption<number>>((_, i) => {
						const date = new Date();
						date.setHours(13 + i, minutes);
						return {
							isDefault: false,
							title: getDefaultTimeStringValue(date),
							value: 13 + i,
						};
					})
			)
	).current;
	const onTimeOptionSelected = React.useCallback(
		(option: ISelectBoxOption<number>) => {
			onRequestSetSchedule({
				...(schedule || {}),
				numberOfHours: option.value,
				numberOfMinutes: minutes,
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onRequestSetSchedule, schedule]
	);
	const selectedTime = !isNaN(schedule?.numberOfHours)
		? scheduleOptions.find(x => x.value === schedule.numberOfHours)
		: undefined;

	return (
		<DefaultSelectBox
			className={css(styleSheet.timeDropdown)}
			disabled={!canEdit}
			menuPopupPosition='top'
			onSelectionChanged={onTimeOptionSelected}
			options={scheduleOptions}
			optionStyles={[styleSheet.timeDropdownOption]}
			selectedOption={selectedTime}
			triggerStyles={[styleSheet.timeDropdownTrigger]}
		/>
	);
};

const daysHoursOptions: TDaysHoursOptions[] = [
	{
		dataContext: EHourDaysTypes.Days,
		id: 'days',
		text: 'day(s)',
	},
	{
		dataContext: EHourDaysTypes.Hours,
		id: 'hours',
		text: 'hr(s)',
	},
];

const DefaultAutomationStepScheduler = (
	props: IAutomationStepCardScheduleProps & { whenText?: string; selectedDueDateOption?: 'day' | 'none' }
) => {
	const { trigger, schedule, onRequestSetSchedule, selectedDueDateOption, stepType, canEdit, whenText } = props;
	const isMeetingAutomation = trigger?._type === Api.AutomationTriggerType.Meeting;
	const isEventBaseAutomation =
		trigger?._type === Api.AutomationTriggerType.Meeting ||
		trigger?._type === Api.AutomationTriggerType.EventRegistration;

	const [selectedDaysHoursOption, setSelectedDaysHoursOption] = React.useState<TDaysHoursOptions>(
		getAutomationStepScheduleAnchorDaysHoursSelectOptions(daysHoursOptions, schedule)
	);

	React.useEffect(() => {
		if (schedule?.numberOfDays !== 0 && selectedDueDateOption === 'day') {
			setSelectedQualifierOption(getAutomationStepScheduleAnchorQualifierSelectedOptions(options, schedule, trigger));
			if (isEventBaseAutomation) {
				setSelectedDaysHoursOption(getAutomationStepScheduleAnchorDaysHoursSelectOptions(daysHoursOptions, schedule));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDueDateOption]);
	// #region AutomationScheduleDayOfInput callbacks and state
	const [options, setOptions] = React.useState<AutomationStepScheduleAnchorQualifierSelectOption[]>(
		getAutomationStepScheduleAnchorQualifierSelectOptions(trigger)
	);
	const [selectedQualifierOption, setSelectedQualifierOption] =
		React.useState<AutomationStepScheduleAnchorQualifierSelectOption>(
			getAutomationStepScheduleAnchorQualifierSelectedOptions(options, schedule, trigger)
		);

	React.useEffect(() => {
		const nextOptions = getAutomationStepScheduleAnchorQualifierSelectOptions(trigger);
		if (
			!equal(
				nextOptions.map(x => x.id),
				options.map(x => x.id)
			)
		) {
			ReactDOM.unstable_batchedUpdates(() => {
				setOptions(nextOptions);
				setSelectedQualifierOption(
					getAutomationStepScheduleAnchorQualifierSelectedOptions(nextOptions, schedule, trigger)
				);
			});
		}
	}, [trigger, schedule, options]);
	// #endregion

	const onQualifierOptionSelected = (option: AutomationStepScheduleAnchorQualifierSelectOption) => {
		setSelectedQualifierOption(option);
		const nextSchedule: Api.IAutomationStepSchedule = {
			...(schedule || {}),
		};
		if (isEventBaseAutomation) {
			const days = Math.abs(schedule?.numberOfDays);
			const hours = Math.abs(schedule?.numberOfHours);
			if (selectedDaysHoursOption.dataContext === EHourDaysTypes.Days) {
				const num = days || hours;
				nextSchedule.numberOfDays = getValueFromContext(option.dataContext, num);
			}
			if (selectedDaysHoursOption.dataContext === EHourDaysTypes.Hours) {
				const num = hours || days;
				nextSchedule.numberOfHours = getValueFromContext(option.dataContext, num);
			}

			if (option.dataContext === EScheduleAnchorOptions.Before && days === 0 && hours === 0) {
				nextSchedule.numberOfDays = -1;
				nextSchedule.numberOfHours = 0;
			} else if (option.dataContext === EScheduleAnchorOptions.After && days === 0 && hours === 0) {
				nextSchedule.numberOfDays = 1;
				nextSchedule.numberOfHours = 0;
			}
			onRequestSetSchedule?.(nextSchedule);
			return;
		}
		nextSchedule.numberOfDays =
			option.dataContext === EScheduleAnchorOptions.SameDay
				? 0
				: (option.dataContext === EScheduleAnchorOptions.Before ? -1 : 1) *
					(schedule?.numberOfDays === 0 ? 1 : Math.abs(schedule?.numberOfDays));
		onRequestSetSchedule?.(nextSchedule);
	};

	const numberOfDays = schedule?.numberOfDays || 0;
	const numberOfHours = schedule?.numberOfHours || 0;
	const triggerDescription = getTriggerDescription(trigger);
	const showNumberInput =
		numberOfDays !== 0 ||
		(numberOfHours !== 0 && isEventBaseAutomation) ||
		selectedQualifierOption.dataContext === EScheduleAnchorOptions.After ||
		selectedQualifierOption.dataContext === EScheduleAnchorOptions.Before;

	const handleOptionSelection = React.useCallback(
		(value: TDaysHoursOptions) => {
			setSelectedDaysHoursOption(value);
			const nextSchedule: Api.IAutomationStepSchedule = { ...(schedule || {}) };
			const days = Math.abs(schedule?.numberOfDays);
			const hours = Math.abs(schedule?.numberOfHours);
			if (value.dataContext === EHourDaysTypes.Days) {
				const num = days || hours;
				nextSchedule.numberOfDays = getValueFromContext(selectedQualifierOption.dataContext, num);
				nextSchedule.numberOfHours = 0;
			}
			if (value.dataContext === EHourDaysTypes.Hours) {
				const num = hours || days;
				nextSchedule.numberOfDays = 0;
				nextSchedule.numberOfHours = getValueFromContext(selectedQualifierOption.dataContext, num);
			}
			onRequestSetSchedule?.(nextSchedule);
		},
		[schedule, onRequestSetSchedule, selectedQualifierOption]
	);
	const daysTextOrDaysHoursSelect = isEventBaseAutomation ? (
		<DaysHoursSelect
			canEdit={canEdit}
			options={daysHoursOptions}
			selectedOption={selectedDaysHoursOption}
			onSelectedOption={handleOptionSelection}
		/>
	) : (
		<div> {` day${Math.abs(numberOfDays) > 1 || Math.abs(numberOfDays) === 0 ? 's ' : ''} `} </div>
	);

	return (
		<div className={css(styleSheet.scheduler)}>
			<div className={css(styleSheet.schedulerRow)}>
				<span className={css(baseStyleSheet.flexShrink0)}>{whenText || 'This step happens'}</span>{' '}
				{showNumberInput && (
					<>
						<AutomationScheduleDayOfInput
							{...props}
							trigger={trigger}
							selectedDueDateOption={selectedDueDateOption}
							initialValue={numberOfDays || 1}
							isEventBaseAutomation={isEventBaseAutomation}
							selectedDaysHoursOption={selectedDaysHoursOption}
							selectedQualifierOption={selectedQualifierOption}
						/>
						{daysTextOrDaysHoursSelect}
					</>
				)}
				<Select
					disabled={!canEdit}
					onOptionClick={onQualifierOptionSelected}
					options={options}
					selectedOption={selectedQualifierOption}
					styles={[styleSheet.schedulerQualifierSelect]}
				/>
				{!!triggerDescription && (
					<span className={css(baseStyleSheet.flexShrink0)}>
						{' '}
						<span className={css(baseStyleSheet.flexShrink0)}>{`the ${triggerDescription}`}</span>
					</span>
				)}
			</div>
			{isMeetingAutomation && selectedQualifierOption.dataContext === EScheduleAnchorOptions.After && (
				<p className={`${css(styleSheet.noMargin)}`}>
					<strong>Pro Tip: </strong> Allow enough time after the meeting so you can cancel this automation if the
					recipient doesn&apos;t show up.
				</p>
			)}
			{!isEventBaseAutomation && (
				<>
					{stepTypesSupportingTimeOfDayInput.indexOf(stepType) >= 0 && (
						<div className={css(styleSheet.schedulerRow)}>
							at <AutomationScheduleTimeOfDayInput {...props} />
						</div>
					)}
				</>
			)}
		</div>
	);
};

interface IDaysHoursProps {
	canEdit: boolean;
	options: TDaysHoursOptions[];
	selectedOption: TDaysHoursOptions;
	onSelectedOption: (option: ISelectOption) => void;
}

const DaysHoursSelect: React.FC<IDaysHoursProps> = ({ canEdit, options, selectedOption, onSelectedOption }) => {
	const qualifierSelectStyles = React.useRef([styleSheet.hoursDaysSelect]).current;
	const onOptionSelect = (value: TDaysHoursOptions) => {
		onSelectedOption(value);
	};

	return (
		<Select
			disabled={!canEdit}
			onOptionClick={onOptionSelect}
			options={options}
			selectedOption={selectedOption}
			styles={qualifierSelectStyles}
		/>
	);
};

const getValueFromContext = (context: EScheduleAnchorOptions, num: number) => {
	return (context === EScheduleAnchorOptions.Before ? -1 : 1) * Math.abs(num);
};

const AutomationScheduleDayOfInput: React.FC<
	IAutomationStepCardScheduleProps & {
		initialValue: number;
		isEventBaseAutomation: boolean;
		selectedDaysHoursOption?: TDaysHoursOptions;
		selectedQualifierOption?: AutomationStepScheduleAnchorQualifierSelectOption;
		selectedDueDateOption?: 'day' | 'none';
	}
> = ({
	canEdit,
	initialValue,
	isEventBaseAutomation,
	onRequestSetSchedule,
	trigger,
	schedule,
	step,
	selectedDaysHoursOption,
	selectedQualifierOption,
	selectedDueDateOption,
}) => {
	const [dayOfAutomationInputValue, setDayOfAutomationInputValue] = React.useState<string>(
		getDayOfInputValueForNumberOfDays(schedule?.numberOfDays || schedule?.numberOfHours || initialValue)
	);

	React.useEffect(() => {
		if (schedule?.numberOfDays !== 0 && selectedDueDateOption === 'day') {
			setDayOfAutomationInputValue(getDayOfInputValueForNumberOfDays(schedule?.numberOfDays));
		} else if (
			!schedule?.numberOfDays &&
			selectedDueDateOption === 'none' &&
			isCustomResourceSelector(trigger?.resourceSelector)
		) {
			setDayOfAutomationInputValue(getDayOfInputValueForNumberOfDays(0));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDueDateOption]);
	const setScheduleNumberOfDays = (numberOfDays: number) => () => {
		if (isEventBaseAutomation) {
			const nextSchedule: Api.IAutomationStepSchedule = {
				...(schedule || {}),
			};
			if (selectedDaysHoursOption.dataContext === EHourDaysTypes.Days) {
				nextSchedule.numberOfDays = getValueFromContext(selectedQualifierOption.dataContext, numberOfDays);
				nextSchedule.numberOfHours = 0;
			}
			if (selectedDaysHoursOption.dataContext === EHourDaysTypes.Hours) {
				nextSchedule.numberOfDays = 0;
				nextSchedule.numberOfHours = getValueFromContext(selectedQualifierOption.dataContext, numberOfDays);
			}
			onRequestSetSchedule?.(nextSchedule);
			return;
		}

		onRequestSetSchedule?.({
			...(schedule || {}),
			numberOfDays,
		});
	};

	const onDayOfAutomationInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		let value = parseInt(e.target.value, 10);
		if (!value || isNaN(value)) {
			value = 1;
		}
		setScheduleNumberOfDays((!isNaN(schedule?.numberOfDays) && schedule?.numberOfDays >= 0 ? 1 : -1) * value)();
		setDayOfAutomationInputValue('' + value);
	};

	const onDayOfInputFocus = React.useCallback((e: React.FocusEvent<HTMLInputElement>) => {
		const inputElement = e.target as HTMLInputElement;
		if (Browser.isMSEdge()) {
			setTimeout(() => {
				inputElement?.select();
			}, 0);
		} else {
			inputElement.select();
		}
	}, []);

	const onDayOfAutomationChanged = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setDayOfAutomationInputValue(e.target.value);
	}, []);

	const days = Math.abs(parseInt(dayOfAutomationInputValue, 10));
	return (
		<TextInput
			className={css(baseStyleSheet.removeChildInputSpinButton, styleSheet.dayOfTextInput)}
			disabled={!canEdit}
			inputClassName={css(styleSheet.dayOfInput)}
			inputId={`dayof-automation-input-${step.uuid}`}
			min={1}
			onBlur={onDayOfAutomationInputBlur}
			onChange={onDayOfAutomationChanged}
			onFocus={onDayOfInputFocus}
			step={1}
			type='number'
			value={isNaN(days) ? '' : days}
		/>
	);
};

const ActionItemAutomationScheduler: React.FC<
	IAutomationStepCardScheduleProps & {
		optionsStyles?: StyleDeclarationValue[];
		optionStyles?: StyleDeclarationValue[];
		onRenderDueDateOption?(disabled?: boolean): React.ReactNode;
	}
> = props => {
	const { schedule, canEdit, step, onRequestSetSchedule, optionsStyles = [], optionStyles = [], trigger } = props;

	const isEventBased =
		trigger?._type === Api.AutomationTriggerType.Meeting ||
		trigger?._type === Api.AutomationTriggerType.EventRegistration;
	const [selectedDueDateOption, setSelectedDueDateOption] = React.useState<'day' | 'none'>(
		schedule?.numberOfDays ? 'day' : 'none'
	);
	const onDueDateOptionChanged =
		(_selectedDueDateOption: 'day' | 'none') => (e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked) {
				let options: Api.IAutomationStepSchedule | null = null;
				setSelectedDueDateOption(_selectedDueDateOption);
				if (_selectedDueDateOption === 'day') {
					options = {
						numberOfDays: 0,
					};
					if (isEventBased) {
						options = {
							criteria: Api.AutomationStepScheduleCriteria.RelativeToAnchor,
							numberOfDays: -1,
						};
					}
					if (Api.VmUtils.Automations.triggers.triggerUsesRelativeToAnchorScheduleCriteria(trigger)) {
						options = {
							criteria: Api.AutomationStepScheduleCriteria.RelativeToAnchor,
							numberOfDays: -30,
						};
					}

					onRequestSetSchedule?.({ ...options });
					return;
				}
				onRequestSetSchedule?.(options);
			}
		};

	const canEditDaysOf = !!schedule;
	return (
		<>
			<p className={css(styleSheet.noMargin)}>
				Due date for action item (<em>this can be adjusted later</em>
				):
			</p>
			<div className={css(styleSheet.actionItemDueDateOptions, ...optionsStyles)}>
				<RadioButton
					checked={canEditDaysOf}
					className={css(...optionStyles)}
					disabled={!canEdit}
					id={`automation-action-item-step-due-date-input-${step.uuid}`}
					name={`automation-action-item-step-due-date-${step.uuid}`}
					onChange={onDueDateOptionChanged('day')}
				>
					<div className={css(styleSheet.actionItemDueDateOption)}>
						<DefaultAutomationStepScheduler
							selectedDueDateOption={selectedDueDateOption}
							{...props}
							canEdit={canEditDaysOf && canEdit}
							whenText='Due'
						/>
					</div>
				</RadioButton>
				<RadioButton
					checked={!canEditDaysOf}
					className={css(...optionStyles)}
					disabled={!canEdit}
					id={`automation-action-item-step-due-date-none-${step.uuid}`}
					name={`automation-action-item-step-due-date-${step.uuid}`}
					onChange={onDueDateOptionChanged('none')}
				>
					<span>No due date</span>
				</RadioButton>
			</div>
		</>
	);
};

const AutomationStepCardScheduleDayOf: React.FC<IAutomationStepCardScheduleProps> = props => {
	const { schedule, canEdit, stepType, onRequestSetSchedule, trigger, children, isBusy } = props;

	const isEventBased =
		trigger?._type === Api.AutomationTriggerType.Meeting ||
		trigger?._type === Api.AutomationTriggerType.EventRegistration;
	const setSchedule = React.useCallback(
		(scheduleToSet: Api.IAutomationStepSchedule) => () => {
			// Below is no longer applicable as we are allowing immediately for all steps
			// note: we hard code an index > 0 when not explicitly setting criteria
			// in order to prevent "getDefaultScheduleForAutomationStep" from returning Api.AutomationStepScheduleCriteria.Immediately
			const defaultSchedule = Api.VmUtils.Automations.steps.getDefaultScheduleForAutomationStep(stepType, trigger);
			onRequestSetSchedule?.({
				...(schedule || {}),
				...defaultSchedule,
				...(scheduleToSet || {}),
			});
		},
		[schedule, onRequestSetSchedule, trigger, stepType]
	);

	if (
		Api.VmUtils.Automations.triggers.triggerUsesRelativeToAnchorScheduleCriteria(trigger) ||
		!Api.VmUtils.Automations.steps.supportsImmediatelyCriteriaForFirstStepInAutomation(stepType)
	) {
		return <div>{children}</div>;
	}

	return (
		<div>
			<div className={css(styleSheet.dayOfHorizontalLayout)}>
				{!schedule || schedule?.criteria === Api.AutomationStepScheduleCriteria.Immediately ? (
					<span>
						<span>This step should happen immediately.</span>
						&nbsp;
						{canEdit ? (
							<button
								className={css(baseStyleSheet.brandLink)}
								onClick={setSchedule({ numberOfDays: isEventBased ? -1 : 0 })}
							>
								<span>Change</span>
							</button>
						) : null}
					</span>
				) : (
					children
				)}
			</div>
			{!!schedule && schedule.criteria !== Api.AutomationStepScheduleCriteria.Immediately && (
				<div className={css(styleSheet.dayOfDetails, styleSheet.dayOfDetailsRightAlign)}>
					<button
						className={css(baseStyleSheet.brandLink)}
						onClick={setSchedule({
							criteria: Api.AutomationStepScheduleCriteria.Immediately,
							numberOfDays: 0,
							numberOfHours: 0,
							numberOfMinutes: 0,
						})}
						disabled={isBusy}
					>
						<span className={css(styleSheet.dayOfDetailsText)}>
							{isBusy ? <LoadingSpinner type='tiny' /> : undefined} Change to &quot;Immediately&quot;
						</span>
					</button>
				</div>
			)}
		</div>
	);
};

export const AdvancedOptionsButton = ({ isCollapsed, onClick }: { isCollapsed: boolean; onClick: () => void }) => {
	return (
		<button className={css(baseStyleSheet.brandLink, styleSheet.advancedOptionsButton)} onClick={onClick}>
			<span>Advanced Options</span>
			<span className={css(styleSheet.advancedOptionsChevron)}>
				<DisclosureIcon
					type='chevron'
					className={css(
						styleSheet.advancedOptionsChevronIcon,
						!isCollapsed && styleSheet.advancedOptionsChevronIconFlipped
					)}
					fillColor={brandPrimary}
				/>
			</span>
		</button>
	);
};

export const AutomationStepCardSchedule: React.FC<IAutomationStepCardScheduleProps> = props => {
	const { stepType } = props;
	let scheduler: React.ReactNode;

	if (!scheduler) {
		switch (stepType) {
			case Api.AutomationStepType.ActionItem: {
				scheduler = <ActionItemAutomationScheduler {...props} />;
				break;
			}
			default: {
				scheduler = <DefaultAutomationStepScheduler {...props} />;
				break;
			}
		}
	}

	return <AutomationStepCardScheduleDayOf {...props}>{scheduler}</AutomationStepCardScheduleDayOf>;
};

type IAutomationStepCardInputFieldErrorProps = Partial<IAutomationStepCardSettingsProps>;

export const AutomationStepCardInputFieldError: React.FC<IAutomationStepCardInputFieldErrorProps> = observer(
	({ step, children }) => {
		let errorMessage: string = null;
		if (step?.showRequiredFieldError && !step?.hasAllRequiredInfo) {
			switch (step.type) {
				case Api.AutomationStepType.Texting: {
					errorMessage = 'Please provide texting content.';
					break;
				}
				case Api.AutomationStepType.Email: {
					errorMessage = 'Please provide email content and description/name.';
					break;
				}
				case Api.AutomationStepType.ActionItem: {
					errorMessage = 'Please provide action item content.';
					break;
				}
				case Api.AutomationStepType.RemoveTag:
				case Api.AutomationStepType.AddTag: {
					errorMessage = 'Please provide a tag name.';
					break;
				}
				case Api.AutomationStepType.HandwrittenCard: {
					errorMessage = 'Please select a card template and fill out card content.';
					break;
				}
				default: {
					break;
				}
			}
		}
		return <InputFieldError errorMessage={errorMessage}>{children}</InputFieldError>;
	}
);

interface IAutomationStepCardSettingsProps<T extends Api.AutomationStepViewModel = Api.AutomationStepViewModel> {
	index: number;
	onRequestClearErrorMessages?(): void;
	onRequestSave?(
		automationStep?: Api.IAutomationStep,
		schedule?: Api.IAutomationStepSchedule,
		loggingEventParams?: Api.IDictionary<string | number | boolean>
	): Promise<any>;
	readOnly?: boolean;
	step?: T;
	trigger?: Api.IAutomationTrigger;
	schedule?: Api.IAutomationStepSchedule;
}

type AutomationStepCardTextingInputProps = IAutomationStepCardSettingsProps<Api.TextingAutomationStepViewModel>;

export const AutomationStepCardTextingInput: React.FC<AutomationStepCardTextingInputProps> = observer(props => {
	const userSession = useUserSession();
	const { onRequestSave, readOnly, onRequestClearErrorMessages, step, trigger } = props;
	const [contentEditorState, setContentEditorState] = React.useState<Api.IRichContentEditorState>(
		convertRawRichTextContentStateToRichContentEditorState(step?.content)
	);
	const attachmentsToBeSent = React.useRef<Api.AttachmentsToBeUploadedViewModel<File>>(
		new Api.AttachmentsToBeUploadedViewModel(null, Api.TextMessagingMaxFileByteSize)
	).current;
	const contentEditorRef = React.useRef<Editor>(null);
	const { logEvent, logApiError } = useEventLogging('AutomationStepCardTextingInput');
	const errorMessages = useErrorMessages();
	const onEditorLoaded = React.useCallback(
		(editor?: Editor) => {
			contentEditorRef.current = editor;
		},
		[contentEditorRef]
	);
	const [showingSizeLimitWarning, setShowingSizeLimitWarning] = React.useState(false);

	const getModel = React.useCallback(() => {
		const stepModel: Partial<Api.ITextingAutomationStep> = {
			...(step?.toJs() || {}),
			attachments: attachmentsToBeSent.attachments as unknown as Api.IFileAttachment[],
			content: contentEditorState?.getRawRichTextContent(),
			name: 'Texting Step',
		};
		return stepModel;
	}, [contentEditorState, step, attachmentsToBeSent]);

	const save = React.useCallback(
		(stepToSave?: Partial<Api.ITextingAutomationStep>) => {
			if (step && onRequestSave) {
				const stepModel = stepToSave || getModel();
				const eventParams: any = {};
				eventParams.nameLength = stepModel.name?.length;
				eventParams.hasContent = contentEditorState?.hasContent();
				return onRequestSave(stepModel, undefined, eventParams);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onRequestSave, contentEditorState, getModel]
	);

	const onFilesInputChanged = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.currentTarget.files[0];
		if (!file) {
			return; // cancelled the file picker
		}

		if (file) {
			let fileSize = 0;
			step.attachments.forEach(x => {
				const updateSize = fileSize;
				fileSize = x.fileSize + updateSize;
			});
			if (file.size + fileSize > Api.TextMessagingMaxFileByteSize) {
				setShowingSizeLimitWarning(true);
				return;
			}
			try {
				attachmentsToBeSent.remove(attachmentsToBeSent.attachments);
				// Removes any attachments that are still in current ref
				attachmentsToBeSent.add([file]);
				step.addAttachments(attachmentsToBeSent);
				getModel();
			} catch (err) {
				logApiError('LocalImageUpload-Error', err);
				errorMessages.pushApiError(err);
			}
		}
	};

	// #region texting toolbar
	const onEmojiClicked = React.useCallback(
		async (_: React.MouseEvent<Element, MouseEvent>, data: IEmojiData) => {
			if (contentEditorRef.current) {
				contentEditorRef.current.insertContent(data.emoji);

				// get content and save because editor does not trigger on change event after insert
				const contentHtmlStringValue = contentEditorRef.current.getContent() as string;
				try {
					const model = {
						...getModel(),
						content: Api.createRawRichTextContentStateWithHtmlStringValue(contentHtmlStringValue),
					};
					await save(model);
					setContentEditorState(convertRawRichTextContentStateToRichContentEditorState(model.content));
				} catch (err) {
					logApiError('InsertEmojiAndSave-Error', err);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getModel, save]
	);

	const onRemoveImage = (fileId: string) => () => {
		attachmentsToBeSent.remove(attachmentsToBeSent.attachments);
		step?.removeAttachments(fileId, step?.id);
	};

	// #endregion

	// #region texting content
	React.useEffect(() => {
		setContentEditorState(convertRawRichTextContentStateToRichContentEditorState(step?.content));
	}, [step]);

	const onTextingContentEditorStateChanged = React.useCallback(
		(nextContent: Api.IRichContentEditorState) => {
			onRequestClearErrorMessages?.();
			setContentEditorState(nextContent);
		},
		[onRequestClearErrorMessages]
	);
	// #endregion

	const onCloseSizeWarning = () => {
		setShowingSizeLimitWarning(false);
	};

	const fallbackEmailAutomationStep = step?.conditionalSteps?.[
		Api.AutomationStepOutcome.Error
	] as Api.EmailAutomationStepViewModel;
	const [showingEmailFallbackComposer, setShowingEmailFallbackComposer] = React.useState<boolean>(false);
	const onShowEmailFallbackComposerClicked = () => {
		setShowingEmailFallbackComposer(true);
		onRequestClearErrorMessages?.();
	};
	const onEmailFallbackComposerModalRequestClose = (data?: Api.IEmailAutomationStep) => {
		if (data) {
			fallbackEmailAutomationStep.mSetModel(data);
		}
		setShowingEmailFallbackComposer(false);
	};
	React.useEffect(() => {
		if (fallbackEmailAutomationStep) {
			fallbackEmailAutomationStep.showRequiredFieldError = step?.showRequiredFieldError;
		}
	}, [step?.showRequiredFieldError, fallbackEmailAutomationStep]);

	const onFallbackToEmailCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			const emailStep: Api.IEmailAutomationStep = {
				_type: 'EmailAutomationStep',
				name: 'Email Fallback Step',
				options: {},
				schedule: {
					criteria: Api.AutomationStepScheduleCriteria.Conditional,
				},
			};
			logEvent('AddConditionalStep', {
				outcome: Api.AutomationStepOutcome.Error,
				parentStepId: step?.id,
				type: emailStep._type,
			});
			step?.addConditionalStep(emailStep, Api.AutomationStepOutcome.Error);
		} else {
			logEvent('RemoveConditionalStep', {
				parentStepId: step?.id,
				stepId: fallbackEmailAutomationStep?.id,
				type: fallbackEmailAutomationStep?.toJs?.()._type,
			});
			step?.removeConditionalStep(fallbackEmailAutomationStep);
		}
	};

	return (
		<>
			<AutomationStepCardInputFieldError step={step}>
				{step.attachments?.length > 0 && (
					<div className={css(styleSheet.imageAreaContainer)}>
						{step.attachments.map((file: Api.IFileAttachment, i) => (
							<div className={css(styleSheet.previewContainer)} key={i}>
								<div className={css(styleSheet.imageArea)}>
									<img className={css(styleSheet.image)} src={file?.url} alt={file?.fileName} />
									<DeprecatedCloseButton
										className={css(styleSheet.closeButton)}
										fillColor={oldSilver}
										onClick={onRemoveImage(file.id)}
										opacity={0.9}
										outlineColor='#FFF'
										outlineWidth={1.5}
										widthAndHeight={16}
										xMark={true}
									/>
								</div>
							</div>
						))}
					</div>
				)}
				<TextCampaignEditor
					className={css(styleSheet.textInputArea)}
					contentState={contentEditorState}
					onBlur={() => save()}
					onContentStateChanged={onTextingContentEditorStateChanged}
					onFocus={onRequestClearErrorMessages}
					onLoad={onEditorLoaded}
					readOnly={readOnly}
					styles={
						step?.attachments?.length ? [styleSheet.textingContentEditorImages] : [styleSheet.textingContentEditor]
					}
				/>
			</AutomationStepCardInputFieldError>
			<div className={css(baseStyleSheet.horizontalStack, styleSheet.textingAddOnContainer)}>
				<EmojiPickerButton onEmojiClick={onEmojiClicked} />
				<AttachInputButton multiple={true} onChange={onFilesInputChanged} type='file' />
				<span className={css(styleSheet.textingAddOnText)}>Add an emoji or attach a file under 4.5MB</span>
			</div>
			{trigger?._type === 'TextingCampaignAutomationTrigger' && (
				<div className={css(styleSheet.textingCanSpam)}>
					<div className={css(styleSheet.textingCanSpamBubble)}>
						{'If you\'d prefer not to hear from me by text message, please reply back with "Stop".'}
					</div>
					<div className={css(styleSheet.textingCanSpamBubbleDetails)}>*Required by CAN-SPAM</div>
				</div>
			)}
			<Checkbox
				checked={!!fallbackEmailAutomationStep}
				className={css(styleSheet.textingFallbackCheckbox)}
				id={`texting-step-send-email-instead-${step?.uuid}`}
				onChange={onFallbackToEmailCheckboxChanged}
			>
				<span>If contact does not have a mobile number, send email instead.</span>
			</Checkbox>
			<div className={css(baseStyleSheet.verticalStack, styleSheet.textingFallbackSettings)}>
				<div>Enter backup email content:</div>
				<AutomationStepCardInputFieldError step={fallbackEmailAutomationStep}>
					<button
						className={css(
							styleSheet.emailCta,
							!fallbackEmailAutomationStep?.content ? styleSheet.emailCreateButton : styleSheet.emailEditButton
						)}
						disabled={!fallbackEmailAutomationStep}
						onClick={onShowEmailFallbackComposerClicked}
					>
						<span>{`${fallbackEmailAutomationStep?.content ? 'Preview / Edit' : 'Create'} Email`}</span>
					</button>
				</AutomationStepCardInputFieldError>
				{showingEmailFallbackComposer ? (
					<EditAutomationStepEmailComposerModal
						automationTemplateId={step.templateId}
						step={fallbackEmailAutomationStep.model}
						onRequestClose={onEmailFallbackComposerModalRequestClose}
						onAttachmentRemoved={data => {
							fallbackEmailAutomationStep.mSetModel(data);
						}}
						readonly={readOnly && !fallbackEmailAutomationStep.canEdit}
						syncSubjectWithStep
						impersonationContext={step.impersonationContext}
						defaultInitialContent={Api.createRawRichTextContentStateWithText(
							getRichContentMessageForTextingStep(userSession, trigger, step.schedule)
						)}
					/>
				) : null}
			</div>
			<ConfirmationDialog
				icon={<WarningIcon />}
				modalProps={{
					isOpen: showingSizeLimitWarning,
					onRequestClose: onCloseSizeWarning,
				}}
				title='Max File Size Exceeded'
			>
				<p className={css(styleSheet.sizeWarning)}>Reduce your total file size below 4.5MB and resend.</p>
			</ConfirmationDialog>
		</>
	);
});
