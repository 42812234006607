import { StyleDeclarationValue, css } from 'aphrodite';
import { IReactComponent, inject, observer } from 'mobx-react';
import * as React from 'react';
import EmailEditor, { Design, HtmlExport, SimpleMergeTag, UnlayerOptions } from 'react-email-editor';
import { useHistory, useLocation } from 'react-router-dom';
import { ICreateCampaignRequest, ILocationState, TemplateOrTemplateFilter } from '../../../models';
import { IEditHtmlNewsletterRequest } from '../../../models/AdminModels';
import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	IToasterComponentProps,
	ToasterViewModelKey,
} from '../../../models/AppState';
import { isIE11 } from '../../../models/Browser';
import { useEventLogging } from '../../../models/Logging';
import {
	DefaultContactPlaceholders,
	SenderEmailPlaceholder,
	SenderFirstNamePlaceholder,
	SenderFullNamePlaceholder,
	SenderPhonePlaceholder,
	Token,
} from '../../../models/Token';
import { useEnvironment, useFullscreenModal } from '../../../models/hooks/appStateHooks';
import {
	ContentSourceFormat,
	ITemplate,
	TemplateScope,
	asApiError,
	selectKeysOf,
} from '../../../viewmodels/AppViewModels';
import { HtmlNewsletter, HtmlNewsletterViewModel } from '../../../viewmodels/HtmlNewletterViewModels';
import { IModalProps } from '../Modal';
import { ShareTemplateCheckbox } from '../ShareTemplateCheckbox';
import { ShareScope } from '../ShareTemplateCheckbox/ShareTemplateCheckbox';
import { TextInputModal } from '../TextInputModal';
import CustomCssUrl from './customCss.unlayer.css';
import CustomJsUrl from './customJs.unlayer.js';
import * as Presentation from './presentation';
import { styleSheet } from './styles';

interface IProps extends IErrorMessageComponentProps, IFullscreenModalComponentProps, IToasterComponentProps {
	className?: string;
	newsletter: HtmlNewsletter;
	onRef?(ref?: IHtmlNewsletterEditorComponent): void;
	styles?: StyleDeclarationValue[];
	isEditModal?: boolean;
}

export type ModalBehaviorProps = {
	open: boolean;
	onSuccess?: () => void;
};

export interface IHtmlNewsletterEditorComponent {
	getContent(): Promise<HtmlExport>;
}

const defaultTemplateName = 'Untitled Newsletter';

interface IHtmlNewsletterEditor {
	Body: React.FC<Presentation.IHtmlNewsletterEditorBodyProps>;
	DefaultEditorOptions: UnlayerOptions;
	Header: React.FC<Presentation.IHtmlNewsletterEditorHeaderProps>;
	SaveAsNewButton: React.FC<
		{ styles?: StyleDeclarationValue[] } & React.DetailedHTMLProps<
			React.ButtonHTMLAttributes<HTMLButtonElement>,
			HTMLButtonElement
		>
	>;
	SaveButton: React.FC<
		{ styles?: StyleDeclarationValue[] } & React.DetailedHTMLProps<
			React.ButtonHTMLAttributes<HTMLButtonElement>,
			HTMLButtonElement
		>
	>;
	AutoSaveCheckbox: React.FC;
	SubjectInput: React.FC;
	SendButton: React.FC<
		{ styles?: StyleDeclarationValue[] } & React.DetailedHTMLProps<
			React.ButtonHTMLAttributes<HTMLButtonElement>,
			HTMLButtonElement
		>
	>;
}

const HtmlNewsletterEditorSFC: React.FC<IProps> = props => {
	const { className, styles, children, newsletter, errorMessages, toaster, onRef, isEditModal } = props;
	const history = useHistory();
	const editorRef = React.useRef<EmailEditor>(null);
	const location = useLocation();
	const fullscreen = useFullscreenModal();
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [subjectInput, setSubjectInput] = React.useState(newsletter.template.subject || newsletter.name);
	const logger = useEventLogging('HtmlNewsletterEditor');
	const environment = useEnvironment();
	const [shareScope, setShareScope] = React.useState<ShareScope>(() => {
		if (newsletter.template.scope) {
			return newsletter.template.scope === TemplateScope.User ? TemplateScope.User : TemplateScope.Account;
		}

		return isEditModal ? TemplateScope.Account : TemplateScope.User;
	});

	const onEditorLoaded = React.useCallback(
		(emailEditor?: EmailEditor) => {
			editorRef.current = emailEditor;
			if (!!emailEditor && isLoaded !== true) {
				setIsLoaded(true);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const reloadTemplate = React.useCallback(
		(savedTemplate: ITemplate) => {
			try {
				const newDesign: Design = JSON.parse(savedTemplate.content.source);
				editorRef.current?.editor.loadDesign(newDesign);
			} catch (error) {
				logger.logApiError('TemplateSave-Error', error);
			}
		},
		[logger]
	);

	const getCurrentTemplateRepresentation = React.useCallback(() => {
		return new Promise<ITemplate | null>(resolve => {
			const getBaseTemplate = () => {
				const result = selectKeysOf({ content: {}, name: defaultTemplateName, ...newsletter.template }, [
					'content',
					'templateType',
					'subject',
					'name',
					'id',
					'templateType',
					'scope',
				]) as PartiallyRequired<ITemplate, 'content'>;
				if (result.id === 'new') {
					delete result.id;
				}
				return result;
			};
			if (!editorRef.current) {
				if (!newsletter.canEdit) {
					return resolve(getBaseTemplate());
				}
				return resolve(null);
			}
			editorRef.current.editor.exportHtml(async exported => {
				const template = getBaseTemplate();
				// remove doctype line
				template.content.document = HtmlNewsletterViewModel.removeDoctypeFromHtmlStringValue(exported.html);
				template.content.source = JSON.stringify(exported.design);
				template.content.sourceFormat = ContentSourceFormat.UnlayerHtmlNewsletter;
				template.subject = subjectInput;
				resolve(template);
			});
		});
	}, [newsletter, editorRef, subjectInput]);

	const replaceHistoryAfterSavingNewTemplate = React.useCallback(
		(savedTemplate: ITemplate) => {
			const locationState: ILocationState<any, IEditHtmlNewsletterRequest> = location?.state ?? {};
			if (!locationState.model && environment.appType === 'admin') {
				return;
			}
			if (locationState.model?.impersonationContext?.account?.id) {
				fullscreen?.history?.replace({
					pathname: `/accounts/${locationState?.model?.impersonationContext?.account?.id}/htmlnewsletter/${savedTemplate.id}`,
					state: locationState,
				});
			} else {
				history.replace({
					pathname: `/campaigns/htmlnewsletter/${savedTemplate.id}`,
				});
			}
		},
		[environment.appType, fullscreen?.history, history, location?.state]
	);

	const onAutoSave = React.useCallback(async () => {
		try {
			const template = await getCurrentTemplateRepresentation();
			return await newsletter.save(template);
		} catch (err) {
			const apiError = asApiError(err);
			logger.logApiError('TemplateAutoSave-Error', apiError);
			errorMessages.pushApiError(apiError);
			return null;
		}
	}, [errorMessages, getCurrentTemplateRepresentation, logger, newsletter]);

	const onSaveClicked = React.useCallback(
		(showToast: boolean) => async () => {
			if (!newsletter.canEdit || isIE11()) {
				return newsletter.template;
			}
			try {
				const template = await getCurrentTemplateRepresentation();

				const savedTemplate = await newsletter.save(template);

				if (template) {
					if (template.name === defaultTemplateName) {
						setSaveModalBehavior({ open: true });
					} else if (showToast) {
						toaster?.push({
							message: 'Template Saved!',
							type: 'successMessage',
						});
					}
				} else {
					throw new Error('Cannot read content from editor.');
				}
				reloadTemplate(savedTemplate);

				return savedTemplate;
			} catch (err) {
				const apiError = asApiError(err);
				logger.logApiError('TemplateSave-Error', apiError);
				errorMessages.pushApiError(apiError);
				return null;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[newsletter, getCurrentTemplateRepresentation, editorRef, reloadTemplate]
	);

	const onSendClicked = React.useCallback(async () => {
		const savedTemplate = await onSaveClicked(false)();
		if (savedTemplate) {
			const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
				model: {
					context: savedTemplate,
					type: 'Template',
				},
			};
			props.fullscreenModal?.history?.push({
				pathname: '/email/campaigns/create/from-template',
				state: locationState,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onSaveClicked]);

	const onSendTestEmailClicked = React.useCallback(async () => {
		try {
			await onSaveClicked(false)();
			await newsletter.sendTestEmail();
			toaster?.push({
				message: 'A test email has been sent to your email address!',
				type: 'successMessage',
			});
		} catch (err) {
			const apiError = asApiError(err);
			logger.logApiError('SendTestEmail-Error', apiError);
			errorMessages.pushApiError(apiError);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newsletter, onSaveClicked]);

	const [editNameModalBehavior, setEditNameModalBehavior] = React.useState<ModalBehaviorProps>({ open: false });
	const [saveAsNewModalBehavior, setSaveAsNewModalBehavior] = React.useState<ModalBehaviorProps>({ open: false });
	const [saveModalBehavior, setSaveModalBehavior] = React.useState<ModalBehaviorProps>({ open: false });

	const onEditNameClicked = React.useCallback(() => {
		setEditNameModalBehavior({ open: true });
	}, []);

	const onSaveAsNewTemplateClicked = React.useCallback(() => {
		setSaveAsNewModalBehavior({ open: true });
	}, []);

	const onShowSaveAsModal = React.useCallback((onSuccess?: ModalBehaviorProps['onSuccess']) => {
		setSaveModalBehavior({ open: true, onSuccess });
	}, []);

	const [autoSaveEnabled, setAutoSaveEnabled] = React.useState(false);

	const toggleAutoSave = React.useCallback(
		(toggleState: boolean) => {
			if (toggleState && newsletter.name === defaultTemplateName) {
				return onShowSaveAsModal(() => {
					setAutoSaveEnabled(true);
				});
			}
			return setAutoSaveEnabled(toggleState);
		},
		[newsletter.name, onShowSaveAsModal]
	);

	const shouldSave = (name: string, newsletterName: string, cancel: boolean) => {
		const trimmedName = name.trim();
		return !!trimmedName && trimmedName !== newsletterName && !cancel;
	};

	const saveTemplate = React.useCallback(
		async (name: string, saveFunction: (template: ITemplate) => Promise<ITemplate>, onSuccess?: () => void) => {
			const templateToSave: ITemplate = {
				...(await getCurrentTemplateRepresentation()),
				name,
				scope: shareScope,
			};
			const savedTemplate = await saveFunction(templateToSave);
			reloadTemplate(savedTemplate);
			replaceHistoryAfterSavingNewTemplate(savedTemplate);
			onSuccess?.();
		},
		[getCurrentTemplateRepresentation, shareScope, reloadTemplate, replaceHistoryAfterSavingNewTemplate]
	);

	const editNameModalProps = React.useMemo<IModalProps<string>>(() => {
		return {
			isOpen: editNameModalBehavior.open,
			onRequestClose: async (name, cancel) => {
				if (shouldSave(name || '', newsletter.name, cancel)) {
					try {
						await newsletter.updateName(name);
						toaster?.push({
							message: 'Name Updated!',
							type: 'successMessage',
						});
					} catch (err) {
						const apiError = asApiError(err);
						logger.logApiError('EditName-Error', apiError);
						errorMessages.pushApiError(apiError);
					}
				}
				setEditNameModalBehavior({ open: false });
			},
			useDefaultHeader: true,
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editNameModalBehavior]);

	const saveTemplateModalProps = React.useMemo<IModalProps<string>>(() => {
		return {
			isOpen: saveModalBehavior.open,
			onRequestClose: async (name, cancel) => {
				if (shouldSave(name || '', newsletter.name, cancel)) {
					try {
						await saveTemplate(name, newsletter.save, saveModalBehavior.onSuccess);
						toaster?.push({
							message: 'Template Saved!',
							type: 'successMessage',
						});
					} catch (err) {
						logger.logApiError('SaveNewTemplate-Error', asApiError(err));
					}
					setSaveModalBehavior({ open: false });
				}
			},
			useDefaultHeader: true,
		};
	}, [logger, newsletter, saveModalBehavior, toaster, saveTemplate]);

	const saveAsNewTemplateModalProps = React.useMemo<IModalProps<string>>(() => {
		return {
			isOpen: saveAsNewModalBehavior.open,
			onRequestClose: async (name, cancel) => {
				if (shouldSave(name || '', newsletter.name, cancel)) {
					try {
						await saveTemplate(name, newsletter.saveAsNewTemplate, saveAsNewModalBehavior.onSuccess);
						toaster?.push({
							message: 'New Template Saved!',
							type: 'successMessage',
						});
					} catch (err) {
						logger.logApiError('SaveAsNewTemplate-Error', asApiError(err));
					}
				}
				setSaveAsNewModalBehavior({ open: false });
			},
			useDefaultHeader: true,
		};
	}, [logger, newsletter, saveAsNewModalBehavior, toaster, saveTemplate]);

	const renderEditTemplateNameInputLabel = () => {
		return <div className={css(styleSheet.editNameLabel)}>Template Name:</div>;
	};

	const externalRef = React.useMemo<IHtmlNewsletterEditorComponent>(() => {
		return {
			getContent: async () => {
				if (!editorRef.current) {
					return null;
				}
				return new Promise<HtmlExport>(resolve => {
					editorRef.current.editor.exportHtml(exported => {
						resolve({
							...exported,
							html: HtmlNewsletterViewModel.removeDoctypeFromHtmlStringValue(exported.html),
						});
					});
				});
			},
		};
	}, [editorRef]);
	React.useEffect(() => {
		onRef?.(externalRef);
		return () => {
			onRef?.(null);
		};
	}, [onRef, externalRef]);

	const headerContext: Presentation.IHtmlNewsletterEditorHeaderContext = React.useMemo(
		() => ({
			newsletter,
			onEditNameClicked,
			onSaveAsNewTemplateClicked,
			onSaveClicked: onSaveClicked(true),
			onAutoSave,
			onSendClicked,
			onSendTestEmailClicked,
			saveDisabled: isIE11(),
			autoSaveEnabled,
			toggleAutoSave,
			subjectInput,
			setSubjectInput,
		}),
		[
			newsletter,
			autoSaveEnabled,
			onAutoSave,
			onSaveClicked,
			onSendClicked,
			onEditNameClicked,
			onSaveAsNewTemplateClicked,
			onSendTestEmailClicked,
			toggleAutoSave,
			subjectInput,
			setSubjectInput,
		]
	);

	const bodyContext: Presentation.IHtmlNewsletterEditorBodyContext = React.useMemo(
		() => ({
			initialDesign: props.newsletter?.design,
			newsletter,
			onEditorLoaded,
		}),
		[newsletter, onEditorLoaded, props.newsletter.design]
	);

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} html-newsletter-editor ${className || ''}`}>
			<Presentation.HtmlNewsletterEditorHeaderContext.Provider value={headerContext}>
				<Presentation.HtmlNewsletterEditorBodyContext.Provider value={bodyContext}>
					{children}
				</Presentation.HtmlNewsletterEditorBodyContext.Provider>
			</Presentation.HtmlNewsletterEditorHeaderContext.Provider>
			<>
				<TextInputModal
					cta='Save'
					inputLabel={renderEditTemplateNameInputLabel()}
					modalProps={editNameModalProps}
					title='Rename Template'
				>
					<ShareTemplateCheckbox shareScope={shareScope} onScopeChange={setShareScope} />
				</TextInputModal>
				<TextInputModal
					cta='Save'
					inputLabel={renderEditTemplateNameInputLabel()}
					modalProps={saveAsNewTemplateModalProps}
					title='Save as New Template'
				>
					<ShareTemplateCheckbox shareScope={shareScope} onScopeChange={setShareScope} />
				</TextInputModal>
				<TextInputModal
					cta='Save'
					inputLabel={renderEditTemplateNameInputLabel()}
					modalProps={saveTemplateModalProps}
					title='Save Template'
				>
					<ShareTemplateCheckbox shareScope={shareScope} onScopeChange={setShareScope} />
				</TextInputModal>
			</>
		</div>
	);
};

const HtmlNewsletterEditorAsObserver: React.FC<IProps> = observer(HtmlNewsletterEditorSFC);
export const HtmlNewsletterEditor: Partial<IHtmlNewsletterEditor> & IReactComponent<IProps> = inject(
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	ToasterViewModelKey
)(HtmlNewsletterEditorAsObserver);

const DefaultEditorOptions: UnlayerOptions = {
	appearance: {
		panels: {
			tools: {
				dock: 'right',
			},
		},
		theme: 'light',
	},
	customCSS: [CustomCssUrl.replace('./', `${window.location.origin}/`)],
	customJS: [CustomJsUrl.replace('./', `${window.location.origin}/`)],
	features: {
		smartMergeTags: false,
		userUploads: false,
		stockImages: false,
	},
	mergeTags: {
		contact: {
			mergeTags: DefaultContactPlaceholders.reduce<Record<string, SimpleMergeTag>>((res, x) => {
				res[x.value] = {
					name: x.text
						.split(' ')
						.map(s => `${s.charAt(0).toLocaleUpperCase()}${s.substring(1)}`)
						.join(' '),
					value: Token.getPlaceholderHtmlStringValue(x),
				};
				return res;
			}, {}),
			name: 'Contact',
		},
		sender: {
			mergeTags: {
				[SenderFirstNamePlaceholder.value]: {
					name: 'First Name',
					value: Token.getPlaceholderHtmlStringValue(SenderFirstNamePlaceholder),
				},
				[SenderFullNamePlaceholder.value]: {
					name: 'Full Name',
					value: Token.getPlaceholderHtmlStringValue(SenderFullNamePlaceholder),
				},
				[SenderEmailPlaceholder.value]: {
					name: 'Email',
					value: Token.getPlaceholderHtmlStringValue(SenderEmailPlaceholder),
				},
				[SenderPhonePlaceholder.value]: {
					name: 'Phone Number',
					value: Token.getPlaceholderHtmlStringValue(SenderPhonePlaceholder),
				},
			},
			name: 'Sender',
		},
	},
	projectId: 11037,
	tools: {
		social: {
			enabled: true,
		},
	},
};
HtmlNewsletterEditor.DefaultEditorOptions = DefaultEditorOptions;

HtmlNewsletterEditor.Body = Presentation.HtmlNewsletterEditorBody;
HtmlNewsletterEditor.Header = Presentation.HtmlNewsletterEditorHeader;
HtmlNewsletterEditor.SaveAsNewButton = Presentation.HtmlNewsletterEditorSaveAsNewButton;
HtmlNewsletterEditor.SaveButton = Presentation.HtmlNewsletterEditorSaveButton;
HtmlNewsletterEditor.SendButton = Presentation.HtmlNewsletterEditorSendButton;
HtmlNewsletterEditor.AutoSaveCheckbox = Presentation.HtmlNewsletterEditorAutoSaveCheckbox;
HtmlNewsletterEditor.SubjectInput = Presentation.HtmlNewsletterEditorSubjectInput;
