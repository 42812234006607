import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, mentionDark, titles } from '../../../styles/colors';
import { BreakPoints, CssSelectors, baseStyleSheet } from '../../../styles/styles';

const sidebarWidth = 390;
const combinedHeaders = 123;

export const styleSheet = StyleSheet.create({
	bodyField: {
		background: 'rgb(232, 243, 249)',
		borderColor: mentionDark,
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		height: '100%',
		overflowX: 'hidden',
		overflowY: 'auto',
		width: `calc(100% - ${sidebarWidth}px)`,
	},
	container: {},
	containerSocial: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		padding: 0,
		margin: 0,
	},
	copyPreviewLinkContainer: {
		marginRight: 16,
	},
	dangerIcon: {
		height: 24,
		width: 30,
	},
	editButton: {
		alignItems: 'center',
		color: brandPrimaryText,
		display: 'flex',
	},
	header: {
		borderBottomColor: mentionDark,
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		display: 'flex',
		justifyContent: 'space-between',
		padding: '20px 30px',
	},
	iconRow: {
		alignItems: 'center',
		display: 'flex',
		height: 40,
		padding: 4,
		...CssSelectors.allChildren(
			{
				height: 24,
			},
			'img'
		),
	},
	iconSection: {
		[BreakPoints.phoneCompactWidth]: {
			paddingLeft: 20,
		},
		marginBottom: 15,
		marginTop: 25,
	},
	mainContent: {
		display: 'flex',
		height: `calc(100% - ${combinedHeaders}px)`,
	},
	name: {
		color: brandPrimaryText,
		flex: '1 1 auto',
		fontSize: 24,
	},
	popover: {
		display: 'inline-flex',
		fontSize: 12,
		margin: 0,
		padding: 6,
	},
	popoverMargin: {
		alignItems: 'center',
		display: 'flex',
	},
	previewContentTitle: {
		...baseStyleSheet.fontBold,
		fontSize: 16,
	},
	previewLinkContainer: {
		[BreakPoints.phoneCompactWidth]: {
			marginTop: 20,
			paddingBottom: 20,
			paddingLeft: 10,
			paddingRight: 10,
		},
		alignItems: 'center',
		color: brandPrimaryText,
		...baseStyleSheet.fontBold,
		fontSize: 12,
		marginTop: 30,
	},
	previewSideContainer: {
		[BreakPoints.phoneCompactWidth]: {
			overflow: 'visible',
			padding: 8,
			paddingTop: 10,
			width: '100%',
		},
		[BreakPoints.forMaxWidth(800)]: {
			minWidth: 0,
		},
		background: '#F8F8F8',
		boxSizing: 'border-box',
		color: brandPrimaryText,
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '100%',
		minWidth: 264,
		overflowY: 'auto',
		padding: 20,
		paddingLeft: 20,
		paddingRight: 10,
		paddingTop: 50,
		width: 390,
		...CssSelectors.allDescendants(
			{
				paddingRight: 10,
			},
			'img'
		),
	},
	sideBar: {
		background: '#f9f9f9',
		width: sidebarWidth,
	},
	sideHeader: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
	},
	targetLocation: {
		color: brandPrimaryText,
		fontSize: 14,
	},
	targetName: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 14,
	},
});
