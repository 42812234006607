import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { SocialMediaPostReportViewModel, SocialMediaPostViewModel, isAdmin } from '../../../../extViewmodels';
import { dedupeByProvider } from '../../../../extViewmodels/Utils';
import {
	IImpersonationContextComponentProps,
	ILocationState,
	IModalContext,
	ImpersonationContextKey,
} from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import {
	checkSocialCitation,
	getSocialMediaPlainTextContentFromRawRichContent,
	getSocialMediaPlatformLogoUrl,
	sortPlatformTargets,
} from '../../../../models/UiUtils';
import { useErrorMessages, useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import { SocialMediaConnectionState } from '../../../../view/models/Api';
import { IConfirmationDialogOption } from '../../../components/ConfirmationDialog';
import { FabContext } from '../../../components/FabContext';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { PrivateRoute } from '../../../components/PrivateRoute';
import { PopoverType, TinyPopover } from '../../../components/TinyPopover';
import { CampaignDetailsSummary } from '../../../components/campaigns/CampaignDetailsSummary';
import { PreviewSocialMediaPost } from '../../../components/socialMedia/PreviewSocialMediaPost';
import { DangerIcon } from '../../../components/svgs/icons/DangerIcon';
import { ViewCitationsModal } from '../../../components/templates/ViewCitationsModal';
import { baseStyleSheet } from '../../../styles/styles';
import { INavigationItemProps } from '../../MainContainer';
import { CopyPreviewLink } from '../../socialMedia/CopyPreviewLink';
import {
	EditSocialMediaPostContext,
	useCreateEditSocialMediaContext,
} from '../../socialMedia/EditSocialMediaPost/context';
import { SocialMediaApprovalSetSchedule } from '../SocialMediaApprovalSetSchedule';
import { styleSheet } from './styles';

interface IProps extends INavigationItemProps, IImpersonationContextComponentProps, IModalContext {
	className?: string;
	onPostUpdated?(): boolean;
	styles?: StyleDeclarationValue[];
}

export const getCancelConfirmationOptions = (
	destructiveOptionText: string,
	cancelText = 'Cancel'
): IConfirmationDialogOption<boolean>[] => {
	return [
		{
			isDestructive: true,
			representedObject: true,
			title: destructiveOptionText,
		},
		{
			isCancel: true,
			representedObject: false,
			title: cancelText,
		},
	];
};

const ApprovalPreviewSocialMediaPostBase: React.FC<IProps> = observer(props => {
	const { className, impersonationContext, routeContainerClassName, styles = [] } = props;
	const { logApiError } = useEventLogging('ApprovalPreviewSocialMediaPost');
	const errorMessages = useErrorMessages();
	const fullscreenModal = useFullscreenModal();
	const h = useHistory();
	const loc = useLocation<ILocationState<SocialMediaPostReportViewModel, any>>();
	const routeMatch = useRouteMatch();
	const userSession = useUserSession();

	const [context] = useCreateEditSocialMediaContext();

	const [post, setPost] = React.useState<SocialMediaPostViewModel>(null);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [showingCitations, setShowingCitations] = React.useState<boolean>(false);

	const canShowEditButton =
		!impersonationContext?.isValid || userSession.user.id === post?.creator?.id || isAdmin(userSession?.user);

	const createSuggestion = loc.state?.viewModel;

	const displayTargets = React.useMemo(() => {
		if (post?.designatedTargets?.length > 0) {
			return sortPlatformTargets(post?.designatedTargets) || [];
		}
		return sortPlatformTargets(post?.targets) || [];
	}, [post?.designatedTargets, post?.targets]);

	React.useEffect(() => {
		if (!post) {
			(async () => {
				try {
					const result = await createSuggestion.loadPost();
					if (result) {
						setPost(new SocialMediaPostViewModel(userSession, result).impersonate(impersonationContext));
					}
					setIsLoading(false);
					return result;
				} catch (error) {
					logApiError('ApprovalPreviewSocialMediaPostBase-Error', error);
					errorMessages.pushApiError(error);
				}
			})();
		}
	}, [createSuggestion, post, impersonationContext, userSession, logApiError, errorMessages]);

	const onEditPost = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault();
		e?.stopPropagation();

		const accountId = impersonationContext?.account?.id;
		if (impersonationContext?.isValid) {
			impersonationContext.user = post.creator;
		}

		const locationState: ILocationState<SocialMediaPostViewModel, any> = {
			viewModel: impersonationContext?.isValid ? post.impersonate(impersonationContext) : post,
		};
		const path = impersonationContext?.isValid
			? `/accounts/${accountId}/content-calendar/post/edit-from-post-report`
			: '/social-media/post/edit-from-post-report';
		fullscreenModal?.history.push({
			pathname: path,
			state: locationState,
		});
	};

	const onNavigateTo = (path: string, locationState?: ILocationState<any, any>) => () => {
		h.push(`${routeMatch.url}${path}`, locationState);
	};

	const onShowSendOptionsClicked = () => {
		// Note: We need to forward the location state on to the next route or we'll lose a ref to the campaign
		onNavigateTo(createSuggestion ? '/approval/set-schedule' : '/send-options')();
	};

	const onViewCitationsClicked = () => {
		setShowingCitations(true);
	};

	const onRequestClose = () => {
		setShowingCitations(false);
	};

	if (!post) {
		return null;
	}

	return (
		<div
			className={`${css(styleSheet.containerSocial, ...styles)} preview-social-approval ${className || ''} ${
				routeContainerClassName || ''
			}`}
		>
			<MultiContainerHeader fullscreenHeader='View Campaign' />
			<Switch>
				<PrivateRoute path={`${routeMatch.url}/approval/set-schedule`} userSession={userSession}>
					<div className={`${css(styleSheet.container)}`}>
						{isLoading ? null : (
							<EditSocialMediaPostContext.Provider value={context}>
								<SocialMediaApprovalSetSchedule
									postVm={post}
									scheduleCtaText='Okay'
									sendOnBehalf={
										(userSession?.user?.id !== post?.creator.id && !impersonationContext?.isValid) ||
										impersonationContext?.isValid
									}
								/>
								{post?.citations ? (
									<ViewCitationsModal
										modalProps={{
											isOpen: showingCitations,
											onRequestClose,
										}}
									>
										{post.citations}
									</ViewCitationsModal>
								) : null}
							</EditSocialMediaPostContext.Provider>
						)}
						<FabContext appearance={{ hidden: true }} />
					</div>
				</PrivateRoute>
				<PrivateRoute path={`${routeMatch.url}`} userSession={userSession}>
					<div className={css(styleSheet.header)}>
						<div className={css(styleSheet.name)}>{post?.name}</div>

						{(!impersonationContext || post) && (
							<CopyPreviewLink
								styles={[styleSheet.copyPreviewLinkContainer]}
								anonymousAccessLink={post.anonymousAccessLink}
							/>
						)}

						{canShowEditButton && (
							<button onClick={onEditPost} className={css(baseStyleSheet.ctaButtonReverseSmall, styleSheet.editButton)}>
								Edit Social Media Post
							</button>
						)}
					</div>
					<div className={css(styleSheet.mainContent)}>
						<div className={css(styleSheet.bodyField)}>
							<PreviewSocialMediaPost
								postTargets={dedupeByProvider(displayTargets)}
								overlayLogo={
									userSession.account.features.socialMedia.enabled &&
									userSession.account.features.socialMedia.alwaysOverlayLogo
								}
								postContent={getSocialMediaPlainTextContentFromRawRichContent(post.content)}
								postImages={post.attachments}
								impersonationCtx={impersonationContext}
							/>
						</div>
						<div className={css(styleSheet.sideBar)}>
							{createSuggestion ? (
								<div className={css(styleSheet.previewSideContainer)}>
									<span className={css(styleSheet.sideHeader, styleSheet.previewContentTitle)}>POSTING TO...</span>
									<div className={css(styleSheet.iconSection)}>
										{displayTargets?.map((x, i) => (
											<div className={css(styleSheet.iconRow)} key={`${x}-${i}`}>
												<img key={i} src={getSocialMediaPlatformLogoUrl(x.provider)} />
												<div>
													<div className={css(styleSheet.targetName)}>
														{x.provider}
														{x.state?.lastConnectionState &&
														x.state?.lastConnectionState?.state !== SocialMediaConnectionState.Connected ? (
															<TinyPopover
																align='end'
																anchor={<DangerIcon className={css(styleSheet.dangerIcon)} />}
																styles={[styleSheet.popoverMargin]}
																dismissOnOutsideAction={true}
																toggleOnHover={true}
																placement={['right', 'top', 'left']}
																type={PopoverType.errorSecondary}
															>
																<p className={css(styleSheet.popover)}>Disconnected </p>
															</TinyPopover>
														) : null}
													</div>
													<div className={css(styleSheet.targetLocation)}>
														@{x?.state?.lastConnectionState?.postTargetDisplayName || 'Unknown'}
													</div>
												</div>
											</div>
										))}
									</div>
									<div className={css(styleSheet.previewLinkContainer)}>
										{checkSocialCitation(post) ? (
											<>
												<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={onViewCitationsClicked}>
													<span>View Citations</span>
												</button>
											</>
										) : null}
									</div>
									<CampaignDetailsSummary
										campaign={post}
										onChangeScheduleClicked={onShowSendOptionsClicked}
										onViewCitationsClicked={onViewCitationsClicked}
									/>
								</div>
							) : null}
						</div>
					</div>
				</PrivateRoute>
			</Switch>
		</div>
	);
});

export const ApprovalPreviewSocialMediaPost = inject(ImpersonationContextKey)(ApprovalPreviewSocialMediaPostBase);
