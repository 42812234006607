import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimary, success, successTint, titles, warningDark, white } from '../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	actions: {
		display: 'flex',
		justifyContent: 'space-around',
		marginTop: 32,
	},
	body: {
		flex: '1',
		padding: 12,
		paddingLeft: 32,
		paddingRight: 24,
		overflow: 'auto',
		height: '100%',
	},
	bottom: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	button: {
		width: '40%',
	},
	checkbox: {
		marginLeft: 20,
	},
	checkboxText: {
		color: titles,
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: 'calc(100vh - 124px - 49px)',
	},
	errors: {
		color: warningDark,
		fontSize: 14,
	},
	filterGroup: {
		marginTop: 20,
		marginBottom: 24,
	},
	footer: {
		padding: 32,
		paddingTop: 0,
		background: white,
		bottom: 0,
		height: 108,
		left: 0,
		position: 'absolute',
		right: 0,
	},
	header: {
		background: brandPrimary,
		color: white,
		display: 'flex',
		justifyContent: 'center',
	},
	headerText: {
		margin: 0,
		padding: 12,
	},
	indentedTagList: {
		maxWidth: 350,
	},
	indentedBody: {
		marginLeft: 12,
	},
	overlay: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: 'rgba(255,255,255,0.5)',
		zIndex: 10,
	},
	ownershipDropdown: {
		width: '80%',
		...CssSelectors.allDescendants(
			{
				background: '#fff',
			},
			'.autocomplete-search-field-input'
		),
	},
	householdBold: {
		...baseStyleSheet.fontBold,
	},
	householdHoverCard: {
		background: successTint,
		border: `1px solid ${success}`,
		borderRadius: '0.25rem',
		boxShadow: '0px 0px 6px rgba(139,139,139, 0.100034)',
		fontSize: '0.75rem',
		padding: '0.5rem 0.75rem',
		width: 288,
		margin: 0,
	},
	infoIconContainer: {
		alignItems: 'center',
		display: 'flex',
	},
	sectionHeader: {
		marginBottom: 8,
	},
	separator: {
		borderBottom: `2px solid ${borderColor}`,
	},
	statusFilters: {
		maxHeight: 170,
	},
	subtitle: {
		fontSize: 14,
	},
	title: {
		color: brandPrimary,
		marginTop: 20,
		marginBottom: 16,
	},
	tagCheckbox: {
		':not(:last-child)': {
			marginBottom: 16,
		},
	},
	tagCheckboxExpanded: {
		':not(:last-child)': {
			marginBottom: 0,
		},
	},
	tagListBuilderContainer: {
		':not(:last-child)': {
			marginBottom: 20,
		},
		background: '#fff',
		borderRadius: 6,
		marginLeft: 12,
		width: 'auto',
		...CssSelectors.allDescendants(
			{
				background: '#fff',
			},
			'.autocomplete-search-field-input'
		),
	},
	tagSection: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren({
			marginRight: 10,
		}),
	},
	tagSectionTitle: {
		marginTop: 0,
		marginBottom: 10,
	},
});
